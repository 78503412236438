import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ConfirmHelper from '../../helpers/ConfirmHelper';
import { fieldRequired } from '../../helpers/fieldRequired';
import { getCurrentUser } from '../../helpers/GetCurrentUser';
import { removeLoginDetails } from '../../helpers/Lookup';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { showToast } from '../../helpers/ToastHelper';


export default class Tasks extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tasks: [],
      task: {
        AddedBy: getCurrentUser().id,
      },
      taskModal: false,
      orderBy: 'NameDown'
    };
    this.taskModalToggle = this.taskModalToggle.bind(this);
  }

  taskModalToggle = () => {
    this.setState({ taskModal: !this.state.taskModal })
  }

  updatetask = () => {
    let valid = true;
    valid &= fieldRequired(this.state.task.taskName, 'task-error', "* required");
    //valid &= fieldRequired(this.state.task.color, 'color-error', "* required");

    if (valid) {
      this.taskModalToggle();
      this.savetask();
    }
  }

  sortBy(header) {
    if (this.state.orderBy.includes(header)) {
      if (this.state.orderBy.includes("Down")) {
        this.setState({ orderBy: header + "Up" })
      } else {
        this.setState({ orderBy: header + "Down" })
      }
    } else {
      this.setState({ orderBy: header + "Down" })
    }
  }

  renderSortArrow(header) {
    return this.state.orderBy === header + "Down" ? <i className='fa fa-long-arrow-down'></i> : this.state.orderBy === header + "Up" ? <i className='fa fa-long-arrow-up'></i> : <></>
  }

  componentDidMount() {
    this.loadData();
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-6"><h1>Tasks</h1></div>
          <div className="col-md-6 text-end align-self-center">
            <Button color="primary" onClick={() => {
              this.setState({ task: { addedBy: getCurrentUser().id } })
              this.taskModalToggle()
            }}>Add Task <i className='fa fa-plus-circle ms-2'></i></Button>
          </div>
        </div>
        <div>
          <table className="table table-striped">
            <thead className='bg-blue'>
              <tr>
                <td style={{ cursor: "pointer" }} onClick={() => this.sortBy("Name")}>{this.renderSortArrow("Name")} Name</td>
                <td className='text-end'>Action</td>
              </tr>
            </thead>
            <tbody>
              {this.state.tasks.length ? this.state.tasks.sort((a, b) => {
                switch (this.state.orderBy) {
                  case "NameDown":
                    return a.taskName.localeCompare(b.taskName);
                  case "NameUp":
                    return b.taskName.localeCompare(a.taskName);
                  default:
                    return a.taskName.localeCompare(b.taskName);
                }
              }).map((task, index) => {
                return (
                  <tr key={index}>
                    <td>{task.taskName}</td>
                    <td >
                      <div className='d-flex justify-content-end'>
                        <div onClick={() => {
                          this.setState({ task });
                          this.taskModalToggle();
                        }}><i className='fa fa-edit mx-2' title="Edit item"></i></div>
                        <div onClick={() => this.deletetask(task.id)}><i className='fa fa-trash-alt text-danger' title="Delete item"></i></div>
                      </div>
                    </td>
                  </tr>
                )
              }) : <tr><td colSpan='3' className='text-center'> No Tasks Found</td></tr>}
            </tbody>
          </table>
        </div>

        <Modal isOpen={this.state.taskModal}>
          <ModalHeader>Edit Task</ModalHeader>
          <ModalBody>

            <label htmlFor="task" className="form-label">
              Name
              <span className='error' id='task-error'></span>
            </label>
            <input type="text" className="form-control" id="task" name="task" value={this.state.task.taskName} onChange={(e) => this.setState({ task: { ...this.state.task, taskName: e.target.value } })} />

          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.taskModalToggle}>Cancel<i className="far fa-times-circle ms-2"></i></Button>
            <Button color="primary" onClick={this.updatetask}>Save<i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>
        <ThrobbleHelper />
      </div>
    )
  }

  loadData = async () => {
    try {
      ThrobbleHelper.ToggleThrobble(true, "Loading Tasks");
      const responses = await fetch('/api/tasks', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + localStorage.getItem('token')
        }
      })
      if (responses.ok) {
        const data = await responses.json();
        this.setState({ tasks: data });
        ThrobbleHelper.ToggleThrobble(false, true);
      } else {
        if (responses.status === 401) {
          removeLoginDetails();
          window.location.href = "/";
        }
        else {
          ThrobbleHelper.ToggleThrobble(false, true);
        }
      }
    } catch (error) {
      console.error(error);
      ThrobbleHelper.ToggleThrobble(false, true);

    }
  }

  savetask = async () => {
    try {
      ThrobbleHelper.ToggleThrobble(true, "Saving Task");

      const data = { ...this.state.task, modifiedBy: getCurrentUser().id };
      const response = await fetch('api/tasks', {
        method: this.state.task.id ? "PUT" : 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + localStorage.getItem('token')
        },
        body: JSON.stringify(data)

      })
      if (response.ok) {
        this.loadData();
        showToast("success", "Save Task", "Task was successfully saved!");
      } else {
        if (response.status === 401) {
          removeLoginDetails();
          window.location.href = "/";
        } else {
          ThrobbleHelper.ToggleThrobble(false, true);
          showToast("error", "Save Task", "There was an error savind task!");
        }
      }

    } catch (error) {
      console.error(error);
      ThrobbleHelper.ToggleThrobble(false, true);
      showToast("error", "Save Task", "There was an error savind task!");
    }
  }


  deletetask = (id) => {
    const ConfirmProps = { DeleteId: id, Title: "Delete Task", Message: "Are you sure you want to delete this task?" }
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmHelper {...ConfirmProps} onClose={onClose} onConfirm={this.deletetaskData} />
      }
    })
  }

  deletetaskData = async (id) => {
    ThrobbleHelper.ToggleThrobble(true, "Deleting Task");
    try {
      const response = await fetch('api/tasks/' + id, {
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + localStorage.getItem('token')
        },
      })
      if (response.ok) {
        this.loadData();
        showToast("success", "Delete Task", "Task was successfully deleted!");
      } else {
        if (response.status === 401) {
          removeLoginDetails();
          window.location.href = "/";
        } else {
          ThrobbleHelper.ToggleThrobble(false, true);
          showToast("error", "Delete Task", "There was an error deleting task");
        }
      }
    } catch (error) {
      console.error(error);
      ThrobbleHelper.ToggleThrobble(false, true);
      showToast("error", "Delete Task", "There was an error deleting task");
    }
  }
}
