import React, { Component } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Select from 'react-select';
import moment from 'moment';
import { Button, Col, Row } from 'reactstrap';
import { myContext } from '../Context';
import { fieldRequired } from '../helpers/fieldRequired';
//import { ToastUtility } from '@syncfusion/ej2-react-notifications';
// import { confirm as confirmAlert } from "react-confirm-box";
import { getCurrentUser } from '../helpers/GetCurrentUser';
import { removeLoginDetails } from '../helpers/Lookup';
import ThrobbleHelper from '../helpers/ThrobbleHelper';
import { showToast } from '../helpers/ToastHelper';
import AssociatedCases from './Case Editor Components/AssociatedCases';
import AttachmentEditor from './Case Editor Components/AttachmentEditor';
//import { NotesTable } from './Case Editor Components/NotesTable';
import ProgressTasks from './Case Editor Components/ProgressTasks';
import NotesTable from './Case Editor Components/NotesTable';

export class CaseFile extends Component {
    static displayName = CaseFile.name;
    constructor(props) {
        super(props);
        this.state = {
            clauses: [],
            editData: [],
            loading: true,
            editModal: false,
            CaseFileId: 0,
            CaseNumber: 0,
            Id: 0,
            Industries: [],
            Description: '',
            DescriptionEdited: '',
            DateOpened: new Date(),
            StatusId: 0,
            DateAssigned: new Date(),
            DateDue: new Date(),
            LevelId: 0,
            OutcomeId: 0,
            Complainant: '',
            ExternalAdjudicator: '',
            ComplainantTypeId: 0,
            Respondent: '',
            Media: [],
            Industry: 0,
            AssignClause: [],
            MembershipStatus: 0,
            Progress: '',
            AssignedTo: 0,
            AddedBy: 0,
            DateAdded: new Date(),
            users: [],
            memberStatuses: [],
            mediaTypes: [],
            complainantTypes: [],
            outcomes: [],
            levels: [],
            statuses: [],
            tasks: [],
        };

        this.notesTableRef = React.createRef();
        this.saveCase = this.saveCase.bind(this);
        this.renderNotes = this.renderNotes.bind(this);

        this.quillModules = {
            toolbar: [
                ['bold', 'italic', 'underline', 'strike',],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                ['clean']
            ]
        }

    }



    componentDidMount() {
        document.title = "Case File";
        let caseId = document.location.href.split('/').pop()
        if (caseId === null || isNaN(caseId)) {
            document.location.href = "/mydms";
        }
        this.loadData(caseId);
    }

    toggleEditNotesModal = () => {
        if (this.notesTableRef.current) {
            this.notesTableRef.current.toggleEditNotesModal();
        }
    };

    zeroPad = (num, places) => String(num).padStart(places, '0');

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    renderCaseInformation() {
        const customStyles = {
            groupHeading: (provided) => ({
                ...provided,
                fontSize: '18px', // customize font size
            }),
        };
        return (
            <>
                <Row>
                    <Col md={4} className='mb-3'>
                        <label>Status</label> &nbsp; <span id='tbStatusIdError' className='error-message' />
                        <div>
                            <Select
                                className={"select2-sm"}
                                options={this.state.statuses}
                                isSearchable={true}
                                isClearable={false}
                                backspaceRemovesValue={false}
                                isRtl={false}
                                value={this.state.statuses.filter(option => option.value === this.state.StatusId)}
                                onChange={e => this.setState({ StatusId: e.value })}
                            />
                        </div>
                    </Col>
                    <Col md={4} className='mb-3'>
                        <label>Level</label> &nbsp; <span id='tbLevelIdError' className='error-message' />
                        <div>
                            <Select
                                className={"select2-sm"}
                                options={this.state.levels}
                                isSearchable={true}
                                isClearable={false}
                                backspaceRemovesValue={false}
                                isRtl={false}
                                value={this.state.levels.filter(option => option.value === this.state.LevelId)}
                                onChange={e => this.setState({ LevelId: e.value })}
                            />
                        </div>
                    </Col>
                    <Col md={4} className='mb-3'>
                        <label>Outcome</label> &nbsp; <span id='tbOutcomeIdError' className='error-message' />
                        <div>
                            <Select
                                className={"select2-sm"}
                                options={this.state.outcomes}
                                isSearchable={true}
                                isClearable={false}
                                backspaceRemovesValue={false}
                                isRtl={false}
                                value={this.state.outcomes.filter(option => option.value === this.state.OutcomeId)}
                                onChange={e => this.setState({ OutcomeId: e.value })}
                            />
                        </div>
                    </Col>
                </Row>

                <label>File Name</label>
                <div className='mb-3'>
                    <ReactQuill theme="snow" value={this.state.Description} onChange={(e) => this.setState({ Description: e })} modules={this.quillModules} />
                </div>


                <h6 className="border-bottom">COMPLAINANT</h6>
                <Row>
                    <Col md={8} className='mb-3'>
                        <label>Name</label> &nbsp; <span id='tbComplainantError' className='error-message' />
                        <div>
                            <input className='form-control form-control-sm' value={this.state.Complainant} onChange={(e) => this.setState({ Complainant: e.target.value })} />
                        </div>
                    </Col>
                    <Col md={4} className='mb-3'>
                        <label>Type</label> &nbsp; <span id='tbComplainantTypeIdError' className='error-message' />
                        <div>
                            <Select
                                className={"select2-sm"}
                                options={this.state.complainantTypes}
                                isSearchable={true}
                                isClearable={false}
                                backspaceRemovesValue={false}
                                isRtl={false}
                                value={this.state.complainantTypes.filter(option => option.value === this.state.ComplainantTypeId)}
                                onChange={e => this.setState({ ComplainantTypeId: e.value })}
                            />
                        </div>
                    </Col>
                </Row>

                <h6 className="border-bottom">RESPONDENT</h6>
                <Row>
                    <Col md={8} className='mb-3'>
                        <label>Name</label> &nbsp; <span id='tbRespondentError' className='error-message' />
                        <div>
                            <input className='form-control form-control-sm' value={this.state.Respondent} onChange={(e) => this.setState({ Respondent: e.target.value })} />
                        </div>
                    </Col>
                    <Col md={4} className='mb-3'>
                        <label>Industry</label>
                        <div className='d-none'>
                            <input className='form-control form-control-sm' value={this.state.Industry} onChange={(e) => this.setState({ Industry: e.target.value })} />
                        </div>
                        <div>
                            <Select
                                className={"select2-sm"}
                                options={this.state.Industries.map(item => ({ label: item.name, value: item.id }))}
                                isSearchable={true}
                                isClearable={false}
                                backspaceRemovesValue={false}
                                isRtl={false}
                                value={this.state.Industries.map(item => ({ label: item.name, value: item.id })).filter(option => option.value === this.state.Industry)}
                                onChange={e => this.setState({ Industry: e.value })}
                            />
                        </div>
                    </Col>
                    <Col md={12} className="mb-3">
                        <label>Media</label>
                        <Select className='select2-sm' options={this.state.mediaTypes.map(item => ({ label: item.name, value: item.id }))} isMulti onChange={(e) => this.setState({ Media: e })} value={this.state.Media} />
                    </Col>
                </Row>
                <Row>
                    <Col md={8} className='mb-3'>
                        <div>Assign Clauses</div>
                        <div>
                            <Select className='select2-sm' options={this.state.clauses} isMulti onChange={(e) => { this.setState({ AssignClause: e }) }} value={this.state.AssignClause} styles={customStyles} />
                        </div>
                    </Col>
                    <Col md={4} className='mb-3'>
                        <label>Membership Status</label> &nbsp; <span id='tbMembershipStatusError' className='error-message' />
                        <div>
                            <Select
                                className={"select2-sm "}
                                options={this.state.memberStatuses}
                                isSearchable={true}
                                isClearable={false}
                                backspaceRemovesValue={false}
                                isRtl={false}
                                value={this.state.memberStatuses.filter(option => option.value === this.state.MembershipStatus)}
                                onChange={e => this.setState({ MembershipStatus: e.value })}
                            />
                        </div>
                    </Col>
                </Row>
                {/* <Row>
            <Col md={4} className='mb-3'>
              <label>Progress</label>
              <div>
                <input className='form-control form-control-sm' value={this.state.Progress} onChange={(e) => this.setState({ Progress: e.target.value })} />
              </div>
            </Col>
            <Col md={4} className='mb-3'>
              <label>Assigned To</label> &nbsp; <span id='tbAssignedToError' className='error-message' />
              <div>
                <Select className={"select2-sm"} options={this.state.users} isSearchable={true} isClearable={false} backspaceRemovesValue={false} isRtl={false} value={this.state.users.filter(option => option.value === this.state.AssignedTo)} onChange={e => this.setState({ AssignedTo: e.value })} />
              </div>
            </Col>
            <Col md={4} className='mb-3'>
              <label>Date Due <span id='tbDateDueError' className='error-message' /></label>
              <div>
                <input className='form-control form-control-sm' placeholder='Date Due' type="date" format='yyyy-MM-dd' value={moment(this.state.DateDue).format("yyyy-MM-DD")} onChange={e => this.setState({ DateDue: e.target.value })} />
              </div>
            </Col>
            <Col md={8} className='mb-3'>
            <label>External Adjudicator </label> &nbsp; <span id='tbRespondentError' className='error-message' />
            <div>
            <input className='form-control form-control-sm' value={this.state.ExternalAdjudicator} onChange={(e) => this.setState({ ExternalAdjudicator: e.target.value })} />
            </div>
          </Col>
        </Row> */}
            </>
        )
    }

    renderAttachments() {
        return <AttachmentEditor CaseId={this.state.CaseFileId} />
    }

    renderProgress() {
        return <ProgressTasks CaseId={this.state.CaseFileId} UserOptions={this.state.users} TaskOptions={this.state.tasks} />
    }

    renderNotes() {
        return (
            <>
                <Row className="border-bottom mt-4">
                    <Col md={6}><h5>NOTES</h5></Col>
                    <Col md={6} className="text-end">
                        <button className="btn btn-pastel-blue btn-sm" onClick={() => this.toggleEditNotesModal()}>Add Note<i className='fa fa-plus-circle ms-2'></i></button>
                    </Col>
                    <Col md={12}>
                        <NotesTable CaseID={this.state.Id} ref={this.notesTableRef} ShowFilter={false} />
                    </Col>
                </Row>
            </>
        );
    }

    renderLinkedCases() {
        return (
            <AssociatedCases CaseID={this.state.Id} />
        )
    }

    render() {
        // console.log(this.state, getCurrentUser())
        return (
            <>
                <div className="container pb-5">
                    <Row>
                        <Col xs={12}>
                            <h1>Case File {this.zeroPad(this.state.CaseNumber, 5)}</h1>
                        </Col>
                    </Row>

                    {this.renderCaseInformation()}

                    {this.state.Id ? this.renderProgress() : ""}
                    {this.state.Id ? this.renderAttachments() : ""}
                    {this.state.Id ? this.renderNotes() : ""}
                    {this.state.Id ? this.renderLinkedCases() : ""}

                    <Row>
                        <Col className='d-flex justify-content-end'>
                            <div>
                                <Button color='dark' outline size='sm' className='me-2' onClick={() => window.history.back()} >
                                    <i className='fal fa-circle-arrow-left me-2'></i> Back
                                </Button>
                                <Button color="primary" size='sm' onClick={this.saveCase}>
                                    Save <i className="far fa-check-circle ms-2"></i>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </div>
                <ThrobbleHelper />
            </>

        );
    }

    addNewFile = () => {
        this.setState({ attachmentModal: true });
    }

    async loadData(caseId) {
        this.setState({ loading: true, CaseFileId: caseId });
        ThrobbleHelper.ToggleThrobble(true, "Loading Case Details");
        await this.loadStatuses();
        await this.loadLevels();
        await this.loadOutcomes();
        await this.loadMedia();
        await this.loadComplainantTypes();
        await this.loadUsers();
        await this.loadTasks();
        await this.loadMembership();
        await this.loadIndustries();
        await this.loadClauses()

        if (Number.isInteger(parseInt(caseId))) {
            await this.loadAttachments(caseId);
            await this.loadCaseClauses(caseId);
        } else {
            ThrobbleHelper.ToggleThrobble(false);
            this.setState({ loading: false });
        }

        if (Number.isInteger(parseInt(caseId))) {
            var bearer = `Bearer ${localStorage.getItem("token")}`;
            try {
                const response = await fetch('api/cases/loadbyid/' + caseId, {
                    method: 'GET',
                    withCredentials: true,
                    credentials: 'include',
                    headers: {
                        'Authorization': bearer,
                        'Content-Type': 'application/json'
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    console.log(data)
                    this.setState({
                        editData: data,
                        Id: data.id,
                        CaseNumber: data.caseNumber,
                        Description: data.description,
                        DateOpened: data.dateOpened,
                        StatusId: data.statusId,
                        DateAssigned: data.dateAssigned,
                        LevelId: data.levelId,
                        OutcomeId: data.outcomeId,
                        Complainant: data.complainant,
                        ComplainantTypeId: data.complainantTypeId,
                        Respondent: data.respondent,
                        Media: JSON.parse(data.media),
                        Industry: data.industry,
                        MembershipStatus: data.membershipStatus,
                        Progress: data.progress,
                        AssignedTo: data.assignedTo,
                        AddedBy: data.addedBy,
                        DateAdded: data.dateAdded,
                        DateDue: data.dateDue,
                        DateClosed: data.dateClosed,
                        ExternalAdjudicator: data.externalAdjudicator,
                        loading: false,
                    });

                    ThrobbleHelper.ToggleThrobble(false);
                }
                else {
                    console.error(response.status + ": " + response.statusText);
                    if (response.status === 401) {
                        removeLoginDetails();
                        window.location.href = "/";
                    }
                    else {
                        ThrobbleHelper.ToggleThrobble(false);

                    }
                }

            } catch (e) {
                console.error(e);
                ThrobbleHelper.ToggleThrobble(false);
            }
        }
    }


    async loadCaseClauses(Id) {
        if (Id) {
            try {
                const response = await fetch('/api/caseclauses/' + Id, {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json',
                        "Authorization": `Bearer ${localStorage.getItem("token")}`
                    }
                })

                if (response.ok) {
                    let body = await response.json();
                    let clauses = this.state.clauses.map(item => item.options).flat()
                    let assignedClauses = body.map(item => ({ value: item.clauseId, label: clauses.find(clause => clause.value === item.clauseId)?.label }))
                    this.setState({ AssignClause: assignedClauses })
                }

            } catch (error) {
                console.error(error)
            }
        }
    }

    async saveCaseClauses(id) {
        try {
            const response = await fetch('/api/caseclauses/', {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                },
                body: JSON.stringify({
                    CaseId: id,
                    ClauseIds: this.state.AssignClause.map(item => item.value),
                    DateAdded: new Date(),
                    AddedBy: getCurrentUser().id,
                    Status: 1
                })
            })
            if (response.ok) {
                const body = await response.json();
                console.log(body)
            }
        } catch (error) {
            console.error(error)
        }
    }

    async loadStatuses() {
        try {
            const response = await fetch('/api/status', {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            });

            if (response.ok) {
                const data = await response.json();
                const statuses = [{ value: 0, label: "- Select -" }, ...data.map(item => {
                    return { value: item.id, label: item.name }
                })]
                this.setState({ statuses });
            }

        } catch (error) {
            console.error(error)
        }
    }

    async loadLevels() {
        try {
            const response = await fetch('/api/levels', {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            });

            if (response.ok) {
                const data = await response.json();

                const levels = [{ value: 0, label: "- Select -" }, ...data.map(item => {
                    return { value: item.id, label: item.name }
                })]
                this.setState({ levels });
            }

        } catch (error) {
            console.error(error)
        }
    }

    async loadOutcomes() {
        try {
            const response = await fetch('/api/outcomes', {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            });

            if (response.ok) {
                const data = await response.json();
                const outcomes = [{ value: 0, label: "- Select -" }, ...data.map(item => {
                    return { value: item.id, label: item.name }
                })]
                this.setState({ outcomes });
            }

        } catch (error) {
            console.error(error)
        }
    }

    async loadMedia() {
        try {
            const response = await fetch('/api/media', {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            })

            if (response.ok) {
                const data = await response.json();
                this.setState({ mediaTypes: data });
            }
        } catch (error) {
            console.error(error)
        }
    }

    async loadComplainantTypes() {
        try {

            const response = await fetch('/api/complainanttypes', {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            })

            if (response.ok) {
                const data = await response.json();
                const complainantTypes = [{ value: 0, label: "- Select -" }, ...data.map(item => {
                    return { value: item.id, label: item.name }
                })]
                this.setState({ complainantTypes });
            }
        } catch (error) {
            console.error(error)
        }
    }

    async loadMembership() {
        try {
            //const response = await fetch('/api/consumer_competitor', {
            const response = await fetch('/api/membershipstatuses', {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            })

            if (response.ok) {
                const data = await response.json();
                const memberStatuses = [{ value: 0, label: "- Select -" }, ...data.map(item => {
                    return { value: item.id, label: item.name }
                })]
                this.setState({ memberStatuses });
            }
        } catch (error) {
            console.error(error)
        }
    }

    async loadUsers() {
        try {
            const response = await fetch('/api/userdata', {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            })

            if (response.ok) {
                const data = (await response.json()).sort((a, b) => a.firstName.localeCompare(b.firstName));

                const users = [{ value: 0, label: "- Select -" }, ...data.map(item => {
                    return { value: item.id, label: item.firstName + " " + item.lastName }
                })]
                this.setState({ users });
            }
        } catch (error) {
            console.error(error)
        }
    }

    async loadTasks() {
        try {
            const response = await fetch('/api/tasks', {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            })

            if (response.ok) {
                const data = (await response.json()).sort((a, b) => a.taskName.localeCompare(b.taskName));
                const tasks = [{ value: 0, label: "- Select -" }, ...data.map(item => {
                    return { value: item.id, label: item.taskName }
                })]
                this.setState({ tasks });
            }
        } catch (error) {
            console.error(error)
        }
    }

    async loadAttachments(caseId) {
        try {
            const response = await fetch('/api/attachments/listforcase/' + caseId, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            });

            if (response.ok) {
                const data = await response.json();
                this.setState({ attachmentData: data });
            }
        } catch (error) {
            console.error(error)
        }
    }

    async loadIndustries() {
        try {
            const responses = await fetch('/api/industry', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem('token')
                }
            })
            if (responses.ok) {
                const data = await responses.json();
                this.setState({ Industries: data });
            } else {
                if (responses.status === 401) {
                    removeLoginDetails();
                    window.location.href = "/";
                } else {
                }
            }
        } catch (error) {
            console.error(error);
        }
    }

    async loadClauses() {
        try {
            const response = await fetch("api/clauses", {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json"
                }
            })

            if (response.ok) {
                let body = await response.json();
                body = body.reduce((arr, curr) => {
                    let index = arr.findIndex(item => item.label === curr.groupName);
                    if (index === -1) {
                        arr.push({ label: curr.groupName, options: [{ label: curr.name, value: curr.id }] })
                    } else {
                        arr[index].options.push({ label: curr.name, value: curr.id })
                    }
                    return arr
                }, [])
                this.setState({ clauses: body });
            } else {
                if (response.status === 401) {
                    removeLoginDetails();
                    window.location.href = "/";
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    saveAttachment = async (fileContents) => {
        var data = { CaseId: this.state.CaseFileId, FileContents: fileContents, FileName: this.state.fileData.name, AddedBy: getCurrentUser().id, ModifiedBy: getCurrentUser().id };
        try {
            const response = await fetch("api/attachments/", {
                method: "POST",
                withCredentials: true,
                credentials: "include",
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ attachmentModal: false });
                this.loadAttachments(this.state.CaseFileId);
                ThrobbleHelper.ToggleThrobble(false);
            } else {
                console.error(response.status + ": " + response.statusText);
                if (response.status === 401) {
                    removeLoginDetails();
                    window.location.href = "/";
                } else {
                    ThrobbleHelper.ToggleThrobble(false);
                }
            }
        } catch (e) {
            ThrobbleHelper.ToggleThrobble(false);

            console.error(e);
        }
    };

    processAttachment = async (event) => {
        event.stopPropagation();

        var valid = true;
        valid &= fieldRequired(this.state.fileData, 'fileImportError', '* required');

        if (valid) {
            ThrobbleHelper.ToggleThrobble(true, "Saving Attachment");
            this.getBase64(this.state.fileData, (result) => {
                this.saveAttachment(result);
            });
        }
    }

    downloadAttachment = async (Id) => {
        await this.downloadFile(Id)
        //let excelFile = '';
        // this.getBase64(this.state.fileData, (result) => {
        //   //excelFile = result;
        //   //this.importData(excelFile);
        //   this.saveAttachment(result);
        // });
    }

    async saveCase() {
        this.setState({ caseModal: false })
        var valid = true;
        const userData = getCurrentUser();

        valid &= fieldRequired(this.state.StatusId, 'tbStatusIdError', '* required');
        valid &= fieldRequired(this.state.LevelId, 'tbLevelIdError', '* required');
        valid &= fieldRequired(this.state.OutcomeId, 'tbOutcomeIdError', '* required');
        valid &= fieldRequired(this.state.MembershipStatus, 'tbMembershipStatusError', '* required');
        //valid &= fieldRequired(this.state.AssignedTo, 'tbAssignedToError', '* required');
        valid &= fieldRequired(this.state.ComplainantTypeId, 'tbComplainantTypeIdError', '* required');
        valid &= fieldRequired(this.state.Complainant, 'tbComplainantError', '* required');
        valid &= fieldRequired(this.state.Respondent, 'tbRespondentError', '* required');

        if (valid) {
            ThrobbleHelper.ToggleThrobble(true, "Saving Case");
            try {
                const response = await fetch("api/cases", {
                    method: this.state.Id ? "PUT" : "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + localStorage.getItem("token")
                    },
                    body: JSON.stringify({
                        Id: this.state.Id,
                        Description: this.state.Description,
                        DateAdded: new Date(this.state.DateAdded),
                        DateOpened: this.state.DateAdded,
                        CaseNumber: this.state.CaseNumber,
                        DateAssigned: this.state.DateAssigned,
                        DateDue: this.state.DateDue,
                        DateClosed: this.state.StatusId === 4 && !this.state.DateClosed ? new Date() : this.state.DateClosed,
                        ExternalAdjudicator: this.state.ExternalAdjudicator,
                        StatusId: this.state.StatusId,
                        LevelId: this.state.LevelId,
                        OutcomeId: this.state.OutcomeId,
                        Complainant: this.state.Complainant,
                        ComplainantTypeId: this.state.ComplainantTypeId,
                        Consumer: this.state.Consumer,
                        Respondent: this.state.Respondent,
                        Media: JSON.stringify(this.state.Media),
                        Industry: this.state.Industry,
                        MembershipStatus: this.state.MembershipStatus,
                        Progress: this.state.Progress,
                        AssignedTo: this.state.AssignedTo,
                        AddedBy: userData.id,
                        AssignClause: JSON.stringify(this.state.AssignClause),
                    })
                })

                if (response.ok) {
                    let body = await response.json();
                    if (this.state.AssignClause.length) {
                        await this.saveCaseClauses(body);
                    }
                    this.loadData(body);
                    ThrobbleHelper.ToggleThrobble(false);
                    showToast("success", "Case File", "Case file was successfully saved!");
                } else {
                    if (response.status === 401) {
                        removeLoginDetails();
                        window.location.href = "/";
                    } else {
                        ThrobbleHelper.ToggleThrobble(false);
                        showToast("error", "Case File", "There was an error saving case file!");
                    }
                }
            } catch (error) {
                ThrobbleHelper.ToggleThrobble(false);
                showToast("error", "Case File", "There was an error saving case file!");
                console.error(error)
            }
        }
    }
}

CaseFile.contextType = myContext;