import moment from 'moment';
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import ConfirmHelper from '../../helpers/ConfirmHelper';
import { fieldRequired } from '../../helpers/fieldRequired';
import { getCurrentUser } from '../../helpers/GetCurrentUser';
import { removeLoginDetails } from '../../helpers/Lookup';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { showToast } from '../../helpers/ToastHelper';

class AttachmentEditor extends Component {

    constructor(props) {
        super(props);

        this.state = {
            CaseId: this.props.CaseId,
            attachmentId: 0,
            attachments: [],
            fileName: '',
            fileRename: '',
            fileData: null,
            attachmentModal: false,
            renameModal: false
        }

        this.toggleAttachment = this.toggleAttachment.bind(this);
        this.toggleRenameModal = this.toggleRenameModal.bind(this)
        this.showRenameModal = this.showRenameModal.bind(this);
        this.addNewFile = this.addNewFile.bind(this);
        this.setfileData = this.setfileData.bind(this);
        this.saveFilename = this.saveFilename.bind(this);
        this.saveAttachment = this.saveAttachment.bind(this);
        this.deleteAttachments = this.deleteAttachments.bind(this);
        this.deleteFile = this.deleteFile.bind(this);
        this.clearForm = this.clearForm.bind(this);

    }

    componentDidMount() {
        this.loadAttachments();
    }

    showRenameModal(id) {
        const attachment = this.state.attachments.find(c => c.id === id);
        this.setState({ attachmentId: id, fileName: attachment.fileName, renameModal: true });
    }

    toggleRenameModal() {
        this.setState({
            renameModal: !this.state.renameModal
        });
    }

    toggleAttachment() {
        this.setState({
            attachmentModal: !this.state.attachmentModal
        });
    }

    addNewFile = () => {
        this.clearForm();
        this.setState({ attachmentModal: true });
    }

    setfileData = (e) => {
        this.setState({
            fileData: e.target.files[0],
            fileName: e.target.files[0].name
        });
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.error('Error: ', error);
        };
    }

    clearForm() {
        this.setState({
            fileName: '',
            fileData: null,
        })
    }

    render() {
        return (
            <>
                <Row className="border-bottom mt-3">
                    <Col md={6}><h5>ATTACHMENTS</h5></Col>
                    <Col md={6} className="text-end">
                        <button className="btn btn-pastel-blue btn-sm" onClick={() => this.addNewFile()}>Add Attachment <i className='fa fa-plus-circle ms-2'></i></button>
                    </Col>
                </Row>



                <table className='table table-sm table-striped mt-2' >
                    <thead className='bg-blue'>
                        <tr>
                            <th style={{ width: "40%" }}>Filename</th>
                            <th style={{ width: "15%" }}>Date Added</th>
                            <th style={{ width: "35%" }}>Added By</th>
                            <th className="text-end" style={{ width: "10%" }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>

                        {
                            this.state.attachments.length ?
                                this.state.attachments.map((item, index) => {
                                    return <tr key={index}>
                                        <td>{item.fileName}</td>
                                        <td className="text-start">{moment(item.dateAdded).format("yyyy/MM/DD")}</td>
                                        <td>{item.addedByName}</td>
                                        <td className="text-end">
                                            <i className='fa fa-file-pen me-2' style={{ cursor: "pointer" }} title='Rename File' onClick={() => this.showRenameModal(item.id)}></i>
                                            <i className='fa fa-download me-2' style={{ cursor: "pointer" }} title="Download attachment" onClick={() => this.downloadAttachment(item.id)}></i>
                                            <i className='fa fa-trash-alt text-danger' style={{ cursor: "pointer" }} title="Delete attachment" onClick={() => this.deleteAttachments(item.id)}></i>
                                        </td>
                                    </tr>

                                })
                                :
                                <tr>
                                    <td colSpan={4} className="text-center"><i>There are currently no attachments</i></td>
                                </tr>
                        }

                    </tbody>
                </table>

                <Modal isOpen={this.state.renameModal} toggle={this.toggleRenameModal} className={this.props.className} backdrop="static">
                    <ModalHeader toggle={this.toggleRenameModal} close={<button className='btn-close' onClick={this.toggleRenameModal}></button>}>
                        Rename Attachment File
                    </ModalHeader>
                    <ModalBody>
                        <div className="mb-3">
                            <label>Filename
                                <span id='errFileName' className='error-message me-2'></span>
                            </label>
                            <input type='text' className='form-control form-control-sm mb-1' value={this.state.fileName} onChange={e => this.setState({ fileName: e.target.value })} />
                            <small className="text-muted">
                                Please ensure that you maintain the file extension.
                            </small>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='secondary' size='sm' onClick={this.toggleRenameModal}>Cancel<i className="far fa-times-circle ms-2"></i></Button>
                        <Button color='primary' size='sm' onClick={this.saveFilename}>Save <i className="far fa-check-circle ms-2"></i></Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.attachmentModal} toggle={this.toggleAttachment} className={this.props.className} scrollable size="md" backdrop="static">
                    <ModalHeader toggle={this.toggleAttachment} close={<button className="close" onClick={this.toggleAttachment}><i className="fas fa-times"></i></button>}>Attachments</ModalHeader>
                    <ModalBody>
                        <form encType="multipart/form-data">
                            <label>Select file to attach</label>
                            <input id='fileImport' type='file' className='form-control form-control-sm' onChange={this.setfileData} />
                            <div id='fileImportError' className='error-message'></div>

                            <div className='mt-2' hidden={!this.state.fileData}>
                                <label>Filename</label>
                                <input type='text' className='form-control form-control-sm mb-1' value={this.state.fileName} onChange={(e) => this.setState({ fileName: e.target.value })} />
                                <small className="text-muted">
                                    Please ensure that you maintain the file extension when you change the filename.
                                </small>
                            </div>
                            {/*accept=".xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.pdf"*/}
                        </form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" size="sm" onClick={this.toggleAttachment}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
                        <Button color="primary" size="sm" onClick={this.processAttachment}>Save <i className="far fa-check-circle ms-2"></i></Button>
                    </ModalFooter>
                </Modal>

            </>
        );
    }

    async loadAttachments(caseId) {
        try {
            const response = await fetch('/api/attachments/listforcase/' + this.state.CaseId, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            });

            if (response.ok) {
                const data = await response.json();
                this.setState({ attachments: data });
            }
        } catch (error) {
            console.error(error)
        }
    }

    saveFilename = async (evt) => {
        evt.stopPropagation();
        let valid = true;
        valid &= fieldRequired(this.state.fileName, "errFileName", "* Required");

        if (valid) {
            ThrobbleHelper.ToggleThrobble(true, "Renaming attachment file");
            try {
                const response = await fetch("api/attachments", {
                    method: "PUT",
                    withCredentials: true,
                    credentials: "include",
                    headers: {
                        "Authorization": "Bearer " + localStorage.getItem("token"),
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ Id: this.state.attachmentId, FileName: this.state.fileName })
                });

                if (response.ok) {
                    showToast("success", "Rename Attachment File", "Attachment file was successfully renamed");
                    ThrobbleHelper.ToggleThrobble(false);
                    this.toggleRenameModal();
                    this.loadAttachments();

                } else {
                    console.log(response.status, ":", response.statusText);
                    ThrobbleHelper.ToggleThrobble(false);
                    showToast("error", "Rename Attachment File", "There was an error renaming attachment file");
                }

            } catch (e) {
                console.error(e);
            }
        }
    }

    saveAttachment = async (fileContents) => {
        var data = { CaseId: this.state.CaseId, FileContents: fileContents, FileName: this.state.fileName, AddedBy: getCurrentUser().id, ModifiedBy: getCurrentUser().id };
        try {
            const response = await fetch("api/attachments/", {
                method: "POST",
                withCredentials: true,
                credentials: "include",
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem("token")}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data)
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ attachmentModal: false });
                this.loadAttachments(this.state.CaseId);
                ThrobbleHelper.ToggleThrobble(false);
            } else {
                console.error(response.status + ": " + response.statusText);
                if (response.status === 401) {
                    removeLoginDetails();
                    window.location.href = "/";
                } else {
                    ThrobbleHelper.ToggleThrobble(false);
                }
            }
        } catch (e) {
            ThrobbleHelper.ToggleThrobble(false);

            console.error(e);
        }
    };

    processAttachment = async (event) => {
        event.stopPropagation();
        var valid = true;
        valid &= fieldRequired(this.state.fileData, 'fileImportError', '* required');

        if (valid) {
            ThrobbleHelper.ToggleThrobble(true, "Saving Attachment");
            this.getBase64(this.state.fileData, (result) => {
                this.saveAttachment(result);
            });
        }
    }

    downloadAttachment = async (Id) => {
        await this.downloadFile(Id)
    }

    async downloadFile(id) {
        try {
            ThrobbleHelper.ToggleThrobble(true, "Downloading attachment");
            const response = await fetch("api/attachments/" + id, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            })

            if (response.ok) {
                const data = await response.json();
                const FileData = data.item1;
                const Filename = data.item2;
                const MimeType = data.item3;
                const Base64String = `data:${MimeType};base64,${FileData}`;

                // // Create a URL from the response blob
                const LinkBtn = document.createElement("a");
                LinkBtn.download = Filename;
                LinkBtn.href = Base64String;
                LinkBtn.click();

                ThrobbleHelper.ToggleThrobble(false);
                showToast("success", "Download Attachment", "Attachment was successfully downloaded");
            } else {
                if (response.status === 401) {
                    localStorage.removeItem('token');
                    window.location.href = "/";
                } else {
                    ThrobbleHelper.ToggleThrobble(false);
                    showToast("error", "Download Attachment", "There was an error downloading attachment");
                }
            }
        } catch (error) {
            console.error(error)
            ThrobbleHelper.ToggleThrobble(false);
            showToast("error", "Download Attachment", "There was an error downloading attachment");
        }
    }

    async deleteAttachments(id) {
        const ConfirmProps = { DeleteId: id, Title: "Delete Attachment", Message: "Are you sure you want to delete this attachment?" };
        confirmAlert({
            customUI: ({ onClose }) => <ConfirmHelper {...ConfirmProps} onClose={onClose} onConfirm={this.deleteFile} />
        })
    }


    deleteFile = async (id) => {
        try {

            ThrobbleHelper.ToggleThrobble(true, "Deleting Attachment")
            const response = await fetch('/api/attachments/' + id, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            })

            if (response.ok) {
                this.loadAttachments();
                ThrobbleHelper.ToggleThrobble(false);
                showToast("success", "Delete Attachment", "Attachment was successfully deleted!")
            } else {
                if (response.status === 401) {
                    localStorage.removeItem('token');
                    window.location.href = "/";
                } else {
                    ThrobbleHelper.ToggleThrobble(false);
                    showToast("error", "Delete Attachment", "There was an error deleting attachment");
                }
            }
        } catch (error) {
            console.error(error)
            ThrobbleHelper.ToggleThrobble(false);
            showToast("error", "Delete Attachment", "There was an error deleting attachment");
        }
    }


}

export default AttachmentEditor;