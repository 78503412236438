import React, { Component } from 'react'
import { Graph } from './LineChart';
import { Col, Row } from 'reactstrap';

export default class CaseStatistics extends Component {
    render() {
        const data = [
            { name: 'Jan', uv: 4000, pv: 2400, amt: 2400 },
            { name: 'Feb', uv: 3000, pv: 1398, amt: 2210 },
            { name: 'Mar', uv: 3000, pv: 1398, amt: 2210 },
            // Add more data points here
        ];
        return (
            <div className='table-container'>
                <div className='dms-header d-flex justify-content-center p-3 align-items-center '>
                    <h4 style={{ fontSize: "30px", fontWeight: 100 }}>CASE STATISTICS DASHBOARD</h4>
                </div>
                <div className='my-2'>
                    <Row>
                        <Col className='d-flex align-items-center flex-column'>
                            <h3>Number of Complainants in {new Date().getFullYear()} (YTD)</h3>
                            <Graph data={data} type="line" />
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }
}
