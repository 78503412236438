import moment from 'moment';
import React, { Component } from 'react';
import Select from 'react-select';
import { Button, Card, CardBody } from 'reactstrap';
import { removeLoginDetails } from '../../helpers/Lookup';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { showToast } from '../../helpers/ToastHelper';
import DatePicker from 'react-datepicker';

export default class Complainants extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ComplainantList: [],
            ComplainantTypeList: [],
            ReportStartDate: new Date(moment().add(-(30), "days").format("yyyy-MM-DD HH:mm:ss")),
            ReportEndDate: new Date(moment().format("yyyy-MM-DD HH:mm:ss")),
            FilterId: 0,
            search: "",
            loading: true,
            orderBy: "DateOpenedUp",
            hideThrobble: false
        };

        this.ExportReport = this.ExportReport.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        ThrobbleHelper.ToggleThrobble(true, "Loading Complainants Report");
        const bearer = "Bearer " + localStorage.getItem("token");
        try {
            const startDate = moment(this.state.ReportStartDate).format("yyyy-MM-DD");
            const endDate = moment(this.state.ReportEndDate).format("yyyy-MM-DD");
            const response = await fetch("/api/reports/GetComplainants/" + startDate + "/" + endDate, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json"
                }
            });

            if (response.ok) {
                const data = await response.json();
                const ComplainantList = data.item1;
                const ComplainantTypeList = [{ value: 0, label: "- All -" }, ...data.item2.map(item => {
                    return { value: item.id, label: item.name }
                })];


                this.setState({ ComplainantList, ComplainantTypeList, loading: false });
                ThrobbleHelper.ToggleThrobble(false);
            } else {
                this.setState({ loading: false });
                ThrobbleHelper.ToggleThrobble(false);
            }

        } catch (e) {
            console.error("Error: ", e);
            this.setState({ loading: false })
            ThrobbleHelper.ToggleThrobble(false);
        }
    }

    renderSortArrow(header) {
        return this.state.orderBy === header + "Down" ? <i className='fa fa-long-arrow-down'></i> : this.state.orderBy === header + "Up" ? <i className='fa fa-long-arrow-up'></i> : <></>
    }

    sortBy(header) {
        if (this.state.orderBy.includes(header)) {
            if (this.state.orderBy.includes("Down")) {
                this.setState({ orderBy: header + "Up" })
            } else {
                this.setState({ orderBy: header + "Down" })
            }
        } else {
            this.setState({ orderBy: header + "Down" })
        }
    }

    renderComplainantReport(ComplaintList) {
        ComplaintList = ComplaintList.filter(item => {
            if (parseInt(this.state.FilterId) === 0) {
                return item
            } else {
                return item.complainantTypeId === parseInt(this.state.FilterId)
            }
        }).filter(item => item.complainantName.toLowerCase().includes(this.state.search.toLowerCase()));
        let result = ComplaintList.reduce((counts, item) => {
            if (item.complainantTypeId === 1) {
                counts.consumers++;
            } else if (item.complainantTypeId === 2) {
                counts.competitors++;
            }
            return counts;
        }, { consumers: 0, competitors: 0 });
        //console.log(ComplaintList)
        return (
            <>
                <Card className='my-3'>
                    <CardBody>
                        <div className="row">
                            <div className="col-md-3">
                                <label>Filter by Date Range</label>
                                <DatePicker
                                    className={'form-control form-control-sm date-picker'}
                                    selectsRange={true}
                                    placeholderText='Select Dates'
                                    startDate={this.state.ReportStartDate}
                                    endDate={this.state.ReportEndDate}
                                    dateFormat='yyyy-MM-dd'
                                    onChange={(update) => this.setState({ ReportStartDate: update[0], ReportEndDate: update[1] }, () => this.state.ReportEndDate && this.loadData())}
                                    isClearable={false}
                                />
                            </div>
                            <div className="col-md-3">
                                <label>Filter By Complianant Type</label>
                                <Select
                                    className='select2-sm'
                                    options={this.state.ComplainantTypeList}
                                    isSearchable={true}
                                    isClearable={false}
                                    backspaceRemovesValue={false}
                                    isRtl={false}
                                    value={this.state.ComplainantTypeList.filter(option => option.value === this.state.FilterId)}
                                    onChange={e => { this.setState({ FilterId: e.value }) }}
                                />
                            </div>
                            <div className="col-md-3">
                                <label>Filter By Keyword</label>
                                <input className='form-control form-control-sm w-100' style={{ width: "100%" }} placeholder='Search Complainant' onChange={(e) => this.setState({ search: e.target.value })} />
                            </div>
                            <div className="col-md-3 align-self-end text-end">
                                <Button type='button' color='primary' onClick={this.ExportReport}><i className='far fa-file-excel me-2'></i>Export Excel</Button>
                            </div>
                        </div>
                    </CardBody>
                </Card>
                <div className="d-flex gap-4">
                <Card className='my-2 col-md p-3'>
                    <span>Consumers: <strong>{result.consumers}</strong></span>
                </Card>
                    <Card className='my-2 col-md p-3'>
                        <span>Competitors: <strong>{result.competitors}</strong></span>
                    </Card>
                </div>
                <div className='table-container' >
                    <table className='table table-striped table-responsive table-hover table-sm ' style={{ border: "1px solid #201d39" }} >
                        <thead className='bg-blue'>
                            <tr style={{ padding: 0, margin: 0 }}>
                                <th style={{ width: "40%", cursor: "pointer" }} onClick={() => this.sortBy("Name")}>{this.renderSortArrow("Name")} Complainant Name</th>
                                <th style={{ width: "40%", cursor: "pointer" }} onClick={() => this.sortBy("Type")}>{this.renderSortArrow("Type")} Complainant Type </th>
                                <th style={{ width: "20%", cursor: "pointer" }} onClick={() => this.sortBy("DateOpened")}>{this.renderSortArrow("DateOpened")} Case Date Opened  </th>
                            </tr>
                        </thead>
                        <tbody>
                            {ComplaintList.length ? ComplaintList.sort((a, b) => {
                                switch (this.state.orderBy) {
                                    case "NameUp":
                                        return b.complainantName.toLowerCase().localeCompare(a.complainantName.toLowerCase());
                                    case "TypeUp":
                                        return a.complainantTypeName.localeCompare(b.complainantTypeName);
                                    case "TypeDown":
                                        return b.complainantTypeName.localeCompare(a.complainantTypeName);
                                    case "DateOpenedDown":
                                        return new Date(a.dateOpened) - new Date(b.dateOpened);
                                    case "DateOpenedUp":
                                        return new Date(b.dateOpened) - new Date(a.dateOpened);
                                    default:
                                        return a.complainantName.toLowerCase().localeCompare(b.complainantName.toLowerCase());
                                }
                            }).map((item, index) => {
                                return <tr key={index}>
                                    <td className="">{item.complainantName}</td>
                                    <td>{item.complainantTypeName}</td>
                                    <td>{moment(item.dateOpened).format("yyyy/MM/DD")}</td>
                                </tr>
                            }) :
                                <tr>
                                    <td colSpan={9} className="text-center"><b>No Complainants Yet</b></td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </>
        );
    }

    async ExportReport(e) {
        e.stopPropagation();

        ThrobbleHelper.ToggleThrobble(true, "Exporting Cases Complainants");
        const bearer = "Bearer " + localStorage.getItem("token");
        try {

            const ExportData = this.state.ComplainantList.filter(item => {
                if (parseInt(this.state.FilterId) === 0) {
                    return item
                } else {
                    return item.complainantTypeId === parseInt(this.state.FilterId)
                }
            }).filter(item => item.complainantName.toLowerCase().includes(this.state.search.toLowerCase()));

            const response = await fetch("api/reports/GenerateComplainantsReport", {
                method: "POST",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(ExportData)
            });

            if (response.ok) {
                const data = await response.json();
                const FileData = data.item1;
                const MimeType = data.item2;
                const Filename = data.item3;
                const Base64String = `data:${MimeType};base64,${FileData}`;

                // // Create a URL from the response blob
                const LinkBtn = document.createElement("a");
                LinkBtn.download = Filename;
                LinkBtn.href = Base64String;
                LinkBtn.click();
                ThrobbleHelper.ToggleThrobble(false);
                showToast("success", "Cases Report Export", "Cases report was successfully exported!");

            } else {
                if (response.status === 401) {
                    removeLoginDetails();
                    window.location.href = "/";
                }
                else {
                    showToast("error", "Cases Report Export", "There was an error exporting cases report!");
                    ThrobbleHelper.ToggleThrobble(false);
                }
            }
        } catch (e) {
            console.error(e);
            showToast("error", "Cases Report Export", "There was an error exporting cases report!");
            ThrobbleHelper.ToggleThrobble(false);
        }
    }

    render() {

        const content = this.state.loading ? <>Loading...</> : this.renderComplainantReport(this.state.ComplainantList);
        return (
            <div>
                <div className='dms-header d-flex justify-content-center p-3 align-items-center '>
                    <h1 style={{ fontSize: "30px", fontWeight: 100 }}>COMPLAINANTS</h1>
                </div>


                <div className="">
                    {content}
                </div>
                <ThrobbleHelper />

            </div>
        )
    }
}
