/* eslint-disable default-case */
import { ToastUtility } from "@syncfusion/ej2-notifications"
import ThrobbleHelper from "./ThrobbleHelper";

export const showToast = (type, title, content, loadMethod) => {
    const timeOut = 5000;
    const showCloseButton = true;
    const position = { X: "Right", Y: "Top" }
    setTimeout(() => {
        switch (type) {
            case "success":
                ToastUtility.show({
                    title,
                    content,
                    cssClass: "e-toast-success",
                    position,
                    timeOut,
                    showCloseButton,
                });
                break;
            case "error":
                ToastUtility.show({
                    title,
                    content,
                    cssClass: "e-toast-danger",
                    position,
                    timeOut,
                    showCloseButton,
                })
                break;
            case "info":
                ToastUtility.show({
                    title,
                    content,
                    cssClass: "e-toast-info",
                    position,
                    timeOut,
                    showCloseButton,
                })
                break;
        }
        // ThrobbleHelper.oggleThrobble(false);
    }, 500)

}