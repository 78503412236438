//Using this while I set up my visual studio for the backend
export function getCurrentUser() {
    let user = localStorage.getItem('user');
    return JSON.parse(user);
}

export function setCurrentUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem("token", user.loginCookie)
}
