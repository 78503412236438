import moment from 'moment';
import React, { Component } from 'react';
import Select from 'react-select';
import { Button, Card, CardBody, Input } from 'reactstrap';
import { ReplaceHTMLFromString } from '../../helpers/global';
import { removeLoginDetails } from '../../helpers/Lookup';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { showToast } from '../../helpers/ToastHelper';

export default class Attachments extends Component {

    constructor(props) {
        super(props)
        this.state = {
            Attachments: [],
            CaseList: [],
            ddCase: [],
            orderBy: "CaseDown",
            loading: true,
            hideThrobble: false,
            FilterId: 0,
            search: "",
        }

        this.renderAttachmentReport = this.renderAttachmentReport.bind(this);
        this.ExportReport = this.ExportReport.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        try {
            ThrobbleHelper.ToggleThrobble(true, "Loading Attachment Report");
            const bearer = "Bearer " + localStorage.getItem("token");
            const response = await fetch("api/reports/GetAttachmentReport", {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json"
                }
            });

            if (response.ok) {
                const data = await response.json();
                const Attachments = data.item1;
                const CaseList = data.item2;

                const ddCase = [{ value: 0, label: "- All -" }, ...CaseList.map(item => {
                    return { value: item.id, label: this.zeroPad(item.caseNumber, 5) + " - " + ReplaceHTMLFromString(item.description) };
                })];
                this.setState({ Attachments, CaseList, ddCase, loading: false });
                ThrobbleHelper.ToggleThrobble(false);
            } else {
                if (response.status === 401) {
                    removeLoginDetails();
                    window.location.href = "/";
                } else {
                    ThrobbleHelper.ToggleThrobble(false);
                }
            }
        } catch (e) {
            console.error(e);
            ThrobbleHelper.ToggleThrobble(false);
        }
    }

    sortBy(header) {
        if (this.state.orderBy.includes(header)) {
            if (this.state.orderBy.includes("Down")) {
                this.setState({ orderBy: header + "Up" })
            } else {
                this.setState({ orderBy: header + "Down" })
            }
        } else {
            this.setState({ orderBy: header + "Down" })
        }
    }

    renderSortArrow(header) {
        return this.state.orderBy === header + "Down" ? <i className='fa fa-long-arrow-down'></i> : this.state.orderBy === header + "Up" ? <i className='fa fa-long-arrow-up'></i> : <></>
    }

    renderAttachmentReport(FilterId, Attachments) {
        if (FilterId > 0) {
            Attachments = Attachments.filter(c => c.caseId === FilterId);
        }
        return (
            <table className='table table-striped' style={{ border: "1px solid #201d39" }}>
                <thead>
                    <tr className='bg-blue'>
                        <th onClick={() => this.sortBy("Case")}>{this.renderSortArrow("Case")} Case</th>
                        <th onClick={() => this.sortBy("Name")}>{this.renderSortArrow("Name")} Name</th>
                        <th onClick={() => this.sortBy("Date")}>{this.renderSortArrow("Date")} Date</th>
                        <th onClick={() => this.sortBy("UploadedBy")}>{this.renderSortArrow("UploadedBy")} Uploaded By</th>
                    </tr>
                </thead>
                <tbody>
                    {Attachments.length ? Attachments.sort((a, b) => {
                        switch (this.state.orderBy) {
                            case "CaseUp":
                                return b.case.localeCompare(a.case)
                            case "NameDown":
                                return a.name.localeCompare(b.name)
                            case "NameUp":
                                return b.name.localeCompare(a.name)
                            case "DateDown":
                                return new Date(a.date) - new Date(b.date)
                            case "DateUp":
                                return new Date(b.date) - new Date(a.date);
                            case "UploadedByDown":
                                return a.uploadedBy.localeCompare(b.uploadedBy)
                            case "UploadedByUp":
                                return b.uploadedBy.localeCompare(a.uploadedBy)
                            default:
                                return a.case.localeCompare(b.case)
                        }
                    }).map((attachment, index) => {
                        return (
                            <tr key={index}>
                                <td>{attachment.case}</td>
                                <td>{attachment.name}</td>
                                <td>{moment(attachment.date).format("yyyy-MM-DD")}</td>
                                <td>{attachment.uploadedBy}</td>
                            </tr>
                        )
                    }) : <tr><td className='text-center' colSpan={6}>No attachments found</td></tr>}
                </tbody>
            </table>
        )
    }

    zeroPad = (num, places) => String(num).padStart(places, '0');

    render() {
        let data = this.state.Attachments;
        if (this.state.search) {
            data = data.filter(item => item.name.toLowerCase().includes(this.state.search.toLowerCase()) || item.case.toLowerCase().includes(this.state.search.toLowerCase()))
        }
        const content = this.state.loading ? <></> : this.renderAttachmentReport(this.state.FilterId, data, this.state.CaseList);
        return (
            <div>
                <div className='dms-header d-flex justify-content-center p-3 align-items-center '>
                    <h1 style={{ fontSize: "30px", fontWeight: 100 }}>ATTACHMENTS</h1>
                </div>
                <Card className='my-5'>
                    <CardBody>
                        <div className="row">
                            <div className="col-md-5 mb-2">
                                <label>Filter By Case</label>
                                <Select
                                    className='select2-sm'
                                    options={this.state.ddCase}
                                    isSearchable={true}
                                    isClearable={false}
                                    backspaceRemovesValue={false}
                                    isRtl={false}
                                    value={this.state.ddCase.filter(option => option.value === this.state.FilterId)
                                    }
                                    onChange={e => this.setState({ FilterId: e.value })}
                                />
                            </div>
                        <div className="col-md-4 mb-2">
                                <label>Filter By Keyword</label>
                                <input className='form-control form-control-sm' placeholder='Search...' onChange={(e) => this.setState({ search: e.target.value })} />
                        </div>
                            <div className="col-md-3 align-self-end text-end mb-2">
                                <Button color='primary' onClick={this.ExportReport}><i className='far fa-file-excel me-2'></i>Export To Excel</Button>
                            </div>
                        </div>
                    </CardBody>
                </Card>
                {content}
                <ThrobbleHelper />
            </div>
        )
    }

    async ExportReport(evt) {
        evt.stopPropagation();

        ThrobbleHelper.ToggleThrobble(true, "Exporting Attachment Report")

        try {
            let data = this.state.Attachments;
            this.setState({ hideThrobble: false });
            if (this.state.FilterId > 0)
                data = data.filter(c => c.caseId === this.state.FilterId);

            const bearer = "Bearer " + localStorage.getItem("token");

            const response = await fetch("api/reports/GenerateAttachmentReport", {
                method: "POST",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                const data = await response.json();
                const FileData = data.item1;
                const MimeType = data.item2;
                const Filename = data.item3;
                const Base64String = `data:${MimeType};base64,${FileData}`;

                // // Create a URL from the response blob
                const LinkBtn = document.createElement("a");
                LinkBtn.download = Filename;
                LinkBtn.href = Base64String;
                LinkBtn.click();
                ThrobbleHelper.ToggleThrobble(false);
                showToast("success", "Attachment Report Export", "Attachment report was successfully exported");
            } else {
                if (response.status === 401) {
                    removeLoginDetails();
                    window.location.href = "/";
                } else {
                    ThrobbleHelper.ToggleThrobble(false);
                    showToast("error", "Attachment Report Export", "There was an error exporting attachment report");
                }
            }
        } catch (e) {
            console.error(e);
            ThrobbleHelper.ToggleThrobble(false);
            showToast("error", "Attachment Report Export", "There was an error exporting attachment report");
        }
    }
}