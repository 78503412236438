import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ConfirmHelper from '../../helpers/ConfirmHelper';
import { fieldRequired } from '../../helpers/fieldRequired';
import { getCurrentUser } from '../../helpers/GetCurrentUser';
import { removeLoginDetails } from '../../helpers/Lookup';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { showToast } from '../../helpers/ToastHelper';

export default class MembershipStatuses extends Component {

  constructor(props) {
    super(props);
    this.state = {
      statuses: [],
      status: {
        AddedBy: getCurrentUser().id,
      },
      statusModal: false,
      orderBy: 'NameDown'
    };
    this.statusModalToggle = this.statusModalToggle.bind(this);
  }

  statusModalToggle = () => {
    this.setState({ statusModal: !this.state.statusModal })
  }

  updateStatus = () => {
    let valid = true;
    valid &= fieldRequired(this.state.status.name, 'status-error', "* required");
    //valid &= fieldRequired(this.state.status.color, 'color-error', "* required");

    if (valid) {
      this.statusModalToggle();
      this.saveStatus();
    }
  }


  outcomesModalToggle = () => {
    this.setState({ outcomesModal: !this.state.outcomesModal })
  }

  updateOutcome = () => {
    let valid = true;
    valid &= fieldRequired(this.state.outcome.name, 'outcome-error', "* required");
    valid &= fieldRequired(this.state.outcome.color, 'outcome-color-error', "* required");

    if (valid) {
      this.outcomesModalToggle();
      this.saveOutcome();
    }
  }

  componentDidMount() {
    this.loadData();
  }

  sortBy(header) {
    if (this.state.orderBy.includes(header)) {
      if (this.state.orderBy.includes("Down")) {
        this.setState({ orderBy: header + "Up" })
      } else {
        this.setState({ orderBy: header + "Down" })
      }
    } else {
      this.setState({ orderBy: header + "Down" })
    }
  }

  renderSortArrow(header) {
    return this.state.orderBy === header + "Down" ? <i className='fa fa-long-arrow-down'></i> : this.state.orderBy === header + "Up" ? <i className='fa fa-long-arrow-up'></i> : <></>
  }


  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-6"><h1>Membership Types</h1></div>
          <div className="col-md-6 text-end align-self-center">
            <Button color="primary" onClick={() => {
              this.setState({ status: { addedBy: getCurrentUser().id } })
              this.statusModalToggle()
            }}>Add Membership Type <i className='fa fa-plus-circle ms-2'></i></Button>
          </div>
        </div>
        <div>
          <table className="table table-striped">
            <thead className='bg-blue'>
              <tr>
                <td style={{ cursor: "pointer" }} onClick={() => this.sortBy("Name")}>{this.renderSortArrow("Name")} Name</td>
                <td className='text-end'>Action</td>
              </tr>
            </thead>
            <tbody>
              {this.state.statuses.length ? this.state.statuses.sort((a, b) => {
                switch (this.state.orderBy) {
                  case "NameDown":
                    return a.name.localeCompare(b.name);
                  case "NameUp":
                    return b.name.localeCompare(a.name);
                  default:
                    return a.name.localeCompare(b.name);
                }
              }).map((status, index) => {
                return (
                  <tr key={index}>
                    <td>{status.name}</td>
                    <td >
                      <div className='d-flex justify-content-end'>
                        <div onClick={() => {
                          this.setState({ status });
                          this.statusModalToggle();
                        }}><i className='fa fa-edit mx-2' title="Edit item"></i></div>
                        <div onClick={() => this.deleteStatus(status.id)}><i className='fa fa-trash-alt text-danger' title="Delete item"></i></div>
                      </div>
                    </td>
                  </tr>
                )
              }) : <tr><td colSpan='3' className='text-center'> No Membership Types Found</td></tr>}
            </tbody>
          </table>
        </div>

        <Modal isOpen={this.state.statusModal}>
          <ModalHeader>Edit Membership Type</ModalHeader>
          <ModalBody>

            <label htmlFor="status" className="form-label">
              Status
              <span className='error' id='status-error'></span>
            </label>
            <input type="text" className="form-control" id="status" name="status" value={this.state.status.name} onChange={(e) => this.setState({ status: { ...this.state.status, name: e.target.value } })} />

          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.statusModalToggle}>Cancel<i className="far fa-times-circle ms-2"></i></Button>
            <Button color="primary" onClick={this.updateStatus}>Save<i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>
        <ThrobbleHelper />
      </div>
    )
  }

  loadData = async () => {
    try {
      ThrobbleHelper.ToggleThrobble(true, "Loading Membership Types");
      const responses = await fetch('/api/membershipstatuses', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + localStorage.getItem('token')
        }
      })
      if (responses.ok) {
        const data = await responses.json();
        this.setState({ statuses: data });

        ThrobbleHelper.ToggleThrobble(false, true);
      } else {
        if (responses.status === 401) {
          removeLoginDetails();
          window.location.href = "/";
        } else {
          ThrobbleHelper.ToggleThrobble(false, true);
        }
      }
    } catch (error) {
      ThrobbleHelper.ToggleThrobble(false, true);
      console.error(error)
    }
  }

  saveStatus = async () => {
    try {
      ThrobbleHelper.ToggleThrobble(true, "Saving Membership Type");
      const data = { ...this.state.status, modifiedBy: getCurrentUser().id };
      const response = await fetch('api/membershipstatuses', {
        method: this.state.status.id ? "PUT" : 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + localStorage.getItem('token')
        },
        body: JSON.stringify(data)

      })
      if (response.ok) {
        this.loadData();
        showToast("success", "Save Membership Type", "Membership type was successfully saved!");
      } else {
        if (response.status === 401) {
          removeLoginDetails();
          window.location.href = "/";
        } else {
          ThrobbleHelper.ToggleThrobble(false, true);
          showToast("error", "Save Membership Type", "There was an error saving membership type");
        }
      }

    } catch (error) {
      console.error(error);
      ThrobbleHelper.ToggleThrobble(false, true);
      showToast("error", "Save Membership Type", "There was an error saving membership type");
    }
  }

  deleteStatus = (id) => {
    const ConfirmProps = { DeleteId: id, Title: "Delete Membership Type", Message: "Are you sure you want to delete this membership type?" };
    confirmAlert({
      customUI: ({ onClose }) => <ConfirmHelper {...ConfirmProps} onClose={onClose} onConfirm={this.deleteStatusData} />
    })
  }

  deleteStatusData = async (id) => {
    try {
      ThrobbleHelper.ToggleThrobble(true, "Deleting Membership Type");
      const response = await fetch('api/membershipstatuses/' + id, {
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + localStorage.getItem('token')
        },
      })
      if (response.ok) {
        this.loadData();
        showToast("success", "Delete Membership Type", "Membership type was successfully deleted!");
      } else {
        if (response.status === 401) {
          removeLoginDetails();
          window.location.href = "/";
        } else {
          ThrobbleHelper.ToggleThrobble(false, true);
          showToast("error", "Delete Membership Type", "There was an error deleting membership type");
        }
      }
    } catch (error) {
      console.error(error);
      ThrobbleHelper.ToggleThrobble(false, true);
      showToast("error", "Delete Membership Status", "There was an error deleting membership status");
    }
  }
}
