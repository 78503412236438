import React, { Component } from 'react';
import { SketchPicker } from 'react-color';
import { confirmAlert } from 'react-confirm-alert';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ConfirmHelper from '../../helpers/ConfirmHelper';
import { fieldRequired } from '../../helpers/fieldRequired';
import { getCurrentUser } from '../../helpers/GetCurrentUser';
import { removeLoginDetails } from '../../helpers/Lookup';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { showToast } from '../../helpers/ToastHelper';

export default class Level extends Component {
    constructor(props) {
        super(props);
        this.state = {
            levels: [],
            level: {
                color: "#ffffff",
                AddedBy: getCurrentUser().id,
                ModifiedBy: getCurrentUser().id,
            },
            levelModal: false,
            orderBy: 'LevelDown',
            colorOpen: false
        }
        this.levelModalToggle = this.levelModalToggle.bind(this);
        this.updateLevel = this.updateLevel.bind(this);
    }

    componentDidMount() {
        this.loadLevel();
    }

    levelModalToggle = () => {
        this.setState({ levelModal: !this.state.levelModal })
        console.log(this.state.level)
    }

    updateLevel = () => {
        let valid = true;
        valid &= fieldRequired(this.state.level.name, 'level-error', "* required");
        valid &= fieldRequired(this.state.level.color, 'level-color-error', "* required");

        if (valid) {
            this.levelModalToggle();
            this.saveLevel();
        }
    }
    handleColorChange = (color, type) => {
        this.setState({ [type]: { ...this.state[type], color: color.hex } })
    }

    sortBy(header) {
        if (this.state.orderBy.includes(header)) {
            if (this.state.orderBy.includes("Down")) {
                this.setState({ orderBy: header + "Up" })
            } else {
                this.setState({ orderBy: header + "Down" })
            }
        } else {
            this.setState({ orderBy: header + "Down" })
        }
    }

    renderSortArrow(header) {
        return this.state.orderBy === header + "Down" ? <i className='fa fa-long-arrow-down'></i> : this.state.orderBy === header + "Up" ? <i className='fa fa-long-arrow-up'></i> : <></>
    }

    render() {
        return <div>
            <div className='d-flex justify-content-between align-items-center'>
                <div>
                    <h1>Levels</h1>
                </div>
                <div>
                    <Button color="primary" onClick={() => {
                        this.setState({ level: { color: '#ffffff', addedBy: getCurrentUser().id, modifiedBy: getCurrentUser().id }, colorOpen: false })
                        this.levelModalToggle()
                    }}>Add Level <i className='fa fa-plus-circle ms-2'></i></Button>
                </div>
            </div>
            <div>
                <table className="table table-striped">
                    <thead className='bg-blue'>
                        <tr>
                            <td style={{ cursor: "pointer" }} onClick={() => this.sortBy("Level")}>{this.renderSortArrow("Level")} Level</td>
                            <td style={{ cursor: "pointer" }} onClick={() => this.sortBy("Color")}>{this.renderSortArrow("Color")} Color</td>
                            <td className='text-end'>Action</td>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.levels.length ? this.state.levels.sort((a, b) => {
                            switch (this.state.orderBy) {
                                case "LevelDown":
                                    return a.name.localeCompare(b.name);
                                case "LevelUp":
                                    return b.name.localeCompare(a.name);
                                case "ColorDown":
                                    return a.color.localeCompare(b.color);
                                case "ColorUp":
                                    return b.color.localeCompare(a.color);
                                default:
                                    return a.name.localeCompare(b.name);
                            }
                        }).map((level, index) => {

                            return (
                                <tr key={index}>
                                    <td>{level.name}</td>
                                    <td><div style={{ display: "inline-block", paddingLeft: "16px", width: "16px", height: "16px", backgroundColor: level.color, border: "1px solid #000" }}></div> {level.color}</td>
                                    <td >
                                        <div className='d-flex justify-content-end'>
                                            <div onClick={() => {
                                                this.setState({ level, colorOpen: true })
                                                this.levelModalToggle();
                                            }}><i className='fa fa-edit mx-2' title="Edit item"></i></div>
                                            <div onClick={() => this.deleteLevel(level.id)}><i className='fa fa-trash-alt text-danger' title="Delete item"></i></div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        }) : <tr><td colSpan='6' className='text-center'>No Levels Found</td></tr>}
                    </tbody>
                </table>
            </div>
            <Modal isOpen={this.state.levelModal}>
                <ModalHeader>Edit Level</ModalHeader>
                <ModalBody>
                    <div className='row'>
                        <div className="mb-3 col-12">
                            <label htmlFor="level" className="form-label">
                                Level
                                <div className='error' id='level-error'></div>
                            </label>
                            <input type="text" className="form-control" id="level" name="level" value={this.state.level.name} onChange={(e) => this.setState({ level: { ...this.state.level, name: e.target.value } })} />
                        </div>
                        <div className="mb-3 col-12">
                            <label htmlFor="color" className="form-label">
                                Color
                                <div className='error' id='level-color-error'></div>
                            </label>
                            <SketchPicker
                                color={this.state.level.color}
                                onChange={(e) => this.handleColorChange(e, "level")}
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.levelModalToggle}>Cancel<i className="far fa-times-circle ms-2"></i></Button>
                    <Button color="primary" onClick={this.updateLevel}>Save<i className="far fa-check-circle ms-2"></i></Button>
                </ModalFooter>
            </Modal>

            <ThrobbleHelper />
        </div>
    }

    loadLevel = async () => {
        try {
            ThrobbleHelper.ToggleThrobble(true, "Loading Levels");
            const responses = await fetch('/api/levels', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            if (responses.ok) {
                const data = await responses.json();
                this.setState({ levels: data });
                ThrobbleHelper.ToggleThrobble(false, true);
            } else {
                if (responses.status === 401) {
                    removeLoginDetails();
                    window.location.href = "/";
                } else {
                    ThrobbleHelper.ToggleThrobble(false, true);
                }
            }

        } catch (error) {
            console.error(error);
            ThrobbleHelper.ToggleThrobble(false, true);
        }
    }


    saveLevel = async () => {
        try {
            ThrobbleHelper.ToggleThrobble(true, "Saving level");
            let data = { ...this.state.level };
            data.modifiedBy = getCurrentUser().id;

            const response = await fetch('/api/levels', {
                method: this.state.level.id ? "PUT" : 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem('token')
                },
                body: JSON.stringify(data)
            })
            if (response.ok) {
                this.loadLevel();
                // ThrobbleHelper.ToggleThrobble(false, true);
                showToast("success", "Save Level", "Level was successfully saved");
            } else {
                if (response.status === 401) {
                    removeLoginDetails();
                    window.location.href = "/";
                } else {
                    ThrobbleHelper.ToggleThrobble(false, true);
                    showToast("error", "Save Level", "There was an error saving level");

                }
            }

        } catch (error) {
            console.error(error);
            ThrobbleHelper.ToggleThrobble(false, true);
            showToast("error", "Save Level", "There was an error saving level");
        }
    }

    deleteLevel = (id) => {
        const ConfirmProps = { DeleteId: id, Title: "Delete Level", Message: "Are you sure you want to delete this level?" }
        confirmAlert({
            customUI: ({ onClose }) => <ConfirmHelper {...ConfirmProps} onClose={onClose} onConfirm={this.deleteLevelData} />
        })
    }

    deleteLevelData = async (id) => {
        ThrobbleHelper.ToggleThrobble(true, "Deleting level");
        try {
            const response = await fetch('/api/levels/' + id, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem('token')
                },
            })
            if (response.ok) {
                this.loadLevel();
                // ThrobbleHelper.ToggleThrobble(false, true);
                showToast("success", "Delete Level", "Level was successfully deleted");
            } else {
                if (response.status === 401) {
                    removeLoginDetails();
                    window.location.href = "/";
                } else {
                    ThrobbleHelper.ToggleThrobble(false, true);
                    showToast("error", "Delete Level", "There was an error deleting level");
                }
            }

        } catch (error) {
            console.error(error);
            ThrobbleHelper.ToggleThrobble(false, true);
            showToast("error", "Delete Level", "There was an error deleting level");
        }
    }

}
