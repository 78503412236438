//import { idText } from "typescript";

export const GetColor = (index) => {
    const ColorArr = ["#D72E27", "#2B2849", "#DF6F6B", "#9BD8F2", "#F2EA2C", "#CF8261", "#A1469A", "#66C3A4", "#A02023", "#F7991D", "#D72F27", "#327DC0", "#929292", "#DCDCDB"];
    return ColorArr[index % ColorArr.length];
}


export const getAuthToken = () => {
    if (window.localStorage.getItem("token")) {
        return window.localStorage.getItem("token");
    } else
        return "";
}

export const setAuthToken = (data) => {
    try {
        window.localStorage.setItem("token", data);
        return true;
    }catch(e) {
        console.error(e);
        return false;
    }
}

export const removeLoginDetails = () => {
    try {
        window.localStorage.clear();
    } catch (e) {
        console.error(e);
    }
}