import parse from 'html-react-parser';
import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Select from 'react-select';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import ConfirmHelper from '../../helpers/ConfirmHelper';
import { fieldRequired } from '../../helpers/fieldRequired';
import { getCurrentUser } from '../../helpers/GetCurrentUser';
import { ReplaceHTMLFromString } from '../../helpers/global';
import { removeLoginDetails } from '../../helpers/Lookup';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { showToast } from '../../helpers/ToastHelper';

class AssociatedCases extends Component {

    constructor(props) {
        super(props);

        this.state = {
            AssociatedCaseList: [], CasesList: [],
            CaseId: this.props.CaseID, AssociatedCaseId: 0, loading: true,
            associatedCaseModal: false
        }

        this.toggleAssociatedCaseModal = this.toggleAssociatedCaseModal.bind(this);
        this.saveAssociatedCase = this.saveAssociatedCase.bind(this);
        this.clearForm = this.clearForm.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        const bearer = `Bearer ${localStorage.getItem("token")}`;
        try {
            const response = await fetch(`api/combinedcases/GetAllLinkedCases/${this.state.CaseId}`, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json"
                }
            })

            if (response.ok) {
                const data = await response.json();
                const AssociatedCaseList = data.item1;
                const CasesList = data.item2;
                this.setState({ AssociatedCaseList, CasesList, loading: false });
            }
        } catch (e) {
            console.error(e);
        }
    }

    zeroPad = (num, places) => String(num).padStart(places, '0');

    renderAssociatedCases() {
        return (
            <table className='table table-sm table-striped mt-2' >
                <thead className='bg-blue'>
                    <tr>
                        <th style={{ width: "20%" }}>Case Number</th>
                        <th style={{ width: "70%" }}>Description</th>
                        <th className="text-end" style={{ width: "10%" }}>Actions</th>
                    </tr>
                </thead><tbody>
                    {
                        this.state.AssociatedCaseList.length ?
                            (
                                this.state.AssociatedCaseList.map(item => {
                                    let caseItem = null;
                                    if (this.state.CaseId === item.caseId1)
                                        caseItem = this.state.CasesList.find(c => c.id === item.caseId2);
                                    else
                                        caseItem = this.state.CasesList.find(c => c.id === item.caseId1);

                                    return { Id: item.id, CaseIdx: caseItem.caseNumber, CaseNumber: this.zeroPad(caseItem.caseNumber, 5), Description: parse(caseItem.description) };
                                }).sort((a, b) => a.CaseIdx - b.CaseIdx).map((item, index) => {
                                    return <tr key={index}>
                                        <td>{item.CaseNumber}</td>
                                        <td className="text-start">{item.Description}</td>
                                        <td className="text-end">
                                            <i className='fa fa-trash-alt text-danger' style={{ cursor: "pointer" }} title="Delete attachment" onClick={() => this.deleteAssociatedCase(item.Id)}></i>
                                        </td>
                                    </tr>

                                })
                            ) :
                            (
                                <tr><td colSpan={3}><p className="text-center"><i>There are currently no associated cases</i></p></td></tr>
                            )
                    }
                </tbody>
            </table>

        )
    }

    deleteAssociatedCase(id) {
        const ConfirmProps = { DeleteId: id, Title: "Delete Associated Case", Message: "Are you sure you want to delete this associated case?" };
        confirmAlert({
            customUI: ({ onClose }) => <ConfirmHelper {...ConfirmProps} onClose={onClose} onConfirm={this.deleteAssociatedCaseData} />
        })
    }

    async deleteAssociatedCaseData(id) {
        ThrobbleHelper.ToggleThrobble(true, "Deleting Associated Case")
        try {
            const bearer = "Bearer " + localStorage.getItem("token");
            const response = await fetch("api/combinedcases/" + id, {
                method: "DELETE",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json"
                }
            });

            if (response.ok) {
                this.loadData();
                ThrobbleHelper.ToggleThrobble(false, true);
                showToast("success", "Delete Associated Case", "Associated case was successfully deleted");
            } else {
                if (response.status === 401) {
                    removeLoginDetails();
                    window.location.href = "/";
                } else {
                    ThrobbleHelper.ToggleThrobble(false, true);
                    showToast("error", "Delete Associated Case", "There was an error deleting associated case");
                }
            }
        } catch (e) {
            console.error(e);
            ThrobbleHelper.ToggleThrobble(false, true);
            showToast("error", "Delete Associated Case", "There was an error deleting associated case");

        }
    }

    toggleAssociatedCaseModal() {
        this.clearForm();
        this.setState({ associatedCaseModal: !this.state.associatedCaseModal })
    }

    GetCaseDropdown(CaseId, Cases, Associated) {
        let options = [{ value: 0, label: "- Select Case -", isOptionSelected: this.state.AssociatedCaseId === 0 }]
        Cases = Cases.sort((a, b) => a.caseNumber - b.caseNumber).filter(f => f.id !== CaseId && Associated.find(g => g.caseId1 === f.id || g.caseId2 === f.id) == null);
        Cases.map(item => options.push({ value: item.id, label: this.zeroPad(item.caseNumber, 5) + " - " + ReplaceHTMLFromString(item.description), isOptionSelected: this.state.AssociatedCaseId === item.id }));

        return (
            <Select
                className='select2-sm'
                options={options}
                isSearchable={true}
                isClearable={false}
                backspaceRemovesValue={false}
                isRtl={false}
                value={options.filter(option => option.value === this.state.AssociatedCaseId)}
                onChange={e => { this.setState({ AssociatedCaseId: e.value }, () => fieldRequired(e.value, "errCase", "* Required")) }}
            />
        )
    }

    render() {

        const content = this.state.loading ? <em>Loading...</em> : this.renderAssociatedCases();

        const CaseOptions = this.GetCaseDropdown(this.state.CaseId, this.state.CasesList, this.state.AssociatedCaseList);
        return (
            <>
                <Row className='border-bottom my-4'>
                    <Col md={6}><h5>ASSOCIATED CASES</h5></Col>
                    <Col md={6} className='text-end'>
                        <button className="btn btn-pastel-blue btn-sm" onClick={this.toggleAssociatedCaseModal}>Add Case<i className='fa fa-plus-circle ms-2'></i></button>
                    </Col>
                    <Col md={12}>
                        {content}
                    </Col>
                </Row>

                <Modal isOpen={this.state.associatedCaseModal} toggle={this.toggleAssociatedCaseModal}>
                    <ModalHeader toggle={this.toggleAssociatedCaseModal} close={<button className='btn-close' onClick={this.toggleAssociatedCaseModal}></button>}>
                        Add Associated Case
                    </ModalHeader>
                    <ModalBody>
                        <div className="mb-3">
                            <label>Associated Case
                                <div className='error ms-2' id="errCase"></div></label>
                            {CaseOptions}
                            {/* <select className='form-select form-select-sm' value={this.state.AssociatedCaseId} onChange={e => this.setState({ AssociatedCaseId: e.target.value }, () => fieldRequired(e.target.value, "errCase", "* Required"))}>

                                {
                                    this.state.CasesList.length && this.state.CasesList.filter(f => f.id !== this.state.CaseId && this.state.AssociatedCaseList.find(g => g.caseId1 === f.id || g.caseId2 === f.id) == null).sort((a, b) => a.caseNumber - b.caseNumber).map((item, idx) =>
                                        <option key={idx} value={item.id}>{this.zeroPad(item.caseNumber, 5)} - {item.description.replace("<p>", "").replace("</p>", "")}</option>
                                    )
                                }
                            </select> */}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='secondary' size='sm' onClick={this.toggleAssociatedCaseModal}>Close<i className="far fa-times-circle ms-2"></i></Button>
                        <Button color='primary' size='sm' onClick={this.saveAssociatedCase}>Save<i className='far fa-check-circle ms-2'></i></Button>
                    </ModalFooter>
                </Modal>

                <ThrobbleHelper />
            </>
        );
    }

    async saveAssociatedCase(evt) {
        evt.stopPropagation();

        let isValid = true;
        isValid &= fieldRequired(this.state.AssociatedCaseId, "errCase", "* Required");

        if (isValid) {
            ThrobbleHelper.ToggleThrobble(true, "Saving Associated Case");
            this.toggleAssociatedCaseModal();
            const bearer = "Bearer " + localStorage.getItem("token");
            try {
                const data = { CaseId1: this.state.CaseId, CaseId2: this.state.AssociatedCaseId, AddedBy: getCurrentUser().Id, DateAdded: new Date() };
                const response = await fetch("api/combinedcases", {
                    method: "POST",
                    headers: {
                        Authorization: bearer,
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                });

                if (response.ok) {
                    this.loadData();
                    this.clearForm();
                    ThrobbleHelper.ToggleThrobble(false, true);
                    showToast("success", "Save Associated Case", "Associated case was successfully saved");
                } else {
                    if (response.status === 401) {
                        removeLoginDetails();
                        window.location.href = "/";
                    } else {
                        showToast("error", "Save Associated Case", "There was an error saving associated case");
                        ThrobbleHelper.ToggleThrobble(false, true);
                    }

                }
            } catch (e) {
                console.error(e);
                showToast("error", "Save Associated Case", "There was an error saving associated case");
                ThrobbleHelper.ToggleThrobble(false, true);
            }
        }
    }

    clearForm() {
        this.setState({
            AssociatedCaseId: 0,
        });
    }
}

export default AssociatedCases;
