import parse from 'html-react-parser';
import moment from 'moment';
import React, { Component } from 'react';
import Select from 'react-select';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, Row } from 'reactstrap';
import { myContext } from '../Context';
import { getCurrentUser } from '../helpers/GetCurrentUser';
import ThrobbleHelper from '../helpers/ThrobbleHelper';

class CalendarComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cases: [],
      statuses: [],
      filterCase: getCurrentUser().id,

    }
  }

  componentDidMount() {
    this.loadCases()
    this.loadStatuses()
  }

  eventStyleGetter = (event, start, end, isSelected) => {

    return {
      style: {
        backgroundColor: event.color,
      },
    };
  }

  handleEventClick = (event) => {
    //this.context.setCaseId(event.Id);
    this.props.navigator("/case-file/" + event.Id);
  }

  render() {
    const regexHtmlStripper = /(<([^>]+)>)/gi;
    let data = this.state.cases.filter(item => item.latestTask != null);
    if (this.state.filterCase !== 0) {
      //data = data.filter(item => item.assignedTo === this.state.filterCase);
      //if (ite)
      data = data.filter(item => item.latestTask.fileWithId === this.state.filterCase);
    }
    const localizer = momentLocalizer(moment)

    let events = data.map(item => {
      return {
        title: `<b>Case No.:</b> ${item.caseNumber} - ${item.description.replace(regexHtmlStripper, '')} <br /> <b>Task: </b> ${item.latestTask.taskName} - ${item.latestTask.notes.replace(regexHtmlStripper, '')}`,
        tooltipTitle: item.caseNumber + " - " + item.latestTask.taskName,
        start: new Date(item.latestTask.dateDue),
        end: new Date(item.latestTask.dateDue),
        allDay: true,
        color: this.state.statuses.find(x => x.id === item.status)?.color,
        actualDescription: item.description.replace(regexHtmlStripper, ""),
        Id: item.id
      }
    })

    const options = [{ label: 'All', value: 0 }, { label: 'Assigned To Me', value: getCurrentUser().id }]
    // console.log(this.state, options.find(item => item.value === this.state.filterCase).label)
    return (
      <div>
        <Row><h1>Case Schedules</h1></Row>
        <Card className='my-3'>
          <CardBody>
            <div className="row">
              <div className="col-md-4">
                <label>Filter By Cases</label>
                <Select
                  className='select2-sm'
                  options={options}
                  isSearchable={true}
                  isClearable={false}
                  backspaceRemovesValue={false}
                  isRtl={false}
                  value={options.find(item => item.value === this.state.filterCase)}
                  onChange={e => this.setState({ filterCase: e.value })}
                />
              </div>
            </div>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <Calendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              style={{ height: "90vh" }}
              eventPropGetter={this.eventStyleGetter}
              onSelectEvent={this.handleEventClick}
              showAllEvents={true}
              titleAccessor={(props) => <div dangerouslySetInnerHTML={{ __html: props.title }}></div>}
              tooltipAccessor={(props) => props.tooltipTitle}
            />
          </CardBody>
        </Card>
        <ThrobbleHelper />
      </div>
    )
  }

  async loadCases() {
    try {
      this.setState({ loading: true });
      ThrobbleHelper.ToggleThrobble(true, "Loading Case");
      const response = await fetch('api/cases/GetAllForCaseLoad', {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
      });

      if (response.ok) {
        let data = await response.json();
        data = data.filter(item => item.statusId !== 4)
        console.log(data);
        this.setState({ cases: data, loading: false });
        ThrobbleHelper.ToggleThrobble(false);
      } else {
        if (response.status === 401) {
          localStorage.removeItem('token');
          window.location.href = "/";
        } else
          ThrobbleHelper.ToggleThrobble(false);
      }

    } catch (error) {
      console.error(error)
      ThrobbleHelper.ToggleThrobble(false);

    }
  }

  async loadStatuses() {
    try {
      const response = await fetch('/api/status', {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        this.setState({ statuses: data });
      } else {
        if (response.status === 401) {
          localStorage.removeItem('token');
          window.location.href = "/";
        }
      }

    } catch (error) {
      console.error(error)
    }
  }
}


export default function CalendarComponent() {
  const navigator = useNavigate()
  return (
    <CalendarComp navigator={navigator} />
  )
}

CalendarComp.contextType = myContext;