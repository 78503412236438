import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ConfirmHelper from '../../helpers/ConfirmHelper';
import { fieldRequired } from '../../helpers/fieldRequired';
import { getCurrentUser } from '../../helpers/GetCurrentUser';
import { removeLoginDetails } from '../../helpers/Lookup';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { showToast } from '../../helpers/ToastHelper';

export default class Consumer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      consumers: [],
      consumer: {
        AddedBy: getCurrentUser().id,
      },
      consumerModal: false,
      orderBy: "NameDown"
    }

    this.consumerModalToggle = this.consumerModalToggle.bind(this);
  }

  componentDidMount() {
    this.loadConsumer();
  }

  consumerModalToggle = () => {
    this.setState({ consumerModal: !this.state.consumerModal })
  }

  updateConsumer = () => {
    let valid = true;
    valid &= fieldRequired(this.state.consumer.name, 'consumer-error', "* required");

    if (valid) {
      this.consumerModalToggle();
      this.saveConsumer();
    }
  }

  sortBy(header) {
    if (this.state.orderBy.includes(header)) {
      if (this.state.orderBy.includes("Down")) {
        this.setState({ orderBy: header + "Up" })
      } else {
        this.setState({ orderBy: header + "Down" })
      }
    } else {
      this.setState({ orderBy: header + "Down" })
    }
  }

  renderSortArrow(header) {
    return this.state.orderBy === header + "Down" ? <i className='fa fa-long-arrow-down'></i> : this.state.orderBy === header + "Up" ? <i className='fa fa-long-arrow-up'></i> : <></>
  }

  render() {
    return <div>
      <div className='d-flex justify-content-between align-items-center'>
        <div>
          <h1>Complainant Types</h1>
        </div>
        <div>
          <Button color="primary" onClick={() => {
            this.setState({ consumer: {} })
            this.consumerModalToggle()
          }}>Add Complainant Type <i className='fa fa-plus-circle ms-2'></i></Button>
        </div>
      </div>
      <div>
        <table className="table table-striped">
          <thead className='bg-blue'>
            <tr>
              <td style={{ cursor: "pointer" }} onClick={() => this.sortBy("Name")}>{this.renderSortArrow("Name")} Name</td>
              <td className='text-end'>Action</td>
            </tr>
          </thead>
          <tbody>
            {this.state.consumers.length ? this.state.consumers.sort((a, b) => {
              switch (this.state.orderBy) {
                case "NameUp":
                  return b.name.localeCompare(a.name);
                default:
                  return a.name.localeCompare(b.name);
              }
            }).map((consumer, index) => {
              return (
                <tr key={index}>
                  <td>{consumer.name}</td>
                  <td >
                    <div className='d-flex justify-content-end'>
                      <div onClick={() => {
                        this.setState({ consumer })
                        this.consumerModalToggle();
                      }}><i className='fa fa-edit mx-2' title="Edit item"></i></div>
                      <div onClick={() => this.deleteConsumer(consumer.id)}><i className='fa fa-trash-alt text-danger' title="Delete item"></i></div>
                    </div>
                  </td>
                </tr>
              )
            }) : <tr><td colSpan='3' className='text-center'> No Complainant Type Found</td></tr>}
          </tbody>
        </table>
      </div>
      <Modal isOpen={this.state.consumerModal}>
        <ModalHeader>Edit Complainant Type</ModalHeader>
        <ModalBody>

          <label htmlFor="consumer" className="form-label">
            Complainant Type Name
            <div className='error' id='consumer-error'></div>
          </label>
          <input type="text" className="form-control" id="consumer" name="consumer" value={this.state.consumer.name} onChange={(e) => this.setState({ consumer: { ...this.state.consumer, name: e.target.value } })} />

        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.consumerModalToggle}>Cancel<i className="far fa-times-circle ms-2"></i></Button>
          <Button color="primary" onClick={this.updateConsumer}>Save<i className="far fa-check-circle ms-2"></i></Button>
        </ModalFooter>
      </Modal>
      <ThrobbleHelper />
    </div>
  }

  loadConsumer = async () => {
    try {
      ThrobbleHelper.ToggleThrobble(true, "Loading Complainant Types");
      const responses = await fetch('/api/complainanttypes', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      })
      if (responses.ok) {
        const data = await responses.json();
        this.setState({ consumers: data });
        ThrobbleHelper.ToggleThrobble(false, true);
      } else {
        if (responses.status === 401) {
          removeLoginDetails();
          window.location.href = "/";
        } else {
          ThrobbleHelper.ToggleThrobble(false, true);
        }
      }
    } catch (error) {
      console.error(error);
      ThrobbleHelper.ToggleThrobble(false, true);

    }
  }

  saveConsumer = async () => {
    try {
      ThrobbleHelper.ToggleThrobble(true, "Saving Complainant Type");
      const response = await fetch('/api/complainanttypes', {
        method: this.state.consumer.id ? "PUT" : 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + localStorage.getItem('token')
        },
        body: JSON.stringify(this.state.consumer)

      })
      if (response.ok) {
        this.loadConsumer();
        ThrobbleHelper.ToggleThrobble(false, true);
        showToast("success", "Save Complainant Type", "Complainant type was successfully saved!");
      } else {
        if (response.status === 401) {
          removeLoginDetails();
          window.location.href = "/";
        } else {
          ThrobbleHelper.ToggleThrobble(false, true);
          showToast("error", "Save Complainant Type", "There was an error saving complainant type");
        }
      }

    } catch (error) {
      console.error(error);
      ThrobbleHelper.ToggleThrobble(false, true);
      showToast("error", "Save Complainant Type", "There was an error saving complainant type");
    }
  }

  deleteConsumer = (id) => {
    const ConfirmProps = { DeleteId: id, Title: "Delete Complainant Type", Message: "Are you sure you want to delete this complainant type?" };
    confirmAlert({
      customUI: ({ onClose }) => <ConfirmHelper {...ConfirmProps} onClose={onClose} onConfirm={this.deleteConsumerData} />
    })
  }

  deleteConsumerData = async (id) => {
    try {
      ThrobbleHelper.ToggleThrobble(true, "Deleting Complainant Types");
      const response = await fetch('/api/complainanttypes/' + id, {
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + localStorage.getItem('token')
        },
      })
      if (response.ok) {
        this.loadConsumer();
        ThrobbleHelper.ToggleThrobble(false, true);
        showToast("success", "Delete Complainant Type", "Complainant type was successfully deleted!");
      } else {
        if (response.status === 401) {
          removeLoginDetails();
          window.location.href = "/";
        } else {
          ThrobbleHelper.ToggleThrobble(false, true);
          showToast("error", "Delete Complainant Type", "There was an error deleting complainant type!");
        }
      }

    } catch (error) {
      console.error(error);
      ThrobbleHelper.ToggleThrobble(false, true);
      showToast("error", "Delete Complainant Type", "There was an error deleting complainant type!");
    }
  }
}
