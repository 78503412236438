import Admin from "./components/Admin/Admin";
import Consumer from "./components/Admin/Consumer";
import Status from "./components/Admin/Status";
import Users from "./components/Admin/Users";
import CaseStatistics from "./components/CaseStatistics";
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
import { MyDms } from "./components/MyDms";
import { CaseFile } from "./components/CaseFile";
import NotePad from "./components/NotePad";
import Calendar from "./components/Calendar"
import { getCurrentUser } from "./helpers/GetCurrentUser";
import { urls } from "./helpers/Urls";
import Cases from "./components/Reports/Cases";
import Complainants from "./components/Reports/Complainants";
import Respondents from "./components/Reports/Respondents";
import Media from "./components/Reports/Media";
import Industry from "./components/Reports/Industry";
import Level from "./components/Reports/Levels";
import AdminLevel from "./components/Admin/Level";
import AdminOutcomes from "./components/Admin/Outcomes";
import Outcomes from "./components/Reports/Outcomes";
import CaseAnalysis from "./components/Reports/CaseAnalyis";
import Attachments from "./components/Reports/Attachments";
import Tasks from "./components/Admin/Tasks";
import MembershipStatuses from "./components/Admin/MembershipStatuses";
import CaseLoad from "./components/CaseLoad";
import ResetPassword from "./components/ResetPassword";
import Search from "./components/Search";
import Industries from "./components/Admin/Industry";
import Clauses from "./components/Admin/Clauses";

let userData = getCurrentUser();

let AppRoutes = [];
if (userData) {
  AppRoutes = [
    {
      index: true,
      element: <MyDms />
    },
    {
      path: urls.myDms,
      element: <MyDms />
    },
    {
      path: urls.viewMyCases,
      element: <MyDms />
    },
    {
      path: '/fetch-data',
      element: <FetchData />
    },
    {
      path: '/case-file/:id',
      element: <CaseFile />
    },
    {
      path: '/case-file',
      element: <CaseFile />
    },
    {
      path: urls.viewCaseStatistics,
      element: <CaseStatistics />
    },
    {
      path: urls.notePad,
      element: <NotePad />
    },
    {
      path: urls.calendar,
      element: <Calendar />
    },
    {
      path: urls.cases,
      element: <Cases />
    },
    {
      path: urls.caseLoad,
      element: <CaseLoad />
    },
    {
      path: urls.complainants,
      element: <Complainants />
    },
    {
      path: urls.respondents,
      element: <Respondents />
    },
    {
      path: urls.media,
      element: <Media />
    },
    {
      path: urls.industry,
      element: <Industry />
    },
    {
      path: urls.level,
      element: <Level />
    },
    {
      path: urls.outcomes,
      element: <Outcomes />
    },
    {
      path: urls.caseAnalysis,
      element: <CaseAnalysis />
    },
    {
      path: urls.attachments,
      element: <Attachments />
    },
    {
      path: urls.resetPassword,
      element: <ResetPassword />
    }, {
      path: urls.search,
      element: <Search />
    }
  ];

  if (userData.userType === 100) {
    const adminUrls = [{
      path: urls.users,
      element: <Users />
    },
    {
      path: urls.status,
      element: <Status />
    },
    {
      path: urls.adminLevel,
      element: <AdminLevel />
    },
    {
      path: urls.adminOutcomes,
      element: <AdminOutcomes />
    },
    {
      path: urls.medias,
      element: <Admin />
    },
    {
      path: urls.adminConsumer,
      element: <Consumer />
    },
    {
      path: urls.tasks,
      element: <Tasks />
    },
    {
      path: urls.industries,
      element: <Industries />
    },
    {
      path: urls.clauses,
      element: <Clauses />
    },
    {
      path: urls.membershipStatuses,
      element: <MembershipStatuses />
    }]
    AppRoutes.push(...adminUrls)
  }
} else {
  AppRoutes = [
    {
      index: true,
      element: <Home />
    },
    {
      path: urls.resetPassword,
      element: <ResetPassword />
    }
  ];
}




export default AppRoutes;
