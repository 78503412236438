import React, { useEffect, useState } from 'react';
import { Button, Card, CardBody, Input } from 'reactstrap';
import { checkColorBrightness } from '../helpers/global';
import { getAuthToken, removeLoginDetails } from '../helpers/Lookup';
import parse from 'html-react-parser';

export default function Search() {

    const [cases, setCases] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [ddStatuses, setddStatuses] = useState([]);
    const [levels, setLevels] = useState([]);
    const [ddLevels, setddLevels] = useState([]);
    const [outcomes, setOutcomes] = useState([]);
    const [ddOutcomes, setddOutcomes] = useState([]);
    const [results, setResults] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [IsSearch, setIsSearch] = useState(false);
    const [oneDayInMilliseconds, setOneDayInMilliseconds] = useState(24 * 60 * 60 * 1000);

    useEffect(() => {
        loadData();
    }, [])

    const loadData = async () => {
        // ThrobbleHelper.ToggleThrobble(true, "L")
        const bearer = "Bearer " + getAuthToken();

        const response = await fetch("/api/cases", {
            method: "GET",
            headers: {
                Authorization: bearer,
                "Content-Type": "application/json"
            }
        });

        if (response.ok) {
            const data = await response.json();
            setCases(data);
            await loadStatuses();
            await loadLevels();
            await loadOutcomes();
        } else {
            if (response.status === 401) {
                removeLoginDetails();
                window.location.href = "/";
            }
        }

    }

  const loadStatuses = async () => {
        try {
            const response = await fetch('/api/status', {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            });

            if (response.ok) {
                const data = await response.json();

                const ddStatuses = [{ value: 0, label: "- All My Cases -" }, ...data.map(item => {
                    return { value: item.id, label: item.name }
                })]
                setStatuses(data)
                setddStatuses(ddStatuses)
                //this.setState({ statuses: data, ddStatuses });
            }

        } catch (error) {
            console.error(error)
        }
    }

  const loadLevels = async () => {
        try {
            const response = await fetch('/api/levels', {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            });

            if (response.ok) {
                const data = await response.json();
                const ddLevels = [{ value: 0, label: "- All Levels -" }, ...data.map(item => {
                    return { value: item.id, label: item.name }
                })]
                setLevels(data);
                setddLevels(ddLevels);
                //this.setState({ levels: data, ddLevels });
            }

        } catch (error) {
            console.error(error)
        }
    }

    const loadOutcomes = async () => {
        try {
            const response = await fetch('/api/outcomes', {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            });

            if (response.ok) {
                const data = await response.json();

                const ddOutcomes = [{ value: 0, label: "- All Outcomes -" }, ...data.map(item => {
                    return { value: item.id, label: item.name }
                })]
                setOutcomes(data)
                setddOutcomes(ddOutcomes)
                //this.setState({ outcomes: data, ddOutcomes });
            }

        } catch (error) {
            console.error(error)
        }
    }

    const zeroPad = (num, places) => String(num).padStart(places, '0');

    const searchCases = (value) => {
        setKeyword(value);
        // let isValid = true;
        // isValid &= fieldRequired(value.length > 0, "errKeyword", "* Required");
        // if (isValid) {
        setIsSearch(true);
        let results = cases.filter(c => {
            return value && (zeroPad(c.caseNumber, 5).includes(value) || c.description.toLowerCase().includes(value.toLowerCase()))
        })
        setResults(results);
        // }
    }

    return (
        <div>
            <Card className='mb-3'>
                <CardBody>
                    <div className="row">
                        <div className="col-md-6">
                            <label>Search by keyword
                                <span id="errKeyword" className="error-message ms-2"></span>
                            </label>
                            <Input type='text' bsSize='sm' placeholder='Enter keyword' value={keyword} onChange={e => searchCases(e.target.value)} />
                        </div>
                        <div className="col-md-6 text-end align-self-end d-none">
                            <Button color='primary' size='sm' onClick={searchCases}>
                                <i className='fa fa-magnifying-glass me-2'></i>
                                Search
                            </Button>
                        </div>
                    </div>
                </CardBody>
            </Card>
            <Card>
                <CardBody>
                    <h3>Search Result</h3>
                    <div className="row">
                        {
                            results.length > 0 ? 
                                <div className='table-container' >
                                    <table className='table table-striped table-responsive table-hover table-sm ' style={{ border: "1px solid #201d39" }} >
                                        <thead className='bg-blue'>
                                            <tr style={{ padding: 0, margin: 0 }}>
                                                <th style={{ width: "10%" }} > File #</th>
                                                <th style={{ width: "25%" }} > File Name </th>
                                                {/*<th style={{ width: "12%" }} > Date Opened </th>*/}
                                                <th className="text-center" style={{ width: "10%" }} > Age (Days)</th>
                                                <th className="text-center" style={{ width: "8%" }}> Status</th>
                                                <th style={{ width: "12%" }} > Assigned Date</th>
                                                {/*<th className="text-center" style={{ width: "10%" }} > Level</th>*/}
                                                {/*<th className="text-center" > Outcome</th>*/}
                                                <th style={{ width: "10%" }} className="text-end" >Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {results.length ? results.map((item, index) => {
                                                const level = levels.find(level => level.id === parseInt(item.levelId));
                                                const status = statuses.find(status => status.id === item.statusId);
                                                //console.log(level?.color, level?.name)
                                                return <tr key={index}>
                                                    <td className="text-start">{zeroPad(item.caseNumber, 5)}</td>
                                                    <td>{parse(item.description.replace(/<p>/g, "").replace(/<\/p>/g, ""))}</td>
                                                    <td>{Math.floor((new Date() - new Date(item.dateOpened)) / oneDayInMilliseconds)}</td>
                                                    {/*<td className="text-center">{Math.abs(new Date().getDate() - new Date(item.dateOpened).getDate())}</td>*/}
                                                    <td className="text-center" style={{ background: status?.color, color: checkColorBrightness(status?.color) }}>{status?.name}</td>
                                                    <td>{new Date(item.dateAssigned).toDateString()}</td>
                                                    {/*<td className="text-center" style={{ background: level?.color, color: checkColorBrightness(level?.color) }}>{level?.name}</td>*/}
                                                    {/*<td className="text-center">{outcomes.find(outcome => outcome.id === parseInt(item.outcomeId))?.name}</td>*/}
                                                    <td className="text-end">
                                                        <i className='fa fa-edit me-2' title="Edit/View Case" onClick={() => document.location.href = "/case-file/" + item.id}></i>
                                                    </td>
                                                </tr>
                                            }) :
                                                <tr>
                                                    <td colSpan={9} className="text-center"><b>No Cases Yet</b></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <div className='col-12 text-center'>
                                    {keyword ?
                                        <p>There are no result for <b>{keyword}</b></p>
                                        :
                                        <p>Please use the "Search by keyword" field to search for case.</p>
                                    }
                                </div>
                        }
                    </div>
                </CardBody>
            </Card>
        </div>
    )
}
