/* eslint-disable default-case */
import moment from 'moment';
import React, { Component, forwardRef, useImperativeHandle } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import ConfirmHelper from '../../helpers/ConfirmHelper';
import { fieldRequired } from '../../helpers/fieldRequired';
import { getCurrentUser } from '../../helpers/GetCurrentUser';
import { removeLoginDetails } from '../../helpers/Lookup';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { showToast } from '../../helpers/ToastHelper';
//import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';

export class NotesTable extends Component {

    //static parentObj = null;
    constructor(props) {
        super(props);

        //NotesTable.parentObj = this;
        this.state = {
            NoteList: [], CaseList: [],
            Id: 0,
            CaseId: this.props.CaseID ? this.props.CaseID : 0,
            Title: "",
            NotesText: "",
            loading: true,
            orderBy: "TitleDown",
            editNotesModal: false,
            DateDue: "",
        }

        this.renderTable = this.renderTable.bind(this);
        this.renderFilterTable = this.renderFilterTable.bind(this);
        this.renderNonFilterTable = this.renderNonFilterTable.bind(this);
        this.GetCaseNumber = this.GetCaseNumber.bind(this);
        this.saveNotes = this.saveNotes.bind(this);
        this.SaveNotesData = this.SaveNotesData.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.deleteNoteData = this.deleteNoteData.bind(this);
        // this.toggleEditNotesModal = this.toggleEditNotesModal.bind(this);
    }

    componentDidMount() {
        this.loadNotes();
    }

    async LoadForUser() {
        const bearer = `Bearer ${localStorage.getItem("token")}`;
        const UserID = getCurrentUser().id;
        ThrobbleHelper.ToggleThrobble(true, "Loading Notes");
        try {
            const response = await fetch('api/notes/' + UserID, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": bearer
                }
            });
            const data = await response.json();
            this.setState({ NoteList: data });
            this.LoadCaseList()
        } catch (error) {
            ThrobbleHelper.ToggleThrobble(false, true);
            console.error(error);
        }
    }

    async LoadForCase() {
        const bearer = `Bearer ${localStorage.getItem("token")}`;
        const CaseID = this.props.CaseID;
        try {
            const response = await fetch('api/notes/GetAllForCase/' + CaseID, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": bearer
                }
            });
            const data = await response.json();
            this.setState({ NoteList: data });
            await this.LoadCaseList()
            ThrobbleHelper.ToggleThrobble(false, true);

        } catch (error) {
            console.error(error);
        }
    }

    async LoadCaseList() {
        const bearer = `Bearer ${localStorage.getItem("token")}`;
        try {
            const response = await fetch('api/cases/' + getCurrentUser().id, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": bearer
                }
            });
            if (response.ok) {
                const data = await response.json();
                this.setState({ CaseList: data, loading: false });
                ThrobbleHelper.ToggleThrobble(false, true);
            } else {
                if (response.status === 401) {
                    removeLoginDetails();
                    window.location.href = "/";
                } else
                    ThrobbleHelper.ToggleThrobble(false, true);
            }
        } catch (error) {
            console.error(error);
            this.setState({ loading: false });
            ThrobbleHelper.ToggleThrobble(false, true);
        }
    }

    toggleEditNotesModal = () => {
        this.clearForm();
        this.setState({ editNotesModal: !this.state.editNotesModal })
    }

    saveNotes() {
        let valid = true;
        valid &= fieldRequired(this.state.Title, "title-error", "* Required");
        valid &= fieldRequired(this.state.NotesText, "notes-error", "* Required");
        if (valid) {
            ThrobbleHelper.ToggleThrobble(true, "Saving Note");
            this.toggleEditNotesModal();
            this.SaveNotesData();
        }
    }

    editNotes(id) {
        if (id) {
            let note = this.state.NoteList.find(x => x.id === id);
            this.setState({ Id: note.id, Title: note.title, NotesText: note.notesText, editNotesModal: true, DateDue: note.dateDue ? new Date(note.dateDue) : "" })
        } else
            this.clearForm();
    }

    clearForm = () => {
        this.setState({
            Id: 0,
            CaseId: this.props.CaseID ? this.props.CaseID : 0,
            Title: "",
            NotesText: "",
            DateDue: null,
        });
    }

    sortBy(header) {
        if (this.state.orderBy.includes(header)) {
            if (this.state.orderBy.includes("Down")) {
                this.setState({ orderBy: header + "Up" })
            } else {
                this.setState({ orderBy: header + "Down" })
            }
        } else {
            this.setState({ orderBy: header + "Down" })
        }
    }

    renderSortArrow(header) {
        return this.state.orderBy === header + "Down" ? <i className='fa fa-long-arrow-down'></i> : this.state.orderBy === header + "Up" ? <i className='fa fa-long-arrow-up'></i> : <></>
    }

    GetCaseNumber(caseId) {
        const caseItem = this.state.CaseList.find(c => c.id === caseId);
        if (caseItem != null)
            return this.zeroPad(caseItem.caseNumber, 5);
        else
            return "";
    }

    renderFilterTable() {

        return (
            <table className='table table-striped table-responsive table-hover table-sm table-bordered' >
                <thead className='bg-blue'>
                    <tr style={{ padding: 0, margin: 0 }}>
                        {/* <th style={{ width: "5%" }} onClick={() => this.sortBy("No.")} >{this.renderSortArrow("No.")} No.</th> */}
                        <th colSpan={2} onClick={() => this.sortBy("Title")} >{this.renderSortArrow("Title")} Title</th>
                        <th colSpan={4} onClick={() => this.sortBy("Notes")} >{this.renderSortArrow("Notes")} Notes</th>
                        <th colSpan={2} onClick={() => this.sortBy("DateAdded")} >{this.renderSortArrow("DateAdded")} Date Added</th>
                        <th colSpan={2} onClick={() => this.sortBy("DateDue")} >{this.renderSortArrow("DateDue")} Date Due</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.NoteList.length ? this.state.NoteList.map((item, index) => ({ ...item, index })).sort((a, b) => {
                            switch (this.state.orderBy) {
                                case "TitleDown":
                                    return a.title.localeCompare(b.title);
                                case "TitleUp":
                                    return b.title.localeCompare(a.title);
                                case "NotesDown":
                                    return a.notesText.localeCompare(b.notesText);
                                case "NotesUp":
                                    return b.notesText.localeCompare(a.notesText);
                                case "DateAddedDown":
                                    return new Date(a.dateAdded) - new Date(b.dateAdded);
                                case "DateAddedUp":
                                    return new Date(b.dateAdded) - new Date(a.dateAdded);
                                case "DateDueDown":
                                    return new Date(a.dateDue) - new Date(b.dateDue);
                                case "DateDueUp":
                                    return new Date(b.dateDue) - new Date(a.dateDue);
                            }
                        }).map((item) => <tr key={item.index}>
                            <td colSpan={2}>{item.title}</td>
                            <td colSpan={4}>{item.notesText}</td>
                            <td colSpan={2}>{moment(item.dateAdded).format("yyyy-MM-DD")}</td>
                            <td colSpan={2}>{item.dateDue ? moment(item.dateDue).format("yyyy-MM-DD") : "N/A"}</td>
                            <td className='text-end'>
                                <i className='fa fa-edit me-2' style={{ cursor: "pointer" }} onClick={() => { this.editNotes(item.id) }}></i>
                                <i className='fa fa-trash-alt  text-danger' style={{ cursor: "pointer" }} onClick={() => this.deleteNotes(item.id)}></i>
                            </td>
                        </tr>) :
                            <tr >
                                <td className="text-center" colSpan={10} >No Records</td>
                            </tr>
                    }
                </tbody>
            </table>
        )
    }

    renderNonFilterTable() {
        return (
            <table className='table table-striped table-responsive table-hover table-sm table-bordered' >
                <thead className='bg-blue'>
                    <tr style={{ padding: 0, margin: 0 }}>
                        <th colSpan={2}> Title</th>
                        <th colSpan={4}>Notes</th>
                        <th colSpan={2}>Date Added</th>
                        <th colSpan={2}>Date Due</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        this.state.NoteList.length ? this.state.NoteList.sort((a, b) => new Date(b.dateAdded) - new Date(a.dateAdded)).map((item, index) =>
                            <tr key={index}>
                                <td colSpan={2}>{item.title}</td>
                                <td colSpan={4}>{item.notesText}</td>
                                <td colSpan={2}>{moment(item.dateAdded).format("yyyy-MM-DD")}</td>
                                <td colSpan={2}>{item.dateDue ? moment(item.dateDue).format("yyyy-MM-DD") : "N/A"}</td>
                                <td className='text-end'>
                                    <i className='fa fa-edit me-2' style={{ cursor: "pointer" }} onClick={() => { this.editNotes(item.id) }}></i>
                                    <i className='fa fa-trash-alt  text-danger' style={{ cursor: "pointer" }} onClick={() => this.deleteNotes(item.id)}></i>
                                </td>
                            </tr>) :
                            <tr >
                                <td className="text-center" colSpan={10} >No Records</td>
                            </tr>
                    }
                </tbody>
            </table>
        )
    }

    renderTable() {
        const contentTable = this.props.ShowFilter ? this.renderFilterTable() : this.renderNonFilterTable();

        return contentTable;
    }

    zeroPad = (num, places) => String(num).padStart(places, '0');

    render() {
        const content = this.renderTable();
        return (
            <>{
                content
            }

                <Modal isOpen={this.state.editNotesModal}>
                    <ModalHeader>Edit Notes</ModalHeader>
                    <ModalBody>
                        <Row className='my-3'>
                            <Col md={12} className='mb-3'>
                                <label className='d-flex'>Title <div className='error' id="title-error"></div></label>
                                <input className='form-control form-control-sm' value={this.state.Title} onChange={(e) => this.setState({ Title: e.target.value })} />
                            </Col>
                            <Col md={12} className='mb-3'>
                                <label className='d-flex'>Notes <div className='error' id="notes-error"></div></label>
                                <textarea className='form-control form-control-sm' rows={10} value={this.state.NotesText} onChange={(e) => this.setState({ NotesText: e.target.value })} />
                            </Col>
                            <Col md={12} className='mb-3'>
                                <label className='d-flex'>Date Due<div className='error' id="dateDue-error"></div></label>
                                <input
                                    className="form-control form-control-sm w-100"
                                    type="date"
                                    id="datePicker"
                                    value={this.state.DateDue ? moment(this.state.DateDue).format("yyyy-MM-DD") : ""}
                                    onChange={e => this.setState({ DateDue: e.target.value })}
                                />
                                {/*<DatePickerComponent id='tbDateDue' name='tbDateDue' placeholder='Date Due' floatLabelType='False' showClearButton={true} format='yyyy-MM-dd' value={this.state.DateDue} onChange={e => this.setState({ DateDue: e.target.value })} />*/}
                                {/*<textarea className='form-control  form-control-sm' rows={10} value={this.state.NotesText} onChange={(e) => this.setState({ NotesText: e.target.value })} />*/}
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button size='sm' onClick={this.toggleEditNotesModal}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
                        <Button size='sm' color='primary' onClick={this.saveNotes}>Save<i className="far fa-check-circle ms-2"></i></Button>
                    </ModalFooter>
                </Modal>
                <ThrobbleHelper />
            </>
        );
    }

    async deleteNotes(id) {
        const ConfirmProps = { DeleteId: id, Title: "Delete Note", Message: "Are you sure you want to delete this note?" };
        confirmAlert({
            customUI: ({ onClose }) => <ConfirmHelper {...ConfirmProps} onClose={onClose} onConfirm={this.deleteNoteData} />
        })
    }

    async loadNotes() {
        if (this.props.ShowFilter)
            await this.LoadForUser();
        else
            await this.LoadForCase();

    }

    async SaveNotesData() {
        try {
            let data = {
                CaseId: this.state.CaseId,
                Title: this.state.Title,
                NotesText: this.state.NotesText,
                Id: this.state.Id,
                AddedBy: getCurrentUser().id,
                DateAdded: this.state.dateAdded || new Date(),
                DateDue: this.state.DateDue ? this.state.DateDue : null,
                ModifiedBy: getCurrentUser().id,
                DateModified: new Date()
            }
            //console.log(data)
            let response = await fetch('api/notes', {
                method: this.state.Id ? "PUT" : "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            })

            if (response.ok) {
                this.loadNotes();
                this.clearForm();
                ThrobbleHelper.ToggleThrobble(false, true);
                showToast("success", "Save Note", "Note was successfully saved");
            } else {
                if (response.status === 401) {
                    removeLoginDetails();
                    window.location.href = "/";
                } else {
                    ThrobbleHelper.ToggleThrobble(false, true);
                    showToast("error", "Save Note", "There was an error saving note");
                }
            }
        } catch (error) {
            ThrobbleHelper.ToggleThrobble(false, true);
            showToast("error", "Save Note", "There was an error saving note");
            console.error(error)
        }
    }

    async deleteNoteData(id) {
        try {
            ThrobbleHelper.ToggleThrobble(true, "Deleting note")
            const response = await fetch('api/notes/' + id, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("token")
                }
            });
            if (response.ok) {
                ThrobbleHelper.ToggleThrobble(false, true);
                this.loadNotes();
                showToast("success", "Delete Note", "Note was successfully deleted");
            }
            else {
                if (response.status === 401) {
                    removeLoginDetails();
                    window.location.href = "/";
                } else {
                    ThrobbleHelper.ToggleThrobble(false, true);
                    showToast("error", "Delete Note", "There was an error deleting note");
                }
            }

        } catch (error) {
            console.error(error);
            ThrobbleHelper.ToggleThrobble(false, true);
            showToast("error", "Delete Note", "There was an error deleting note");
        }
    }
}

export default forwardRef((props, ref) => {
    const notesTableRef = React.createRef();

    useImperativeHandle(ref, () => ({
        toggleEditNotesModal: notesTableRef.current.toggleEditNotesModal,
    }));

    return <NotesTable {...props} ref={notesTableRef} />;
});