import React, { Component } from 'react';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Button, Col, Row } from 'reactstrap';
import NotesTable from './Case Editor Components/NotesTable';

export default class NotePad extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: [],
            editNotesModal: false,
            title: "",
            notesText: "",
            id: 0,
            orderBy: "No.Down",
            dateDue: "",
        }
    }

    render() {
        return (
            <div>
                <Row className='mb-3'>
                    <Col><h1>My Notes</h1></Col>
                    <Col className='text-end align-self-end' >
                        <Button color='primary' onClick={(e) => { NotesTable.toggleEditNotesModal() }}>Add Notes<i className='fa fa-circle-plus ms-2'></i></Button>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <NotesTable ShowFilter={true} />
                    </Col>
                </Row>
            </div>
        )
    }

    // async deleteNotes(id) {

    //     confirmAlert({
    //         customUI: ({ onClose }) => {
    //             return (
    //                 <div className="custom-ui">
    //                     <Card>
    //                         <CardBody>
    //                             <h3 className="">Delete Note</h3>
    //                             <p>Are you sure you want to delete this note?</p>
    //                             <hr />
    //                             <button className='btn btn-success me-2' onClick={() => {
    //                                 //   this.deleteFile(id);
    //                                 onClose();
    //                             }}>Yes</button>
    //                             <button className='btn btn-danger' onClick={onClose}>No</button>
    //                         </CardBody>
    //                     </Card>
    //                 </div>
    //             )
    //         }
    //     })

    //     try {

    //     } catch (e) {
    //         console.error(e);
    //     }
    // }

    // async loadNotes() {
    //     try {
    //         const response = await fetch('api/notes/' + getCurrentUser().id, {
    //             method: "GET",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 "Authorization": "Bearer " + localStorage.getItem("token")
    //             }
    //         });
    //         const data = await response.json();
    //         this.setState({ notes: data });
    //     } catch (error) {
    //         console.error(error)
    //     }
    // }

    // async SaveNotes() {
    //     try {
    //         let data = {
    //             title: this.state.title,
    //             notesText: this.state.notesText,
    //             id: this.state.id,
    //             addedBy: getCurrentUser().id,
    //             dateAdded: this.state.dateAdded || new Date(),
    //             modifiedBy: getCurrentUser().id,

    //         }
    //         let response = await fetch('api/notes', {
    //             method: this.state.id ? "PUT" : "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 "Authorization": "Bearer " + localStorage.getItem("token")
    //             },
    //             body: JSON.stringify(data)
    //         })

    //         if (response.ok) {
    //             this.loadNotes();
    //         }
    //     } catch (error) {
    //         console.error(error)
    //     }
    // }
}
