import React, { Component } from 'react'
import { getCurrentUser } from '../../helpers/GetCurrentUser';
import { Button, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import Select from 'react-select';
import { fieldRequired } from '../../helpers/fieldRequired';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';

export default class Clauses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Id: 0,
            clauses: [],
            clause: [],
            clauseModal: false,
            orderBy: 'NameDown',
            clauseName: "",
            clauseGroup: "",
            clauseModal: false,
            groupModal: false,
            groupArray: [],
            previousGroup: "",
        };
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleGroupModal = this.toggleGroupModal.bind(this);
        this.saveGroup = this.saveGroup.bind(this);
        this.saveClause = this.saveClause.bind(this);
        this.addClause = this.addClause.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    addClause() {
        this.toggleModal()
        this.setState({ clauseName: "", clauseGroup: "", Id: "" })
    }

    toggleModal() {
        this.setState({ clauseModal: !this.state.clauseModal })
    }

    toggleGroupModal() {
        this.setState({ groupModal: !this.state.groupModal })
    }

    saveGroup() {
        let valid = true;
        valid &= fieldRequired(this.state.clauseGroup, 'main-group-error', "* required");
        if (valid) {
            this.setState({ groupArray: [...this.state.groupArray, this.state.clauseGroup], groupModal: false, clauseGroup: "" })
            if (this.state.previousGroup) {
                this.updateGroup()
            } else {
                this.newGroup()
            }
        }
    }

    saveClause() {
        let valid = true;
        valid &= fieldRequired(this.state.clauseName, 'clause-error', "* required");
        valid &= fieldRequired(this.state.clauseGroup, 'group-error', "* required");
        if (valid) {
            this.toggleModal();
            this.SaveClause();
        }
    }

    render() {
        return (
            <div>
                <div className='d-flex justify-content-between align-items-center my-3'>
                    <div>
                        <h1>Clauses</h1>
                    </div>
                    <div>
                        <Button color='primary' className='mx-2' onClick={() => {
                            this.setState({ clauseName: "", clauseGroup: "", Id: "", previousGroup: "" })
                            this.toggleGroupModal()
                        }}>Add Group <i className='fa fa-plus-circle' ></i></Button>
                        <Button color='primary' onClick={this.addClause}>Add Clause <i className='fa fa-plus-circle' ></i></Button>
                    </div>
                </div >
                <div style={{ maxHeight: 700, overflowY: "scroll" }}>
                    <table className="table table-striped">
                        <thead className='bg-blue'>
                            <tr>
                                <td>Clause</td>
                                <td className='text-end'>Action</td>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.clauses.length ?
                                this.state.groupArray.map((item, index) => {
                                    let groupSection = [<tr key={item}><td className='text-center' ><h4>{item}</h4></td> <td className='text-end'>
                                        <div className='d-flex justify-content-end'>
                                            <Button color='primary' className='mx-2' onClick={() => {

                                                this.setState({ clauseGroup: item, previousGroup: item })
                                                this.toggleGroupModal()
                                            }}>Edit</Button>
                                        </div>
                                    </td></tr>]
                                    this.state.clauses.filter(clause => clause.groupName === item).forEach((clause, index) => {
                                        clause.name && groupSection.push(<tr key={index}>
                                            <td>{clause.name}</td>
                                            <td className='text-end'>
                                                <div className='d-flex justify-content-end'>
                                                    <Button color='primary' className='mx-2' onClick={() => {
                                                        this.setState({ clauseName: clause.name, clauseGroup: { label: clause.groupName, value: clause.groupName }, Id: clause.id })
                                                        this.toggleModal()
                                                    }}>Edit</Button>
                                                    <Button color='danger' onClick={() => {
                                                        this.deleteClause(clause.id)
                                                    }}>Delete</Button>
                                                </div>
                                            </td>
                                        </tr>)
                                    })
                                    return groupSection
                                })
                                : <tr>
                                    <td colSpan={2} className='text-center'>No Clauses Found</td>
                                </tr>}
                        </tbody>
                    </table>
                </div>
                <Modal isOpen={this.state.clauseModal}>
                    <ModalHeader>
                        Add Clause
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <label>Clause Name</label>
                                <input className='form-control form-control-sm' value={this.state.clauseName} onChange={(e) => this.setState({ clauseName: e.target.value })} />
                                <div id="clause-error" className='error-message'></div>
                            </Col>
                            <Col>
                                <label>Clause Group</label>
                                <Select className='select2-sm' options={this.state.groupArray.map(item => ({ label: item, value: item }))} onChange={(e) => this.setState({ clauseGroup: e })} value={this.state.clauseGroup} />
                                <div id="group-error" className='error-message'></div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.toggleModal}>Close</Button>
                        <Button color="success" onClick={this.saveClause}>Add</Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.groupModal}>
                    <ModalHeader>
                        Add Group
                    </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <label>Clause Group</label>
                                <input className='form-control form-control-sm' value={this.state.clauseGroup} onChange={(e) => this.setState({ clauseGroup: e.target.value })} />
                                {console.log(this.state.clauseGroup)}
                                <div id="main-group-error" className='error-message'></div>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.toggleGroupModal}>Close</Button>
                        <Button color="success" onClick={this.saveGroup}>Add</Button>
                    </ModalFooter>
                </Modal>
                <ThrobbleHelper />
            </div>
        )
    }

    async loadData() {
        try {
            ThrobbleHelper.ToggleThrobble(true, "Loading Clause");
            const response = await fetch('api/clauses', {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                }
            });
            if (response.ok) {
                const data = await response.json();
                let groupArray = data.reduce((arr, curr) => {
                    if (arr.indexOf(curr.groupName) === -1)
                        arr.push(curr.groupName)
                    return arr
                }, [])
                this.setState({ clauses: data, groupArray, previousGroup: "" });
            } else {
                if (response.status === 401) {
                    localStorage.removeItem("user");
                    localStorage.removeItem("token");
                    window.location.href = "/";
                }
            }

            ThrobbleHelper.ToggleThrobble(false);
        } catch (error) {
            console.error(error)
        }
    }

    async SaveClause() {
        ThrobbleHelper.ToggleThrobble(true, "Loading Clause");
        try {
            let Id = this.state.Id
            const response = await fetch('api/clauses', {
                method: Id ? "PUT" : "POST",
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json"

                },
                body: JSON.stringify({
                    Id: this.state.Id || 0,
                    Name: this.state.clauseName,
                    GroupName: this.state.clauseGroup?.value,
                    AddedBy: getCurrentUser().id,
                    DateAdded: new Date(),
                    ModifiedBy: getCurrentUser().id,
                    DateModified: new Date(),
                    Status: 1
                })
            })

            if (response.ok) {
                this.loadData();
            } else {
                if (response.status === 401) {
                    localStorage.removeItem("user");
                    localStorage.removeItem("token");
                    window.location.href = "/";
                }
            }
        } catch (error) {
            console.error(error)
        }
    }

    async deleteClause(id) {
        try {
            const response = await fetch("api/clauses/" + id, {
                method: "DELETE",
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json"
                }
            })

            if (response.ok) {
                this.loadData()
            }
        } catch (error) {
            console.error(error)
        }
    }

    async newGroup() {
        try {
            const response = await fetch("/api/clauses", {
                method: "POST",
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    Id: 0,
                    Name: this.state.clauseName,
                    GroupName: this.state.clauseGroup,
                    AddedBy: getCurrentUser().id,
                    DateAdded: new Date(),
                    ModifiedBy: getCurrentUser().id,
                    DateModified: new Date(),
                    Status: 1
                })
            })
            if (response.ok) {
                this.loadData()
            } else {
                if (response.status === 401) {
                    // localStorage.removeItem("user");
                    // localStorage.removeItem("token");
                    // window.location.href = "/";
                }
            }
        } catch (error) {
            console.error()
        }
    }

    async updateGroup() {
        try {
            const response = await fetch("/api/clausegroup", {
                method: "PUT",
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    PreviousGroup: this.state.previousGroup,
                    NewGroup: this.state.clauseGroup,
                })
            })
            if (response.ok) {
                this.loadData()
            } else {
                if (response.status === 401) {
                    // localStorage.removeItem("user");
                    // localStorage.removeItem("token");
                    // window.location.href = "/";
                }
            }
        } catch (error) {
            console.error()
        }
    }
}
