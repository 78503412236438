import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { fieldRequired } from '../helpers/fieldRequired';
import ThrobbleHelper from '../helpers/ThrobbleHelper';

export default function ResetPassword() {

    let splitPath = window.location.pathname.split('/');
    if (splitPath.length < 3 || !splitPath[2])
        window.location.href = "/";

    const Code = splitPath[2];
    const [Password, setPassword] = useState("");
    const [ConfirmPassword, setPasswordConfirm] = useState("");
    const [IsPasswordValid, setIsPasswordValid] = useState(false);
    const [showResultModal, setShowResultModal] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [ResultMessage, setResultMessage] = useState("");

    const toggle = () => {
        setShowResultModal(!showResultModal);
    }

    const OnConfirmChange = (evt) => {
        let isMatching = true;
        setPasswordConfirm(evt.target.value)
        if (Password.length > 0) {
            isMatching = Password === evt.target.value;
            setIsPasswordValid(isMatching);
        }
        fieldRequired(isMatching, "errConfirmPassword", "Passwords don't match!");
    }

    const SubmitPassword = async (evt) => {
        evt.stopPropagation();
        let isValid = true;
        isValid &= fieldRequired(Password, "errPassword", "* Required");
        isValid &= fieldRequired(IsPasswordValid, "errConfirmPassword", "Passwords don't match!");

        if (isValid) {
            ThrobbleHelper.ToggleThrobble(true, "Changing Password");
            try {
                const data = {
                    Code, Password
                }

                const response = await fetch("api/login/ResetPassword", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify(data)
                });

                if (response.ok) {
                    ThrobbleHelper.ToggleThrobble(false);
                    setIsSuccess(true);
                    setResultMessage("Your password was successfully changed.");
                    toggle();
                    // setTimeout(() => {
                    //     window.location.href = "/";
                    // }, 2500);
                } else {
                    if (response.status === 404) {
                        ThrobbleHelper.ToggleThrobble(false);
                        setIsSuccess(false);
                        setResultMessage("Your password reset code is invalid. Please click the \"Reset Password\" button in the email message.");
                        toggle();

                    } else {
                        ThrobbleHelper.ToggleThrobble(false);
                        setIsSuccess(false);
                        setResultMessage("There was an error changing your password. Please try again.");
                        toggle();

                    }
                }
            } catch (e) {
                console.error(e);
                ThrobbleHelper.ToggleThrobble(false);
                setIsSuccess(false);
                setResultMessage("There was an error changing your password. Please try again.");
            }
        }
    }

    return (
        <>
            <div className='login'>
                <div className='card login-card text-center d-flex justify-content-between align-items-between'>
                    <div >
                        <img alt='ARBLogo' src="/img/logos/dms_logo.svg" height="120px" />
                    </div>
                    <div>
                        <h1 className='text-blue'>RESET PASSWORD</h1>
                        <div className='row my-3'>
                            <div className='col-md-5 text-end align-self-center'>
                                <h3>PASSWORD</h3>
                            </div>
                            <div className='col-md-7 text-start'>
                                <input id='password' type='password' className='form-control w-75 ' value={Password} onChange={(e) => { setPassword(e.target.value); fieldRequired(Password, "errPassword", "* Required"); }} />
                                <div id="errPassword" className="error"></div>
                            </div>
                        </div>
                        <div className='row my-3'>
                            <div className='col-md-5 text-end  align-self-center'>
                                <h3>CONFIRM PASSWORD</h3>
                            </div>
                            <div className='col-md-7 text-start'>
                                <input id='confirmPassword' type='password' className='form-control w-75' value={ConfirmPassword} onChange={OnConfirmChange} />
                                <div id="errConfirmPassword" className="error"></div>
                            </div>
                        </div>

                        <div className="mb-4">
                            <button className='btn btn-blue text-light' onClick={SubmitPassword} type='submit'>Change Password</button>
                        </div>
                    </div>
                </div>
            </div>

            <Modal isOpen={showResultModal}>
                <ModalHeader toggle={toggle} close={<button className='btn-close' onClick={toggle}></button>}>
                    RESET PASSWORD
                </ModalHeader>
                <ModalBody>
                    <div className='text-center'>
                        {isSuccess ? <i className='far fa-check-circle fa-3x text-success mb-3'></i> : <i className='far fa-times-circle fa-4x text-danger mb-3'></i>}
                        <br />{ResultMessage}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button hidden={isSuccess} onClick={toggle} color='secondary'>Close</Button>
                    <Button hidden={!isSuccess} color='primary' onClick={() => window.location.href = "/"}>Go to Login</Button>
                </ModalFooter>
            </Modal>

            <ThrobbleHelper />
        </>
    )
}
