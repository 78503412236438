import { createContext, useState } from "react";

export const myContext = createContext();

export function Provider(props) {
    const [caseId, setCaseId] = useState(0);
    let values = {
        caseId,
        setCaseId
    }

    return (
        <myContext.Provider value={values} >
            {props.children}
        </myContext.Provider>
    )
}