import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { fieldRequired } from '../../helpers/fieldRequired';
import { getCurrentUser } from '../../helpers/GetCurrentUser';
import { removeLoginDetails } from '../../helpers/Lookup';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { showToast } from '../../helpers/ToastHelper';

export default class Industries extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mediaModal: false,
            colorOpen: false,
            industries: [],
            industry: {
                AddedBy: getCurrentUser().id,
            },
            currentPage: window.location.pathname,
            orderBy: "mediaDown"
        }
    }

    componentDidMount() {
        this.loadMedia();
    }

    mediaModalToggle = () => {
        this.setState({ mediaModal: !this.state.mediaModal })
    }

    updateMedia = () => {
        let valid = true;
        valid &= fieldRequired(this.state.industry.name, 'industry-error', "* required");

        if (valid) {
            this.mediaModalToggle();
            this.saveMedia();
        }
    }

    handleColorChange = (color, type) => {
        this.setState({ [type]: { ...this.state[type], color: color.hex } })
    }

    sortBy(header) {
        if (this.state.orderBy.includes(header)) {
            if (this.state.orderBy.includes("Down")) {
                this.setState({ orderBy: header + "Up" })
            } else {
                this.setState({ orderBy: header + "Down" })
            }
        } else {
            this.setState({ orderBy: header + "Down" })
        }
    }

    renderSortArrow(header) {
        return this.state.orderBy === header + "Down" ? <i className='fa fa-long-arrow-down'></i> : this.state.orderBy === header + "Up" ? <i className='fa fa-long-arrow-up'></i> : <></>
    }


    industries() {
        return <div>
            <div className='d-flex justify-content-between align-items-center'>
                <div>
                    <h1>Industry</h1>
                </div>
                <div>
                    <Button color="primary" onClick={() => {
                        this.setState({ industry: { addedBy: getCurrentUser().id } })
                        this.mediaModalToggle()
                    }}>Add Industry <i className='fa fa-plus-circle ms-2'></i></Button>
                </div>
            </div>
            <div>
                <table className="table table-striped">
                    <thead className='bg-blue'>
                        <tr>
                            <td style={{ cursor: "pointer" }} onClick={() => this.sortBy("industry")}>{this.renderSortArrow("industry")} Industry</td>
                            <td className='text-end'>Action</td>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.industries.length ? this.state.industries.sort((a, b) => {
                            switch (this.state.orderBy) {
                                case "mediaDown":
                                    return a.name.localeCompare(b.name)
                                case "mediaUp":
                                    return b.name.localeCompare(a.name)
                                default:
                                    return a.name.localeCompare(b.name)
                            }
                        }).map((industry, index) => {
                            return (
                                <tr key={index}>
                                    <td>{industry.name}</td>
                                    <td >
                                        <div className='d-flex justify-content-end'>
                                            <div onClick={() => {
                                                this.setState({ industry })
                                                this.mediaModalToggle();
                                            }}><i className='fa fa-edit mx-2' title="Edit item"></i></div>
                                            <div onClick={() => this.deleteMedia(industry.id)}><i className='fa fa-trash-alt text-danger' title="Delete item"></i></div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        }) : <tr><td colSpan='3' className='text-center'>No Industry Found</td></tr>}
                    </tbody>
                </table>
            </div>
            <Modal isOpen={this.state.mediaModal}>
                <ModalHeader>Edit Industry</ModalHeader>
                <ModalBody>

                    <label htmlFor="industry" className="form-label">
                        Industry
                        <div className='error' id='industry-error'></div>
                    </label>
                    <input type="text" className="form-control" id="industry" name="industry" value={this.state.industry.name} onChange={(e) => this.setState({ industry: { ...this.state.industry, name: e.target.value } })} />

                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.mediaModalToggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
                    <Button color="primary" onClick={this.updateMedia}>Save<i className="far fa-check-circle ms-2"></i></Button>
                </ModalFooter>
            </Modal>
            <ThrobbleHelper />
        </div>
    }

    render() {
        return this.industries();
    }

    loadMedia = async () => {
        try {
            ThrobbleHelper.ToggleThrobble(true, "Loading Industry");
            const responses = await fetch('/api/industry', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem('token')
                }
            })
            if (responses.ok) {
                const data = await responses.json();

                //console.log(data);
                this.setState({ industries: data });
                ThrobbleHelper.ToggleThrobble(false);
            } else {
                if (responses.status === 401) {
                    removeLoginDetails();
                    window.location.href = "/";
                } else {
                    ThrobbleHelper.ToggleThrobble(false);
                }
            }
        } catch (error) {
            console.error(error);
            ThrobbleHelper.ToggleThrobble(false);
        }
    }



    saveMedia = async () => {
        try {
            ThrobbleHelper.ToggleThrobble(true, "Saving Industry");
            const data = { ...this.state.industry, modifiedBy: getCurrentUser().id };
            const response = await fetch('/api/industry', {
                method: this.state.industry.id ? "PUT" : 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem('token')
                },
                body: JSON.stringify(data)
            })
            if (response.ok) {
                this.loadMedia();
                showToast("success", "Save Industry", "Industry was successfully saved");
            } else {
                if (response.status === 401) {
                    removeLoginDetails();
                    window.location.href = "/";
                } else {
                    ThrobbleHelper.ToggleThrobble(false);
                    showToast("error", "Save Industry", "There was an error saving industry");
                }
            }

        } catch (error) {
            console.error(error);
            ThrobbleHelper.ToggleThrobble(false);
            showToast("error", "Save Industry", "There was an error saving industry");
        }
    }

    deleteMedia = (id) => [
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="custom-ui">
                        <Card>
                            <CardBody>
                                <h3 className="">Delete Industry</h3>
                                <p>Are you sure you want to delete this industry?</p>
                                <hr />
                                <button className='btn btn-success me-2' onClick={() => {
                                    this.deleteMediaData(id);
                                    onClose();
                                }}>Yes</button>
                                <button className='btn btn-danger' onClick={onClose}>No</button>
                            </CardBody>
                        </Card>
                    </div>
                )
            }
        })
    ]

    deleteMediaData = async (id) => {
        try {
            ThrobbleHelper.ToggleThrobble(true, "Deleting Industry");
            const response = await fetch('/api/industry/' + id, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem('token')
                },
            })
            if (response.ok) {
                this.loadMedia();
                showToast("success", "Delete Industry", "Industry was successfully deleted!");
            } else {
                if (response.status === 401) {
                    removeLoginDetails();
                    window.location.href = "/";
                } else {
                    ThrobbleHelper.ToggleThrobble(false);
                    showToast("error", "Delete Industry", "There was an error deleting industry");
                }
            }

        } catch (error) {
            console.error(error);
            ThrobbleHelper.ToggleThrobble(false);
            showToast("error", "Delete Industry", "There was an error deleting industry");
        }
    }
}
