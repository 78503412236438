import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import { getCurrentUser } from '../helpers/GetCurrentUser';
import logo from '../img/dms_logo.svg'
export class Layout extends Component {
  static displayName = Layout.name;

  render() {
    let userData = getCurrentUser();
    let entryDate = new Date(userData?.loginDate).toString().replace(" GMT+0200 (South Africa Standard Time)", "").slice(0, -3)
    return (
      <div>
        {userData && <div>
          <div className="site-title">ADVERTISING<br /><span>REGULATORY</span> BOARD</div>
          <div className='text-center'>
            
            <img src={logo} alt="logo" height="130" />
            <div className="text-muted small">{`${userData.firstName} ${userData.lastName}`}</div>
            <div className="text-muted small">{`Last Login - ${entryDate}`}</div>
          </div>
          <NavMenu />
        </div>
        }
        <Container className="pb-4">
          {this.props.children}
        </Container>
      </div>
    );
  }
}
