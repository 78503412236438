/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { fieldRequired } from '../helpers/fieldRequired';
import { setCurrentUser } from '../helpers/GetCurrentUser';
import ThrobbleHelper from '../helpers/ThrobbleHelper';
import { showToast } from '../helpers/ToastHelper';
import logo from "../img/arb_logo_on_navy.png";
export class Home extends Component {
  static displayName = Home.name;
  constructor(props) {
    super(props);
    this.state = {
      forgotUsername: "",
      forgotPassword: false,
      password: "",
      username: "",
      usernameRequired: false,
      passwordRequired: false,
      errorMessage: "",
      errorModal: false
    }
    this.login = this.login.bind(this);
    this.fieldRequired = this.fieldRequired.bind(this);
  }

  fieldRequired = (field) => {
    this.setState({ [field]: true })
  }

  render() {
    return (
      <div className='login'>
        <div className='card login-card d-flex justify-content-between align-items-between'>
          <div className='text-center '>
            <img alt='ARBLogo' src="/img/logos/dms_logo.svg" height="150px" />
          </div>
          <div className='col-md-8 mx-auto'>
            <h1 className='text-blue text-center'>LOGIN</h1>
            <div className='row my-3'>
              <div className='col-md-5 text-md-end text-start align-self-center'>
                <h3>USERNAME</h3>
              </div>
              <div className='col-md-7'>
                <input id='username' name='username' type='text' className='form-control ' value={this.state.username} onChange={(e) => this.setState({ username: e.target.value, usernameRequired: false })} style={{ border: this.state.usernameRequired && "2px solid red" }} />
                <div id="errUsername" className="error"></div>
              </div>
            </div>
            <div className='row mt-3'>
              <div className='col-md-5 text-md-end text-start align-self-center'>
                <h3>PASSWORD</h3>
              </div>
              <div className='col-md-7'>
                <input id='password' name='password' type='password' className='form-control' value={this.state.password} onChange={(e) => this.setState({ password: e.target.value, passwordRequired: false })} style={{ border: this.state.passwordRequired && "2px solid red" }} />
                <div id="errPassword" className="error"></div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-5 d-none d-md-block"></div>
              <div className="col-md-7">
                <div className="row mt-2">
                  <div className='col-md-6'>
                    {/* <a className='text-primary' onClick={() => this.setState({ forgotPassword: true })} style={{ cursor: "pointer" }}>Forgot Username?</a> */}
                  </div>
                  <div className='col-md-6 text-end'>
                    <a className='text-primary' onClick={() => this.setState({ forgotPassword: true })} style={{ cursor: "pointer" }}>Forgot Password?</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className='col-md-5 text-md-end text-start align-self-center'>
                <span></span>
              </div>
              <div className="mb-4 col-md-5 ">
                <button className='btn btn-blue text-light' onClick={this.login} type='submit'>ENTER</button>
              </div>
            </div>
          </div>
          <div className='bg-blue d-flex flex-wrap'>
            <div className='col-md-6 d-flex align-items-center justify-content-end'>
              <img src={logo} height={160} alt="ARBlogo" />
            </div>
            <div className='col-md-6 d-flex flex-column align-items-start p-5 text-thin' style={{ fontSize: "18px" }}>
              <div>&copy; {new Date().getFullYear()} All rights reserved</div>
              <div>Licensed exclusively to the</div>
              <div>Advertising Regulatory Board</div>
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.forgotPassword}>
          <ModalHeader>
            Forgot Password
          </ModalHeader>
          <ModalBody>
            <div className='row'>
              <div >
                <label>Please enter your email address</label>
              </div>
              <div>
                <input type='text' className='form-control' placeholder="Email address" onChange={e => this.setState({ forgotUsername: e.target.value })} />
                <div id="errEmail" className="error"></div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => this.setState({ forgotPassword: false })}>
              Cancel
            </Button>
            <Button color='primary' onClick={this.forgotPassword = this.forgotPassword.bind(this)}>
              Submit
            </Button>
          </ModalFooter>
        </Modal>

        <ThrobbleHelper />
        
      </div>
    );
  }

  async forgotPassword(evt) {
    evt.stopPropagation();
    // this.setState({ hideThrobble: false });
    let isValid = true;
    isValid &= fieldRequired(this.state.forgotUsername, "errEmail", "* Required");

    if (isValid) {
      ThrobbleHelper.ToggleThrobble(true, "Checking Account");

      try {
        const response = await fetch("api/login/ForgotPassword/" + this.state.forgotUsername, {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        });

        if (response.ok) {
          showToast("success", "Forgot Password", "Your password reset request was successful. Please check your email inbox or spam folder for the password reset link.");
          ThrobbleHelper.ToggleThrobble(false);
          this.setState({ forgotPassword: false });
        } else {
          showToast("error", "Forgot Password", "An account with the entered email address was not found. Please check if the entered email address is correct.");
          ThrobbleHelper.ToggleThrobble(false);
        }
      } catch (e) {
        console.error(e);
        showToast("error", "Forgot Password", "An account with the entered email address was not found. Please check if the entered email address is correct.");
        ThrobbleHelper.ToggleThrobble(false);
      }
    }

  }

  async login() {

    let isValid = true
 

    isValid &= fieldRequired(this.state.username, "errUsername", "* Required");
    isValid &= fieldRequired(this.state.password, "errPassword", "* Required");

    if (isValid) {
      ThrobbleHelper.ToggleThrobble(true, "Logging in");
      let response = await fetch(`/api/login?username=${this.state.username}&password=${this.state.password}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json"
        }
      });
      if (response.ok) {
        let data = await response.json();
        setCurrentUser(data)
        window.location.href = "/mydms"
      } else {
        if (response.status === 401) {
          showToast("error", "Login", "Incorrect Username or Password");
        } else {
          showToast("error", "Login", "An error occurred");
        }
        setTimeout(() => {
          ThrobbleHelper.ToggleThrobble(false);
          this.setState({ errorModal: false, errorMessage: "" })
        }, 1500)
      }
    }
  }
}
