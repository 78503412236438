import React, { Component } from 'react';
import { Collapse, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Navbar, NavItem, NavLink, Tooltip } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { urls } from '../helpers/Urls';
import { getCurrentUser } from '../helpers/GetCurrentUser';
import { myContext } from '../Context';
export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = {
      collapsed: true,
      caseLoadDropDownOpen: false,
      reportsDropDownOpen: false,
      adminDropDownOpen: false,
      active: window.location.pathname,
      ttCases: false,
      ttComplainants: false,
      ttRespondents: false,
      ttMedia: false,
      ttIndustry: false,
      ttLevel: false,
      ttOutcomes: false,
      ttCaseAnalysis: false,
      ttAttachments: false,

    };
    this.toggleDropDown = this.toggleDropDown.bind(this);
    this.toggleCases = this.toggleCases.bind(this);
    this.toggleComplainants = this.toggleComplainants.bind(this);
    this.toggleRespondents = this.toggleRespondents.bind(this);
    this.toggleMedia = this.toggleMedia.bind(this);
    this.toggleIndustry = this.toggleIndustry.bind(this);
    this.toggleLevel = this.toggleLevel.bind(this);
    this.toggleOutcomes = this.toggleOutcomes.bind(this);
    this.toggleCaseAnalysis = this.toggleCaseAnalysis.bind(this);
    this.toggleAttachments = this.toggleAttachments.bind(this);

  }

  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }

  toggleDropDown(toggle) {
    this.setState({
      [toggle]: !this.state[toggle]
    })
  }

  toggleCases() { this.setState({ ttCases: !this.state.ttCases }); }
  toggleComplainants() { this.setState({ ttComplainants: !this.state.ttComplainants }); }
  toggleRespondents() { this.setState({ ttRespondents: !this.state.ttRespondents }); }
  toggleMedia() { this.setState({ ttMedia: !this.state.ttMedia }); }
  toggleIndustry() { this.setState({ ttIndustry: !this.state.ttIndustry }); }
  toggleLevel() { this.setState({ ttLevel: !this.state.ttLevel }); }
  toggleOutcomes() { this.setState({ ttOutcomes: !this.state.ttOutcomes }); }
  toggleCaseAnalysis() { this.setState({ ttCaseAnalysis: !this.state.ttCaseAnalysis }); }
  toggleAttachments() { this.setState({ ttAttachments: !this.state.ttAttachments }); }

  render() {
    return (
      <header>
        <Navbar className="navbar navbar-expand-lg navbar-toggleable-lg ng-white border-bottom box-shadow mb-3 bg-blue" container light>
          <Collapse isOpen={!this.state.collapsed} navbar >
            <ul className="navbar-nav flex-grow d-flex justify-content-between align-items-center w-100">
              <NavItem>
                <NavLink tag={Link} className="text-light" to={urls.myDms} onClick={() => {
                  this.setState({ active: urls.myDms })
                  this.context.setCaseId(0);
                }}><h5 style={{ color: this.state.active === urls.myDms ? "#DF6F6B" : "#fff" }}>MY DMS</h5></NavLink>
              </NavItem>
              <NavItem >
                <NavLink tag={Link} className='w-100 text-light' to={urls.caseLoad} onClick={() => {
                  this.setState({ active: urls.caseLoad })
                }}>
                  <h5 style={{ color: this.state.active === urls.caseLoad ? "#DF6F6B" : "#fff" }}>CASE LOAD</h5>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-light" to={urls.notePad} onClick={() => this.setState({ active: urls.notePad })}><h5 style={{ color: this.state.active === urls.notePad ? "#DF6F6B" : "#fff" }}>NOTEPAD</h5></NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-light" to={urls.calendar} onClick={() => this.setState({ active: urls.calendar })}><h5 style={{ color: this.state.active === urls.calendar ? "#DF6F6B" : "#fff" }}>CALENDAR</h5></NavLink>
              </NavItem>
              <NavItem>
                <Dropdown isOpen={this.state.reportsDropDownOpen} toggle={() => { this.toggleDropDown("reportsDropDownOpen") }} >
                  <DropdownToggle nav caret className='w-100 text-light'>
                    <h5 style={{ color: (this.state.reportsDropDownOpen || this.state.active.includes("/report")) ? "#DF6F6B" : "#fff" }}>REPORTS</h5>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem tag={Link} to={urls.cases} className='text-dark' id="mnuRptCases" onClick={() => this.setState({ ttCases: false, active: "/report" })}>
                      Cases
                    </DropdownItem>
                    <DropdownItem tag={Link} to={urls.complainants} className='text-dark' id="mnuRptComplainants" onClick={() => this.setState({ ttComplainants: false, active: "/report" })}>
                      Complainants
                    </DropdownItem>
                    <DropdownItem tag={Link} to={urls.respondents} className='text-dark' id="mnuRptRespondents" onClick={() => this.setState({ ttRespondents: false, active: "/report" })}>
                      Respondents
                    </DropdownItem>
                    <DropdownItem tag={Link} to={urls.media} className='text-dark' id="mnuRptMedia" onClick={() => this.setState({ ttMedia: false, active: "/report" })}>
                      Media
                    </DropdownItem>
                    <DropdownItem tag={Link} to={urls.industry} className='text-dark' id="mnuRptIndustry" onClick={() => this.setState({ ttIndustry: false, active: "/report" })}>
                      Industry
                    </DropdownItem>
                    <DropdownItem tag={Link} to={urls.level} className='text-dark' id="mnuRptLevel" onClick={() => this.setState({ ttLevel: false, active: "/report" })}>
                      Level
                    </DropdownItem>
                    <DropdownItem tag={Link} to={urls.outcomes} className='text-dark' id="mnuRptOutcomes" onClick={() => this.setState({ ttOutcomes: false, active: "/report" })}>
                      Outcomes
                    </DropdownItem>
                    <DropdownItem tag={Link} to={urls.caseAnalysis} className='text-dark' id="mnuRptCaseAnalysis" onClick={() => this.setState({ ttCaseAnalysis: false, active: "/report" })}>
                      Case Analysis
                    </DropdownItem>
                    {/* <DropdownItem tag={Link} to={urls.attachments} className='text-dark' id="mnuRptAttachments" onClick={() => this.setState({ ttAttachments: false, active: "/report" })}>
                      Attachments
                    </DropdownItem> */}
                  </DropdownMenu>
                </Dropdown>
              </NavItem>
              {getCurrentUser().userType === 100 && <NavItem>
                <Dropdown isOpen={this.state.adminDropDownOpen} toggle={() => { this.toggleDropDown("adminDropDownOpen") }} >
                  <DropdownToggle nav caret className='w-100 text-light'>
                    <h5 style={{ color: (this.state.adminDropDownOpen || this.state.active.match(/\/admin/g)) ? "#DF6F6B" : "#fff" }}>ADMIN</h5>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem tag={Link} to={urls.users} className='text-dark' onClick={() => this.setState({ active: urls.users })}>
                      Users
                    </DropdownItem>
                    <DropdownItem tag={Link} to={urls.status} className='text-dark' onClick={() => this.setState({ active: urls.status })}>
                      Status
                    </DropdownItem>
                    <DropdownItem tag={Link} to={urls.adminLevel} className='text-dark' onClick={() => this.setState({ active: urls.adminLevel })}>
                      Level
                    </DropdownItem>
                    <DropdownItem tag={Link} to={urls.adminOutcomes} className='text-dark' onClick={() => this.setState({ active: urls.adminOutcomes })}>
                      Outcomes
                    </DropdownItem>
                    <DropdownItem tag={Link} to={urls.adminConsumer} className='text-dark' onClick={() => this.setState({ active: urls.adminConsumer })}>
                      Complainant Type
                    </DropdownItem>
                    <DropdownItem tag={Link} to={urls.industries} className='text-dark' onClick={() => this.setState({ active: urls.industries })}>
                      Industry
                    </DropdownItem>
                    <DropdownItem tag={Link} to={urls.clauses} className='text-dark' onClick={() => this.setState({ active: urls.clauses })}>
                      Clauses
                    </DropdownItem>
                    <DropdownItem tag={Link} to={urls.medias} className='text-dark' onClick={() => this.setState({ active: urls.medias })}>
                      Media
                    </DropdownItem>
                    <DropdownItem tag={Link} to={urls.tasks} className='text-dark' onClick={() => this.setState({ active: urls.tasks })}>
                      Tasks
                    </DropdownItem>
                    <DropdownItem tag={Link} to={urls.membershipStatuses} className='text-dark' onClick={() => this.setState({ active: urls.membershipStatuses })}>
                      Membership Types
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </NavItem>}
              <NavItem>
                <NavLink tag={Link} className="text-light" onClick={() => {
                  localStorage.removeItem("user");
                  window.location.href = "/";
                }}><h5>LOGOUT</h5></NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} className="text-light" to={urls.search} onClick={() => this.setState({ active: urls.search })} >
                  <h5 style={{ color: this.state.active === urls.search ? "#DF6F6B" : "#fff" }}>
                    <i className='fa fa-magnifying-glass'></i>
                  </h5>
                </NavLink>
              </NavItem>
            </ul>
          </Collapse>
          <div className='nav-chevron'><i className={'fa fa-chevron-' + (this.state.collapsed ? 'down' : "up")} onClick={this.toggleNavbar}></i></div>
        </Navbar>
      </header >
    );
  }
}

NavMenu.contextType = myContext;
