import React from 'react'
import { Card, CardBody } from 'reactstrap';

export default function ConfirmHelper(props) {

    const onConfirm = (e) => {
        e.stopPropagation();
        if (props.onConfirm)
            props.onConfirm(props.DeleteId);
        props.onClose();
    }

    return (
        <div>
            <div className="custom-ui">
                <Card>
                    <CardBody className='text-center'>
                        <h3 className="text-uppercase">{props.Title}</h3>
                        <p>{props.Message}</p>
                        <hr />
                        <div className="text-end">
                            <button className='btn btn-primary btn-sm me-2' onClick={onConfirm}>Yes</button>
                            <button className='btn btn-secondary btn-sm' onClick={() => props.onClose()} >No</button></div>
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}
