/* eslint-disable default-case */
// import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, BarChart, Bar, AreaChart, Area, Pie, PieChart, Cell, ResponsiveContainer, Sector } from 'recharts';
import { GetColor } from '../helpers/Lookup';
import { ChartComponent, Inject, ColumnSeries, AreaSeries, LineSeries, Legend, Tooltip, DataLabel, Category, SeriesCollectionDirective, SeriesDirective } from '@syncfusion/ej2-react-charts';
import { AccumulationChartComponent, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, AccumulationLegend, AccumulationDataLabel, AccumulationTooltip } from '@syncfusion/ej2-react-charts';
import { Cell, Pie, PieChart, ResponsiveContainer, Legend as PieLegend } from 'recharts';

export function Graph({ data, type, value, chartName, xAxisName, yAxisName }) {
    let primaryxAxis = null;
    let primaryyAxis = null;

    const width = window.innerWidth / 2
    switch (type) {
        case "line":

            primaryxAxis = { valueType: 'Category', title: xAxisName };
            primaryyAxis = { minimum: 0, title: yAxisName };
            return (
                <ChartComponent className='w-100' primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis} name={chartName}>
                    <Inject services={[LineSeries, Legend, Tooltip, DataLabel, Category]} />
                    <SeriesCollectionDirective>
                        <SeriesDirective dataSource={data} xName='x' yName='y' type='Line' />
                    </SeriesCollectionDirective>

                </ChartComponent>
            );
        case "column":
            //console.log(yAxisName, xAxisName)
            primaryxAxis = { valueType: 'Category', title: xAxisName, edgeLabelPlacement: 'Shift' };
            primaryyAxis = { minimum: 0, title: yAxisName };
            return (
                <ChartComponent className='w-100' primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis} name={chartName}>
                    <Inject services={[ColumnSeries, Legend, Tooltip, DataLabel, Category]} />
                    <SeriesCollectionDirective>
                        <SeriesDirective dataSource={data} xName='x' yName='y' type='Column' dataLabelTemplate={<i class="fas fa-star"></i>} />
                    </SeriesCollectionDirective>

                </ChartComponent>
            )
        case "area":

            primaryxAxis = { valueType: 'Category', title: xAxisName };
            primaryyAxis = { minimum: 0, title: yAxisName };
            const marker = { visible: true, width: 10, height: 10, border: { width: 2, color: '#F8AB1D' }, dataLabel: { visible: true, position: 'Top' } };
            return (
                <ChartComponent className='w-100' primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis} name={chartName}>
                    <Inject services={[AreaSeries, Legend, Tooltip, DataLabel, Category]} />
                    <SeriesCollectionDirective>
                        <SeriesDirective dataSource={data} xName='x' yName='y' type='Area' fill="#8884d8" marker={marker} />
                    </SeriesCollectionDirective>
                </ChartComponent>
            );
        case "pie":
            const ItemWithValue = data.find(c => c.y > 0);
            //console.log(ItemWithValue);
            if (!ItemWithValue)
                data = [{ x: "No Items", y: 1, color: "#111" }];

            return (
                <ResponsiveContainer width={"100%"} height={600}>
                    <PieChart>
                        <Pie data={data} cx={"%50"} cy={"50%"} dataKey={"y"}
                            label={props => renderCustomizedLabel(props, !ItemWithValue)}
                            labelLine={true} stroke='#dfdfdf' nameKey={"x"} >
                            {data.map((e, idx) => (
                                <Cell key={`cell-${idx}`} fill={e.color ? e.color : GetColor(idx)} stroke='#000' />
                            ))}
                        </Pie>
                        <PieLegend content={renderLegend(data)} align="center" />
                    </PieChart>
                </ResponsiveContainer>
            )

    }

}



const capitalizeFirstLetter = (string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
}

const renderLegend = (data) => {
    //console.log(data)
    return (
        <div className="justify-content-center">
            <div className='d-flex justify-content-center' style={{flexWrap: "wrap"} }>
                {data.map((legend, index) =>
                    index <= data.length &&
                    <div key={index} className="mr-4 d-flex align-items-center px-1">
                            <div className="inline-block rounded-full items-center justify-center m-1"
                                    style={{ backgroundColor: legend.color, width: 15, height: 15 }}></div>
                                <div className="inline-block">{capitalizeFirstLetter(legend.x)}</div>
                        </div>)}
                </div>
        </div>
    );
}

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = (props, hideValues) => {
    //console.log(props);
    const { cx, cy, midAngle, innerRadius, outerRadius, percent, value, fill } = props;
    const radius = 25 + innerRadius + (outerRadius - innerRadius);
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
        (value > 0 && !hideValues) ?
            <text x={x} y={y} fill={"#000"} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(1)}% (${value})`}
            </text>
            : <></>
    );
};

