import React, { Component } from 'react';
import { SketchPicker } from 'react-color';
import { confirmAlert } from 'react-confirm-alert';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ConfirmHelper from '../../helpers/ConfirmHelper';
import { fieldRequired } from '../../helpers/fieldRequired';
import { getCurrentUser } from '../../helpers/GetCurrentUser';
import { removeLoginDetails } from '../../helpers/Lookup';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { showToast } from '../../helpers/ToastHelper';

export default class Outcomes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            outcomes: [],
            outcome: {
                AddedBy: getCurrentUser().id,
            },
            outcomesModal: false,
            colorOpen: false,
            orderBy: 'OutcomesDown'
        }
        this.outcomesModalToggle = this.outcomesModalToggle.bind(this);
        this.updateOutcome = this.updateOutcome.bind(this);
    }

    componentDidMount() {
        this.loadOutcomes()
    }

    outcomesModalToggle = () => {
        this.setState({ outcomesModal: !this.state.outcomesModal })
    }

    updateOutcome = () => {
        let valid = true;
        valid &= fieldRequired(this.state.outcome.name, 'outcome-error', "* required");
        valid &= fieldRequired(this.state.outcome.color, 'outcome-color-error', "* required");

        if (valid) {
            this.outcomesModalToggle();
            this.saveOutcome();
        }
    }

    handleColorChange = (color, type) => {
        this.setState({ [type]: { ...this.state[type], color: color.hex } })
    }

    sortBy(header) {
        if (this.state.orderBy.includes(header)) {
            if (this.state.orderBy.includes("Down")) {
                this.setState({ orderBy: header + "Up" })
            } else {
                this.setState({ orderBy: header + "Down" })
            }
        } else {
            this.setState({ orderBy: header + "Down" })
        }
    }

    renderSortArrow(header) {
        return this.state.orderBy === header + "Down" ? <i className='fa fa-long-arrow-down'></i> : this.state.orderBy === header + "Up" ? <i className='fa fa-long-arrow-up'></i> : <></>
    }

    render() {
        return <div>
            <div className='d-flex justify-content-between align-items-center'>
                <div>
                    <h1>Outcomes</h1>
                </div>
                <div>
                    <Button color="primary" onClick={() => {
                        this.setState({ outcome: { color: "", addedBy: getCurrentUser().id }, colorOpen: false })
                        this.outcomesModalToggle()
                    }}>Add Outcomes <i className='fa fa-plus-circle ms-2'></i></Button>
                </div>
            </div>
            <div>
                <table className="table table-striped">
                    <thead className='bg-blue'>
                        <tr>
                            <td style={{ cursor: "pointer" }} onClick={() => this.sortBy("Outcomes")}>{this.renderSortArrow("Outcomes")} Outcomes</td>
                            <td style={{ cursor: "pointer" }} onClick={() => this.sortBy("Color")}>{this.renderSortArrow("Color")} Color</td>
                            <td className='text-end'>Action</td>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.outcomes.length ? this.state.outcomes.sort((a, b) => {
                            switch (this.state.orderBy) {
                                case "OutcomesDown":
                                    return a.name.localeCompare(b.name);
                                case "OutcomesUp":
                                    return b.name.localeCompare(a.name);
                                case "ColorDown":
                                    return a.color.localeCompare(b.color);
                                case "ColorUp":
                                    return b.color.localeCompare(a.color);
                                default:
                                    return a.name.localeCompare(b.name);
                            }
                        }).map((outcome, index) => {
                            return (
                                <tr key={index}>
                                    <td>{outcome.name}</td>
                                    <td><div style={{ display: "inline-block", paddingLeft: "16px", width: "16px", height: "16px", backgroundColor: outcome.color, border: "1px solid #000" }}></div> {outcome.color}</td>
                                    <td >
                                        <div className='d-flex justify-content-end'>
                                            <div onClick={() => {
                                                this.setState({ outcome, colorOpen: true })
                                                this.outcomesModalToggle();
                                            }}><i className='fa fa-edit mx-2' title="Edit item"></i></div>
                                            <div onClick={() => this.deleteOutcome(outcome.id)}><i className='fa fa-trash-alt text-danger' title="Delete item"></i></div>
                                        </div>
                                    </td>
                                </tr>
                            )
                        }) : <tr><td colSpan='3' className='text-center'>No Outcomes Found</td></tr>}
                    </tbody>
                </table>
            </div>
            <Modal isOpen={this.state.outcomesModal}>
                <ModalHeader>Edit Outcome</ModalHeader>
                <ModalBody>
                    <div className='row'>
                        <div className="mb-3 col-12">
                            <label htmlFor="outcome" className="form-label">
                                Outcome
                                <div className='error' id='outcome-error'></div>
                            </label>
                            <input type="text" className="form-control" id="outcome" name="outcome" value={this.state.outcome.name} onChange={(e) => this.setState({ outcome: { ...this.state.outcome, name: e.target.value } })} />
                        </div>
                        <div className="mb-3 col-12">
                            <label htmlFor="color" className="form-label ">
                                Color
                                <div className='error' id='outcome-color-error'></div>
                            </label>
                            <SketchPicker
                                color={this.state.outcome.color}
                                onChange={(e) => this.handleColorChange(e, "outcome")}
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={this.outcomesModalToggle}>Cancel<i className="far fa-times-circle ms-2"></i></Button>
                    <Button color="primary" onClick={this.updateOutcome}>Save<i className="far fa-check-circle ms-2"></i></Button>
                </ModalFooter>
            </Modal>

            <ThrobbleHelper />
        </div>
    }

    loadOutcomes = async () => {
        try {
            ThrobbleHelper.ToggleThrobble(true, "Loading Outcomes");
            const responses = await fetch('/api/outcomes', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }
            })
            if (responses.ok) {
                const data = await responses.json();
                this.setState({ outcomes: data });;
                ThrobbleHelper.ToggleThrobble(false, true);
            } else {
                if (responses.status === 401) {
                    removeLoginDetails();
                    window.location.href = "/";
                } else
                    ThrobbleHelper.ToggleThrobble(false, true);
            }

        } catch (error) {
            console.error(error);
            ThrobbleHelper.ToggleThrobble(false, true);
        }
    }

    saveOutcome = async () => {
        try {
            ThrobbleHelper.ToggleThrobble(true, "Saving Outcome")
            const data = { ...this.state.outcome, modifiedBy: getCurrentUser().id };
            const response = await fetch('/api/outcomes', {
                method: this.state.outcome.id ? "PUT" : 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem('token')
                },
                body: JSON.stringify(data)

            })
            if (response.ok) {
                this.loadOutcomes();
                // ThrobbleHelper.ToggleThrobble(false, true);
                showToast("success", "Save Outcome", "Outcome was successfully saved");
            } else {
                if (response.status === 401) {
                    removeLoginDetails();
                    window.location.href = "/";
                } else {
                    ThrobbleHelper.ToggleThrobble(false, true);
                    showToast("error", "Save Outcome", "There was an error saving outcome");
                }
            }

        } catch (error) {
            console.error(error);
            ThrobbleHelper.ToggleThrobble(false, true);
            showToast("error", "Save Outcome", "There was an error saving outcome");
        }
    }

    deleteOutcome = (id) => {
        const ConfirmProps = { DeleteId: id, Title: "Delete Outcome", Message: "Are you sure you want to delete this outcome?" }
        confirmAlert({
            customUI: ({ onClose }) => {
                return <ConfirmHelper {...ConfirmProps} onClose={onClose} onConfirm={this.deleteOutcomeData} />
            }
        })
    }

    deleteOutcomeData = async (id) => {

        try {
            ThrobbleHelper.ToggleThrobble(true, "Deleting outcome");
            const response = await fetch('/api/outcomes/' + id, {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem('token')
                },
            })
            if (response.ok) {
                this.loadOutcomes();
                // ThrobbleHelper.ToggleThrobble(false, true);
                showToast("success", "Delete Outcome", "Outcome was successfully deleted");
            } else {
                if (response.status === 401) {
                    removeLoginDetails();
                    window.location.href = "/";
                } else {
                    ThrobbleHelper.ToggleThrobble(false, true);
                    showToast("error", "Delete Outcome", "There was an error deleting outcome");
                }
            }
        } catch (error) {
            console.error(error);
            ThrobbleHelper.ToggleThrobble(false, true);
            showToast("error", "Delete Outcome", "There was an error deleting outcome");
        }
    }

}
