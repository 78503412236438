import moment from 'moment';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { Button, Card, CardBody, Label } from 'reactstrap';
import { removeLoginDetails } from '../../helpers/Lookup';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { showToast } from '../../helpers/ToastHelper';
import { Graph } from '../LineChart';

export default class Industry extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ReportData: [], ReportStartDate: new Date(moment().add(-30, "days").format("yyyy-MM-DD HH:mm:ss")), ReportEndDate: new Date(moment().format("yyyy-MM-DD HH:mm:ss")), loading: true, hideThrobble: false
        }

        this.ExportReport = this.ExportReport.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {

        ThrobbleHelper.ToggleThrobble(true, "Loading Industy Report");
        const bearer = "Bearer " + localStorage.getItem("token");
        try {
            const startDate = moment(this.state.ReportStartDate).format("yyyy-MM-DD");
            const endDate = moment(this.state.ReportEndDate).format("yyyy-MM-DD");
            const response = await fetch("/api/reports/GetIndustryReport/" + startDate + "/" + endDate, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/json"
                }
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data);
                this.setState({ ReportData: data, loading: false });
                ThrobbleHelper.ToggleThrobble(false);
            } else {
                this.setState({ loading: false });
                ThrobbleHelper.ToggleThrobble(false);
            }
        } catch (e) {
            console.error(e);
            this.setState({ loading: false });
            ThrobbleHelper.ToggleThrobble(false);
        }
    }

    renderPieChart(data) {
        console.log(data)
        data = data.filter(item => item.x)
        return (
            <div className='d-flex justify-content-center'>
                <Graph data={data} type={"pie"} />
            </div>
        )
    }

    render() {

        const content = this.state.loading ? <>Loading Industry Report...</> : this.renderPieChart(this.state.ReportData);
        return (
            <>
                <div className='dms-header d-flex justify-content-center p-3 align-items-center '>
                    <h1 style={{ fontSize: "30px", fontWeight: 100 }}>COMPLAINTS BY INDUSTRY</h1>
                </div>

                <Card className='my-3'>
                    <CardBody>
                        <div className="row">
                            <div className="col-md-3">
                                <Label>Filter By Date Range</Label><br />
                                <DatePicker
                                    className={'form-control form-control-sm date-picker graph-filter'}
                                    selectsRange={true}
                                    placeholderText='Select Dates'
                                    startDate={this.state.ReportStartDate}
                                    endDate={this.state.ReportEndDate}
                                    dateFormat='yyyy-MM-dd'
                                    onChange={(update) => this.setState({ ReportStartDate: update[0], ReportEndDate: update[1] }, () => this.state.ReportEndDate && this.loadData())}
                                    isClearable={false}
                                />
                            </div>
                            <div className="col-md-9 align-self-end text-end">
                                <Button color='primary' onClick={this.ExportReport}><i className='far fa-file-pdf me-2'></i>Export to PDF</Button>
                            </div>
                        </div>
                    </CardBody>
                </Card>

                <Card>
                    <CardBody>
                        {content}
                    </CardBody>
                </Card>
                <ThrobbleHelper />
            </>
        )
    }

    async ExportReport(e) {
        e.stopPropagation();

        ThrobbleHelper.ToggleThrobble(true, "Exporting Industry Report");
        const bearer = "Bearer " + localStorage.getItem("token");
        try {
            const startDate = moment(this.state.ReportStartDate).format("yyyy-MM-DD");
            const endDate = moment(this.state.ReportEndDate).format("yyyy-MM-DD");
            const response = await fetch("api/reports/GenerateIndustriesReport/" + startDate + "/" + endDate, {
                method: "GET",
                headers: {
                    Authorization: bearer,
                    "Content-Type": "application/pdf"
                }
            });

            if (response.ok) {
                const data = await response.blob();
                const contentDisposition = response.headers.get("content-disposition");
                const fileNameMatch = contentDisposition.match(/filename=(.*?);/);
                const fileName = fileNameMatch
                    ? fileNameMatch[1].replace(/"/g, "")
                    : "unknown";

                // Create a URL from the response blob
                const fileUrl = URL.createObjectURL(data);
                const LinkBtn = document.createElement("a");
                LinkBtn.download = fileName;
                LinkBtn.href = fileUrl;
                LinkBtn.click();

                ThrobbleHelper.ToggleThrobble(false);
                showToast("success", "Industry Report Export", "Industry report was successfully exported");
            } else {
                if (response.status === 401) {
                    removeLoginDetails();
                    window.location.href = "/";
                } else {
                    ThrobbleHelper.ToggleThrobble(false);
                    showToast("error", "Industry Report Export", "There was an error exporting industry report");
                }
            }
        } catch (e) {
            ThrobbleHelper.ToggleThrobble(false);
            showToast("error", "Industry Report Export", "There was an error exporting industry report");
            console.log(e);
        }
    }
}
