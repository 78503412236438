import React, { Component } from 'react';
import { SketchPicker } from 'react-color';
import { confirmAlert } from 'react-confirm-alert';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import ConfirmHelper from '../../helpers/ConfirmHelper';
import { fieldRequired } from '../../helpers/fieldRequired';
import { getCurrentUser } from '../../helpers/GetCurrentUser';
import { removeLoginDetails } from '../../helpers/Lookup';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { showToast } from '../../helpers/ToastHelper';

export default class Status extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderBy: "StatusDown",
      statuses: [],
      status: {
        AddedBy: getCurrentUser().id,
      },
      statusModal: false,
      colorOpen: false
    }
    this.statusModalToggle = this.statusModalToggle.bind(this);
  }

  componentDidMount() {
    this.loadStatus();
  }

  statusModalToggle = () => {
    this.setState({ statusModal: !this.state.statusModal })
  }

  updateStatus = () => {
    let valid = true;
    valid &= fieldRequired(this.state.status.name, 'status-error', "* required");
    valid &= fieldRequired(this.state.status.color, 'color-error', "* required");

    if (valid) {
      this.statusModalToggle();
      this.saveStatus();
    }
  }

  handleColorChange = (color, type) => {
    this.setState({ [type]: { ...this.state[type], color: color.hex } })
  }


  sortBy(header) {
    if (this.state.orderBy.includes(header)) {
      if (this.state.orderBy.includes("Down")) {
        this.setState({ orderBy: header + "Up" })
      } else {
        this.setState({ orderBy: header + "Down" })
      }
    } else {
      this.setState({ orderBy: header + "Down" })
    }
  }

  renderSortArrow(header) {
    return this.state.orderBy === header + "Down" ? <i className='fa fa-long-arrow-down'></i> : this.state.orderBy === header + "Up" ? <i className='fa fa-long-arrow-up'></i> : <></>
  }

  render() {
    return <div>
      <div className='d-flex justify-content-between align-items-center'>
        <div>
          <h1>Status</h1>
        </div>
        <div>
          <Button color="primary" onClick={() => {
            this.setState({ status: {} })
            this.statusModalToggle()
          }}>Add Status <i className='fa fa-plus-circle ms-2'></i></Button>
        </div>
      </div>
      <div>
        <table className="table table-striped">
          <thead className='bg-blue'>
            <tr>
              <td style={{ cursor: "pointer" }} onClick={() => this.sortBy("Status")}>{this.renderSortArrow("Status")} Status</td>
              <td style={{ cursor: "pointer" }} onClick={() => this.sortBy("Color")}>{this.renderSortArrow("Color")} Color</td>
              <td className='text-end'>Action</td>
            </tr>
          </thead>
          <tbody>
            {this.state.statuses.length ? this.state.statuses.sort((a, b) => {
              switch (this.state.orderBy) {
                case "StatusDown":
                  return a.name.localeCompare(b.name);
                case "StatusUp":
                  return b.name.localeCompare(a.name);
                case "ColorDown":
                  return a.color.localeCompare(b.color);
                case "ColorUp":
                  return b.color.localeCompare(a.color);
                default:
                  return a.name.localeCompare(b.name);
              }
            }).map((status, index) => {
              return (
                <tr key={index}>
                  <td>{status.name}</td>
                  <td><div style={{ display: "inline-block", paddingLeft: "16px", width: "16px", height: "16px", backgroundColor: status.color, border: "1px solid #000" }}></div> {status.color}</td>
                  <td >
                    <div className='d-flex justify-content-end'>
                      <div onClick={() => {
                        this.setState({ status })
                        this.statusModalToggle();
                      }}><i className='fa fa-edit mx-2' title="Edit item"></i></div>
                      <div onClick={() => this.deleteStatus(status.id)}><i className='fa fa-trash-alt text-danger' title="Delete item"></i></div>
                    </div>
                  </td>
                </tr>
              )
            }) : <tr><td colSpan='3' className='text-center'>No Status Found</td></tr>}
          </tbody>
        </table>
      </div>
      <Modal isOpen={this.state.statusModal}>
        <ModalHeader>Edit Status</ModalHeader>
        <ModalBody>
          <div className='row'>
            <div className="mb-3 col-12">
              <label htmlFor="status" className="form-label">
                Status
                <div className='error' id='status-error'></div>
              </label>
              <input type="text" className="form-control" id="status" name="status" value={this.state.status.name} onChange={(e) => this.setState({ status: { ...this.state.status, name: e.target.value } })} />
            </div>
            <div className="mb-3 col-12">
              <label htmlFor="color" className="form-label">
                Color
                <div className='error' id='color-error'></div>
              </label>
              <SketchPicker
                color={this.state.status.color}
                onChange={(e) => this.handleColorChange(e, "status")}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.statusModalToggle}>Cancel<i className="far fa-times-circle ms-2"></i></Button>
          <Button color="primary" onClick={this.updateStatus}>Save<i className="far fa-check-circle ms-2"></i></Button>
        </ModalFooter>
      </Modal>
      <ThrobbleHelper />
    </div>
  }


  loadStatus = async () => {
    try {

      ThrobbleHelper.ToggleThrobble(true, "Loading status");
      const responses = await fetch('api/status', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      })
      if (responses.ok) {
        const data = await responses.json();
        this.setState({ statuses: data });
        ThrobbleHelper.ToggleThrobble(false, true);
      } else {
        if (responses.status === 401) {
          removeLoginDetails();
          window.location.href = "/";
        } else {
          ThrobbleHelper.ToggleThrobble(false, true);
        }
      }

    } catch (error) {
      console.error(error);
      ThrobbleHelper.ToggleThrobble(false, true);
    }
  }

  saveStatus = async () => {
    try {

      ThrobbleHelper.ToggleThrobble(true, "Saving Status");
      const response = await fetch('api/status', {
        method: this.state.status.id ? "PUT" : 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + localStorage.getItem('token')
        },
        body: JSON.stringify(this.state.status)

      })
      if (response.ok) {
        this.loadStatus();
        showToast("success", "Save Status", "Status was successfully saved!");
      } else {
        if (response.status === 401) {
          removeLoginDetails();
          window.location.href = "/";
        } else {
          ThrobbleHelper.ToggleThrobble(false, true);
          showToast("error", "Save Status", "There was an error saving status");
        }
      }

    } catch (error) {
      console.error(error);
      ThrobbleHelper.ToggleThrobble(false, true);
      showToast("error", "Save Status", "There was an error saving status");
    }
  }

  deleteStatus = (id) => {

    const ConfirmProps = { DeleteId: id, Title: "Delete Status", Message: "Are you sure you want to delete this status?" };
    confirmAlert({
      customUI: ({ onClose }) => <ConfirmHelper {...ConfirmProps} onClose={onClose} onConfirm={this.deleteStatusData} />
    })
  }

  deleteStatusData = async (id) => {

    ThrobbleHelper.ToggleThrobble(true, "Deleting Status");
    try {
      const response = await fetch('api/status/' + id, {
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + localStorage.getItem('token')
        },
      })
      if (response.ok) {
        this.loadStatus();
        ThrobbleHelper.ToggleThrobble(false, true);
        showToast("success", "Delete Status", "Status was successfully deleted!");
      }
      else {
        if (response.status === 401) {
          removeLoginDetails();
          window.location.href = "/";
        } else {
          ThrobbleHelper.ToggleThrobble(false, true);
          showToast("error", "Delete Status", "There was an error deleting status");
        }
      }
    } catch (error) {
      console.error(error);
      ThrobbleHelper.ToggleThrobble(false, true);
      showToast("error", "Delete Status", "There was an error deleting status");
    }
  }
}
