/* eslint-disable eqeqeq */
import parse from 'html-react-parser';
import React, { Component } from 'react';
import Select from 'react-select';
import { Button, Card, CardBody } from 'reactstrap';
import { myContext } from '../Context';
import { getCurrentUser } from '../helpers/GetCurrentUser';
import { removeLoginDetails } from '../helpers/Lookup';
import ThrobbleHelper from '../helpers/ThrobbleHelper';
import { Link } from 'react-router-dom';
import { checkColorBrightness } from '../helpers/global';
import moment from 'moment';
import DatePicker from 'react-datepicker';

export class MyDms extends Component {
  static displayName = MyDms.name;
  constructor(props) {
    super(props);
    this.state = {
      active: "myOpenCases",
      assignClauses: "",
      attachments: "",
      attachmentFiles: [],
      casesOpen: false,
      caseModal: false,
      close: true,
      complainant: "",
      consumer: 0,
      consumerDropdown: false,
      consumers: [],
      cases: [],
      dateAssigned: new Date(),
      dateOpened: new Date(),
      dateAdded: new Date(),
      description: "",
      filterCase: 2,
      industry: "",
      id: 0,
      caseNumber: 0,
      loading: false,
      media: [],
      orderBy: "FileDown",
      fileWith: [],
      level: 0,
      levelDropdown: false,
      levels: [],
      ddLevels: [],
      statusId: 0,
      levelId: 0,
      outcomeId: 0,
      complainantTypeId: 0,
      assignClause: '',
      membershipStatus: 0,
      memberStatuses: [],
      outcome: 0,
      outcomeDropdown: false,
      outcomes: [],
      ddOutcomes: [],
      medias: [],
      mediaDropdown: false,
      search: "",
      status: 0,
      statuses: [],
      ddStatuses: [],
      tasks: [],
      statusDropdown: false,
      taskDropdown: false,
      showFor: 0,
      oneDayInMilliseconds: 24 * 60 * 60 * 1000,
      specificDate: moment().format("yyyy-MM-DD"),
      filterDateRange: [new Date(moment().add(-(30 * 6), "days").format("yyyy-MM-DD")), new Date(moment().format("yyyy-MM-DD"))]
      //user: 0,
      //users: []
    }

    this.sortBy = this.sortBy.bind(this);
    this.renderSortArrow = this.renderSortArrow.bind(this);
    this.editCases = this.editCases.bind(this);

    MyDms.parentObj = this;
  }

  componentDidMount() {
    async function load() {
      await MyDms.parentObj.loadCases();
      let id = MyDms.parentObj.context.caseId;
      if (id) {
        MyDms.parentObj.editCases(id);
      }
    }
    load()
  }

  sortBy(header) {
    if (this.state.orderBy.includes(header)) {
      if (this.state.orderBy.includes("Down")) {
        this.setState({ orderBy: header + "Up" })
      } else {
        this.setState({ orderBy: header + "Down" })
      }
    } else {
      this.setState({ orderBy: header + "Down" })
    }
  }

  renderSortArrow(header) {
    return this.state.orderBy === header + "Down" ? <i className='fa fa-long-arrow-down'></i> : this.state.orderBy === header + "Up" ? <i className='fa fa-long-arrow-up'></i> : <></>
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }


  getFileWithName(caseId) {

  }

  renderTasks(caseId) {

    const tasksToRender = this.state.tasks.sort((a, b) => new Date(a.dateAdded) - new Date(b.dateAdded)).filter(item => item.caseId === caseId)[0];
    console.log(caseId, this.state.tasks, tasksToRender);

    return (
      <div>
        <span>{tasksToRender?.taskName}</span>
      </div>
    );
  }

  zeroPad = (num, places) => String(num).padStart(places, '0');

  editCases(id) {
    window.location.href = 'case-file/'
  }

  renderDataTable(data) {
    if (this.state.filterCase)
      data = data.filter(item => item.statusId == this.state.filterCase)

    if (this.state.level)
      data = data.filter(item => item.levelId == this.state.level);

    if (this.state.outcome)
      data = data.filter(item => item.outcomeId == this.state.outcome)

    if (this.state.search)
      data = data.filter(item => item.description.toLowerCase().includes(this.state.search.toLowerCase()));

      data = data.filter(c => {
        const dateOpened = moment(c.dateOpened);
        switch (this.state.showFor) {
            case 1:
                return moment().format("DD/MM/yyyy") === dateOpened.format("DD/MM/yyyy");
            case 2:
                return moment(this.state.specificDate).format("DD/MM/yyyy") === dateOpened.format("DD/MM/yyyy");
            default:
                const startDate = moment(this.state.filterDateRange[0]);
                const endDate = moment(this.state.filterDateRange[1]).add(1, "days");
                return dateOpened >= startDate && dateOpened <= endDate;
        }
    });

    return <div className='table-container' >
      <table className='table table-striped table-responsive table-hover table-sm ' style={{ border: "1px solid #201d39" }} >
        <thead className='bg-blue'>
          <tr style={{ padding: 0, margin: 0 }}>
            <th style={{ width: "10%", cursor: "pointer" }} onClick={() => this.sortBy("File")}>{this.renderSortArrow("File")} File #</th>
            <th style={{ width: "25%", cursor: "pointer" }} onClick={() => this.sortBy("Description")}>{this.renderSortArrow("Description")} File Name </th>
            <th className="text-center" style={{ width: "10%", cursor: "pointer" }} onClick={() => this.sortBy("Age")}>{this.renderSortArrow("Age")} Age (Days)</th>
            <th className="text-center" style={{ width: "8%" }}> Status</th>
            <th style={{ width: "10%", cursor: "pointer" }} onClick={() => this.sortBy("AssignedTo")}>{this.renderSortArrow("AssignedTo")} Assigned To</th>
            {/*<th style={{ width: "15%", cursor: "pointer" }} onClick={() => this.sortBy("ExternalAdjudicator")}>{this.renderSortArrow("ExternalAdjudicator")} External Adjudicator</th>*/}
            <th style={{ width: "10%", cursor: "pointer" }} onClick={() => this.sortBy("AssignedDate")}>{this.renderSortArrow("AssignedDate")} Date Due</th>
            <th style={{ width: "25%" }}> Tasks </th>
            {/*<th className="text-center" style={{ width: "10%" }} > Level</th>*/}
            {/*<th className="text-center" style={{ width: "10%", cursor: "pointer" }} onClick={() => this.sortBy("Outcome")} >{this.renderSortArrow("Outcome")} Outcome</th>*/}
            <th style={{ width: "10%" }} className="text-end" >Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.length ? data.sort((a, b) => {
            switch (this.state.orderBy) {
              case "FileUp":
                return b.id - a.id;
              case "DescriptionDown":
                return a.description.localeCompare(b.description);
              case "DescriptionUp":
                return b.description.localeCompare(a.description);
              case "DateOpenedDown":
                return new Date(a.dateOpened) - new Date(b.dateOpened);
              case "DateOpenedUp":
                return new Date(b.dateOpened) - new Date(a.dateOpened);
              case "AgeDown":
                return new Date().getDate() - new Date(a.dateOpened).getDate();
              case "AgeUp":
                return new Date(a.dateOpened).getDate() - new Date().getDate();
              case "StatusDown":
                return a.status - b.status;
              case "StatusUp":
                return b.status - a.status;
              case "AssignedToDown":
                return a.latestTask?.fileWithName.localeCompare(b.latestTask?.fileWithName);
              case "AssignedToUp":
                return b.latestTask?.fileWithName.localeCompare(a.latestTask?.fileWithName);
              case "ExternalAdjudicatorDown":
                return a.externalAdjudicator.localeCompare(b.externalAdjudicator);
              case "ExternalAdjudicatorUp":
                return b.externalAdjudicator.localeCompare(a.externalAdjudicator);
              case "AssignedDateDown":
                return a.latestTask ? new Date(a.latestTask?.dateDue) - new Date(b.latestTask?.dateDue) : false;
              case "AssignedDateUp":
                return b.latestTask ? new Date(b.latestTask?.dateDue) - new Date(a.latestTask?.dateDue) : false;
              case "LevelDown":
                return a.level - b.level;
              case "LevelUp":
                return b.level - a.level;
              case "OutcomeDown":
                return a.outcomeId - b.outcomeId;
              case "OutcomeUp":
                return b.outcomeId - a.outcomeId;
              default:
                return a.id - b.id;
            }
          }).map((item, index) => {
            const level = this.state.levels.find(level => level.id === parseInt(item.levelId));
            const status = this.state.statuses.find(status => status.id === item.statusId);
            return <tr key={index}>
              <td className="text-start">{this.zeroPad(item.caseNumber, 5)}</td>
              <td>{parse(item.description.replace(/<p>/g, "").replace(/<\/p>/g, ""))}</td>
                <td className="text-center">{Math.floor((new Date() - new Date(item.dateOpened)) / this.state.oneDayInMilliseconds)}</td>
              <td className="text-center" style={{ background: status?.color, color: checkColorBrightness(status?.color) }}>{status?.name}</td>
              <td>{item.latestTask ? item.latestTask.fileWithName : "N/A"}</td>
              <td>{item.latestTask ? this.formatDate(new Date(item.latestTask.dateDue)) : "N/A"}</td>
              <td>{item.latestTask ? item.latestTask.taskName : "N/A"}</td>
              <td className="text-end">
                <i className='fa fa-edit me-2' title="Edit/View Case" onClick={() => document.location.href = "/case-file/" + item.id}></i>
              </td>
            </tr>
          }) :
            <tr>
              <td colSpan={9} className="text-center"><b>No Cases Yet</b></td>
            </tr>
          }
        </tbody>
      </table>
    </div>
  }


  render() {
    const userData = getCurrentUser();

    let cases = []

    let content = this.state.loading ? <p><em>Loading...</em></p> : this.renderDataTable(this.state.cases)
    const filterOptions = [{ label: "Date Range", value: 0 }, { label: "Today", value: 1 }, { label: "Specific Date", value: 2 }];

    return (
      <div >
        <div className='dms-header d-flex justify-content-center p-3 align-items-center '>
          <h1 style={{ fontSize: "30px", fontWeight: 100 }}>CASE FILES OF <span className='text-orange'>{userData?.firstName?.toUpperCase() + " " + userData?.lastName?.toUpperCase()}</span></h1>
        </div>


        <Card className='my-3'>
          <CardBody>
            <div className="row">
              <div className="col-12 align-self-end text-end mb-3">
                <Link to="/case-file/" className="btn btn-primary btn-sm" size='sm' color='primary'>
                  Add New Case <i className='fa fa-plus-circle ms-2'></i>
                </Link>
              </div>
              <div className="col-md-3">
                <label>Filter By Case Status</label>
                {/* <select className='form-select form-select-sm' onChange={(e) => this.setState({ filterCase: e.target.value })} value={this.state.filterCase}>
                  {this.state.statuses.map((item, index) => {
                    return <option value={item.id} title={item.name} key={index}>{item.name}</option>
                  })}
                </select> */}
                <Select
                  className='select2-sm'
                  options={this.state.ddStatuses}
                  isSearchable={true}
                  isClearable={false}
                  backspaceRemovesValue={false}
                  isRtl={false}
                  value={this.state.ddStatuses.filter(option => option.value === this.state.filterCase)}
                  onChange={e => this.setState({ filterCase: e.value })}
                />
              </div>
              <div className="col-md-3 mb-3">
                <label>Filter By Level </label>
                <Select
                  className={"select2-sm"}
                  options={this.state.ddLevels}
                  isSearchable={true}
                  isClearable={false}
                  backspaceRemovesValue={false}
                  isRtl={false}
                  value={this.state.ddLevels.filter(option => option.value === this.state.level)}
                  onChange={e => this.setState({ level: e.value })}
                />
              </div>

              <div className="col-md-3 mb-3">
                <label>Filter By Outcomes </label>
                <Select
                  className={"select2-sm"}
                  options={this.state.ddOutcomes}
                  isSearchable={true}
                  isClearable={false}
                  backspaceRemovesValue={false}
                  isRtl={false}
                  value={this.state.ddOutcomes.filter(option => option.value === this.state.outcome)}
                  onChange={e => this.setState({ outcome: e.value })}
                />
              </div>
              <div className="col-md-3">
                <label>Filter By Keyword</label>
                <input className='form-control form-control-sm lh-base' placeholder='Search...' onChange={(e) => this.setState({ search: e.target.value })} />
              </div>
              <div className="col-md-3">
                <label>Show For</label>
                <Select
                  className='select2-sm'
                  options={filterOptions}
                  isClearable={false}
                  isSearchable={false}
                  value={filterOptions.find(c => c.value === this.state.showFor)}
                  onChange={e => this.setState({ showFor: parseInt(e.value) })}
                />
              </div>
              <div className="col-md-3" hidden={this.state.showFor !== 0}>
                <label>Select Date Range</label>
                <DatePicker
                  className={'w-100 form-control form-control-sm lh-base date-picker'}
                  selectsRange={true}
                  placeholderText='Select Dates'
                  startDate={this.state.filterDateRange[0]}
                  endDate={this.state.filterDateRange[1]}
                  dateFormat='yyyy-MM-dd'
                  onChange={(update) => this.setState({ filterDateRange: update })}
                  isClearable={false}
                />
              </div>
              <div className="col-md-3" hidden={this.state.showFor !== 2}>
                <label>Select Date</label>
                {/*{console.log(this.state.specificDate)}*/}
                <DatePicker
                  className={'w-100 form-control form-control-sm lh-base date-picker'}
                  placeholderText='Select Date'
                  dateFormat={"yyyy-MM-dd"}
                  selected={new Date(this.state.specificDate)}
                  onChange={e => this.setState({ specificDate: moment(e).format("yyyy-MM-DD") })}
                  adjustDateOnChange={true}
                  onSelect={e => console.log(e)}
                  isClearable={false}
                />
              </div>
            </div>
          </CardBody>
        </Card>

        {content}
        <ThrobbleHelper />
      </div>
    );
  }

  async loadCases() {
    try {
      ThrobbleHelper.ToggleThrobble(true, "Loading Cases");
      this.setState({ loading: true });
      let userData = getCurrentUser();
      const response = await fetch('api/cases/' + userData.id, {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        console.log(data)
        this.setState({ cases: data, loading: false });
        await this.loadStatuses();
        await this.loadLevels();
        await this.loadOutcomes();
        // await this.loadTasks();
        ThrobbleHelper.ToggleThrobble(false, true);
      } else {
        if (response.status === 401) {
          removeLoginDetails();
          window.location.href = "/";
        } else {
          ThrobbleHelper.ToggleThrobble(false);
        }
      }

    } catch (error) {
      console.error(error);
      ThrobbleHelper.ToggleThrobble(false);
    }

  }

  async loadTasks() {
    try {
      const response = await fetch('/api/progress', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        this.setState({ tasks: data });
      }
    } catch (error) {
      console.error(error)
    }
  }

  async loadStatuses() {
    try {
      const response = await fetch('/api/status', {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
      });

      if (response.ok) {
        const data = await response.json();

        const ddStatuses = [{ value: 0, label: "- All My Cases -" }, ...data.map(item => {
          return { value: item.id, label: item.name }
        })]
        this.setState({ statuses: data, ddStatuses });
      }

    } catch (error) {
      console.error(error)
    }
  }

  async loadLevels() {
    try {
      const response = await fetch('/api/levels', {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
      });

      if (response.ok) {
        const data = await response.json();
        const ddLevels = [{ value: 0, label: "- All Levels -" }, ...data.map(item => {
          return { value: item.id, label: item.name }
        })]
        this.setState({ levels: data, ddLevels });
      }

    } catch (error) {
      console.error(error)
    }
  }

  async loadOutcomes() {
    try {
      const response = await fetch('/api/outcomes', {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          "Authorization": `Bearer ${localStorage.getItem("token")}`
        }
      });

      if (response.ok) {
        const data = await response.json();

        const ddOutcomes = [{ value: 0, label: "- All Outcomes -" }, ...data.map(item => {
          return { value: item.id, label: item.name }
        })]
        this.setState({ outcomes: data, ddOutcomes });
      }

    } catch (error) {
      console.error(error)
    }
  }
}

MyDms.contextType = myContext;