import React, { Component } from 'react'
import { Graph } from '../LineChart';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { removeLoginDetails } from '../../helpers/Lookup';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { showToast } from '../../helpers/ToastHelper';
import DatePicker from 'react-datepicker';
import moment from 'moment';

export default class Level extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cases: [],
            levels: [],
            ReportStartDate: new Date(moment().add(-30, "days").format("yyyy-MM-DD HH:mm:ss")),
            ReportEndDate: new Date(moment().format("yyyy-MM-DD HH:mm:ss")),
            orderBy: "levelDown"
        }

        this.ExportReport = this.ExportReport.bind(this);
    }

    componentDidMount() {
        this.loadCases();
    }

    renderSortArrow(header) {
        return this.state.orderBy === header + "Down" ? <i className='fa fa-long-arrow-down'></i> : this.state.orderBy === header + "Up" ? <i className='fa fa-long-arrow-up'></i> : <></>
    }

    sortBy(header) {
        if (this.state.orderBy.includes(header)) {
            if (this.state.orderBy.includes("Down")) {
                this.setState({ orderBy: header + "Up" })
            } else {
                this.setState({ orderBy: header + "Down" })
            }
        } else {
            this.setState({ orderBy: header + "Down" })
        }
    }

    render() {
        return (
            <div>
                <div className='dms-header d-flex justify-content-center p-3 align-items-center '>
                    <h1 style={{ fontSize: "30px", fontWeight: 100 }}>COMPLAINTS BY LEVELS</h1>
                </div>
                <Card className='my-3'>
                    <CardBody>
                        <div className="row">
                            <div className="col-md-3">
                                <DatePicker
                                    className={'form-control form-control-sm date-picker'}
                                    selectsRange={true}
                                    placeholderText='Select Dates'
                                    startDate={this.state.ReportStartDate}
                                    endDate={this.state.ReportEndDate}
                                    dateFormat='yyyy-MM-dd'
                                    onChange={(update) => this.setState({ ReportStartDate: update[0], ReportEndDate: update[1] }, () => this.state.ReportEndDate && this.loadCases())}
                                    isClearable={false}
                                />
                            </div>
                            <div className="col-md-9 align-self-end text-end">
                                <Button color='primary' onClick={this.ExportReport}><i className='far fa-file-pdf me-2'></i>Export to PDF</Button>
                            </div>
                        </div>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody>
                        <Row className='my-5 align-items-center'>
                            <Col md={4}>
                                <table className="table table-striped table-hover">
                                    <thead className='bg-blue'>
                                        <tr>
                                            <td style={{ width: "40%", cursor: "pointer" }} onClick={() => this.sortBy("level")}>{this.renderSortArrow("level")} Level</td>
                                            <td style={{ width: "40%", cursor: "pointer" }} onClick={() => this.sortBy("numOfCases")}>{this.renderSortArrow("numOfCases")} Number of cases</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.cases.length ? this.state.cases.sort((a, b) => {
                                            switch (this.state.orderBy) {
                                                case "levelDown":
                                                    return a.x.localeCompare(b.x)
                                                case "levelUp":
                                                    return b.x.localeCompare(a.x)
                                                case "numOfCasesDown":
                                                    return a.y - b.y
                                                case "numOfCasesUp":
                                                    return b.y - a.y
                                                default:
                                                    return a.name.localeCompare(b.name)
                                            }
                                        }).map((level, index) => {
                                            return <tr key={index}>
                                                <td>{level.x}</td>
                                                <td>{level.y}</td>
                                            </tr>
                                        }) :
                                            <tr>
                                                <td colSpan={9} className="text-center"><b>No Levels Yet</b></td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </Col>
                            <Col>

                                <div className='d-flex justify-content-center'>
                                    <Graph type={"pie"} data={this.state.cases} />
                                </div>

                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <ThrobbleHelper />
            </div>
        )
    }

    async ExportReport(evt) {
        evt.stopPropagation();
        ThrobbleHelper.ToggleThrobble(true, "Exporting Levels Report");
        try {
            const startDate = moment(this.state.ReportStartDate).format("yyyy-MM-DD");
            const endDate = moment(this.state.ReportEndDate).format("yyyy-MM-DD");
            const response = await fetch("api/reports/GenerateLevelReport/" + startDate + "/" + endDate, {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/pdf"
                }
            })

            if (response.ok) {
                const data = await response.blob();
                const contentDisposition = response.headers.get("content-disposition");
                const fileNameMatch = contentDisposition.match(/filename=(.*?);/);
                const fileName = fileNameMatch
                    ? fileNameMatch[1].replace(/"/g, "")
                    : "unknown";

                // Create a URL from the response blob
                const fileUrl = URL.createObjectURL(data);
                const LinkBtn = document.createElement("a");
                LinkBtn.download = fileName;
                LinkBtn.href = fileUrl;
                LinkBtn.click();

                ThrobbleHelper.ToggleThrobble(false);
                showToast("success", "Levels Report Export", "Levels report was successfully exported");
            } else {
                if (response.status === 401) {
                    removeLoginDetails();
                    window.location.href = "/";
                } else {
                    ThrobbleHelper.ToggleThrobble(false);
                    showToast("error", "Levels Report Export", "There was an error exporting levels report");
                }
            }
        }
        catch (e) {
            console.log(e);
            ThrobbleHelper.ToggleThrobble(false);
            showToast("error", "Levels Report Export", "There was an error exporting levels report");
        }

    }

    async loadCases() {
        // let levels = await this.loadLevels();
        try {
            ThrobbleHelper.ToggleThrobble(true, "Loading Levels Report");
            const startDate = moment(this.state.ReportStartDate).format("yyyy-MM-DD");
            const endDate = moment(this.state.ReportEndDate).format("yyyy-MM-DD");
            const response = await fetch("api/reports/GetLevelReport/" + startDate + "/" + endDate, {
                method: "GET",
                headers: {
                    "Authorization": "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json"
                }
            })

            if (response.ok) {
                const cases = await response.json();
                this.setState({ cases });
                ThrobbleHelper.ToggleThrobble(false);
            } else {
                if (response.status === 401) {
                    removeLoginDetails();
                    window.location.href = "/";
                } else
                    ThrobbleHelper.ToggleThrobble(false);
            }
        } catch (e) {
            console.error(e);
            ThrobbleHelper.ToggleThrobble(false);
        }
    }
}
