import React, { Component } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Button, Card, CardBody, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { fieldRequired } from '../../helpers/fieldRequired';
import { getCurrentUser } from '../../helpers/GetCurrentUser';
import { removeLoginDetails } from '../../helpers/Lookup';
import ThrobbleHelper from '../../helpers/ThrobbleHelper';
import { showToast } from '../../helpers/ToastHelper';
export default class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mediaModal: false,
      colorOpen: false,
      medias: [],
      media: {
        AddedBy: getCurrentUser().id,
      },
      currentPage: window.location.pathname,
      orderBy: "mediaDown"
    }
  }

  componentDidMount() {
    this.loadMedia();
  }

  mediaModalToggle = () => {
    this.setState({ mediaModal: !this.state.mediaModal })
  }

  updateMedia = () => {
    let valid = true;
    valid &= fieldRequired(this.state.media.name, 'media-error', "* required");

    if (valid) {
      this.mediaModalToggle();
      this.saveMedia();
    }
  }

  handleColorChange = (color, type) => {
    this.setState({ [type]: { ...this.state[type], color: color.hex } })
  }

  sortBy(header) {
    if (this.state.orderBy.includes(header)) {
      if (this.state.orderBy.includes("Down")) {
        this.setState({ orderBy: header + "Up" })
      } else {
        this.setState({ orderBy: header + "Down" })
      }
    } else {
      this.setState({ orderBy: header + "Down" })
    }
  }

  renderSortArrow(header) {
    return this.state.orderBy === header + "Down" ? <i className='fa fa-long-arrow-down'></i> : this.state.orderBy === header + "Up" ? <i className='fa fa-long-arrow-up'></i> : <></>
  }


  medias() {
    return <div>
      <div className='d-flex justify-content-between align-items-center'>
        <div>
          <h1>Media</h1>
        </div>
        <div>
          <Button color="primary" onClick={() => {
            this.setState({ media: { addedBy: getCurrentUser().id } })
            this.mediaModalToggle()
          }}>Add Media <i className='fa fa-plus-circle ms-2'></i></Button>
        </div>
      </div>
      <div>
        <table className="table table-striped">
          <thead className='bg-blue'>
            <tr>
              <td style={{ cursor: "pointer" }} onClick={() => this.sortBy("media")}>{this.renderSortArrow("media")} Media</td>
              <td className='text-end'>Action</td>
            </tr>
          </thead>
          <tbody>
            {this.state.medias.length ? this.state.medias.sort((a, b) => {
              switch (this.state.orderBy) {
                case "mediaDown":
                  return a.name.localeCompare(b.name)
                case "mediaUp":
                  return b.name.localeCompare(a.name)
                default:
                  return a.name.localeCompare(b.name)
              }
            }).map((media, index) => {
              return (
                <tr key={index}>
                  <td>{media.name}</td>
                  <td >
                    <div className='d-flex justify-content-end'>
                      <div onClick={() => {
                        this.setState({ media })
                        this.mediaModalToggle();
                      }}><i className='fa fa-edit mx-2' title="Edit item"></i></div>
                      <div onClick={() => this.deleteMedia(media.id)}><i className='fa fa-trash-alt text-danger' title="Delete item"></i></div>
                    </div>
                  </td>
                </tr>
              )
            }) : <tr><td colSpan='3' className='text-center'>No Media Found</td></tr>}
          </tbody>
        </table>
      </div>
      <Modal isOpen={this.state.mediaModal}>
        <ModalHeader>Edit Media</ModalHeader>
        <ModalBody>

          <label htmlFor="media" className="form-label">
            Media
            <div className='error' id='media-error'></div>
          </label>
          <input type="text" className="form-control" id="media" name="media" value={this.state.media.name} onChange={(e) => this.setState({ media: { ...this.state.media, name: e.target.value } })} />

        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.mediaModalToggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
          <Button color="primary" onClick={this.updateMedia}>Save<i className="far fa-check-circle ms-2"></i></Button>
        </ModalFooter>
      </Modal>
      <ThrobbleHelper />
    </div>
  }

  render() {
    return this.medias();
  }

  loadMedia = async () => {
    try {
      ThrobbleHelper.ToggleThrobble(true, "Loading Media");
      const responses = await fetch('/api/media', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + localStorage.getItem('token')
        }
      })
      if (responses.ok) {
        const data = await responses.json();

        //console.log(data);
        this.setState({ medias: data });
        ThrobbleHelper.ToggleThrobble(false);
      } else {
        if (responses.status === 401) {
          removeLoginDetails();
          window.location.href = "/";
        } else {
          ThrobbleHelper.ToggleThrobble(false);
        }
      }
    } catch (error) {
      console.error(error);
      ThrobbleHelper.ToggleThrobble(false);
    }
  }



  saveMedia = async () => {
    try {
      ThrobbleHelper.ToggleThrobble(true, "Saving Media");
      const data = { ...this.state.media, modifiedBy: getCurrentUser().id };
      const response = await fetch('/api/media', {
        method: this.state.media.id ? "PUT" : 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + localStorage.getItem('token')
        },
        body: JSON.stringify(data)
      })
      if (response.ok) {
        this.loadMedia();
        showToast("success", "Save Media", "Media was successfully saved");
      } else {
        if (response.status === 401) {
          removeLoginDetails();
          window.location.href = "/";
        } else {
          ThrobbleHelper.ToggleThrobble(false);
          showToast("error", "Save Media", "There was an error saving media");
        }
      }

    } catch (error) {
      console.error(error);
      ThrobbleHelper.ToggleThrobble(false);
      showToast("error", "Save Media", "There was an error saving media");
    }
  }

  deleteMedia = (id) => [
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <Card>
              <CardBody>
                <h3 className="">Delete Media</h3>
                <p>Are you sure you want to delete this media?</p>
                <hr />
                <button className='btn btn-success me-2' onClick={() => {
                  this.deleteMediaData(id);
                  onClose();
                }}>Yes</button>
                <button className='btn btn-danger' onClick={onClose}>No</button>
              </CardBody>
            </Card>
          </div>
        )
      }
    })
  ]

  deleteMediaData = async (id) => {
    try {
      ThrobbleHelper.ToggleThrobble(true, "Deleting Media");
      const response = await fetch('/api/media/' + id, {
        method: "DELETE",
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + localStorage.getItem('token')
        },
      })
      if (response.ok) {
        this.loadMedia();
        showToast("success", "Delete Media", "Media was successfully deleted!");
      } else {
        if (response.status === 401) {
          removeLoginDetails();
          window.location.href = "/";
        } else {
          ThrobbleHelper.ToggleThrobble(false);
          showToast("error", "Delete Media", "There was an error deleting media");
        }
      }

    } catch (error) {
      console.error(error);
      ThrobbleHelper.ToggleThrobble(false);
      showToast("error", "Delete Media", "There was an error deleting media");
    }
  }
}
